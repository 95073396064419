'use client'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import { XClose } from '@snipfeed/icons'
import { cva } from 'class-variance-authority'
import React from 'react'
import { cn } from '../utils/className'

const Dialog = DialogPrimitive.Root
const DialogTrigger = DialogPrimitive.Trigger
const DialogPortal = DialogPrimitive.Portal
const DialogClose = DialogPrimitive.Close

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-20 bg-black/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
  />
))

DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      'text-base font-semibold text-neutral-800 flex items-center font-regular',
      className
    )}
    {...props}
  />
))

DialogTitle.displayName = DialogPrimitive.Title.displayName

export const dialogContentClasses = cva(
  [
    // decorative styles
    'bg-white shadow-xl',

    // replacment
    'fixed z-30 bottom-0 max-h-[95%] overflow-y-auto',

    // spacing
    `border-none p-6 w-full rounded-tl-md	rounded-tr-ms`,

    // focus
    'focus:outline-none focus-visible:ring focus-visible:ring-offset-1 focus-visible:ring-cornflower-600',

    //* --|| Desktop ||-- *//

    // replacment
    'md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2	md:bottom-[unset]',

    // spacing
    'md:rounded-md',

    'font-regular',
  ],
  {
    variants: {
      size: {
        sm: 'md:w-[25rem]',
        md: 'w-full max-w-[36rem]',
        lg: 'w-full max-w-[50rem]',
        xl: 'w-full max-w-[60rem]',
      },
      isOpen: {
        true: 'animate-modal-show',
        false: 'animate-modal-exit',
      },
    },
    defaultVariants: {
      size: 'lg',
      isOpen: false,
    },
  }
)

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    size?: 'sm' | 'md' | 'lg' | 'xl'
    isOpen?: boolean

    hideXClose?: boolean
  }
>(({ className, size, isOpen, children, hideXClose, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />

    <DialogPrimitive.Content
      ref={ref}
      className={cn(dialogContentClasses({ isOpen, size }), className)}
      {...props}
    >
      {!hideXClose && (
        <div className="flex relative items-center justify-between">
          <DialogPrimitive.Close
            data-testid="modal-close-button"
            className="absolute top-0 right-0 focus:outline-none focus-visible:ring focus-visible:ring-ring"
          >
            <XClose className="h-6 w-6 text-neutral-700 hover:text-neutral-800" />
          </DialogPrimitive.Close>
        </div>
      )}

      <div>{children}</div>
    </DialogPrimitive.Content>
  </DialogPortal>
))

DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col space-y-1.5 text-center sm:text-left',
      className
    )}
    {...props}
  />
)

DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = 'DialogFooter'

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('mt-4 text-2xs font-normal text-neutral-800', className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}
