import type { SVGProps } from 'react'
const SvgXClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="m8.71 8 3.76-3.76c.2-.2.2-.51 0-.71-.2-.2-.51-.2-.71 0L8 7.29 4.24 3.53c-.2-.2-.51-.2-.71 0-.2.2-.2.51 0 .71L7.29 8l-3.76 3.76c-.2.2-.2.51 0 .71a.485.485 0 0 0 .7 0l3.76-3.76 3.76 3.76a.485.485 0 0 0 .7 0c.2-.2.2-.51 0-.71L8.69 8h.02Z"
    />
  </svg>
)
export default SvgXClose
