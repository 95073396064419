import { signOut } from 'next-auth/react'
import { usePostHog } from 'posthog-js/react'

import { useApollo } from '@/lib/apolloClient'

export default function useLogout() {
  const apolloClient = useApollo({})
  const posthog = usePostHog()

  const onLogout = async (callbackUrl?: string) => {
    const _hsp = (window._hsp = window._hsp || [])

    _hsp.push(['revokeCookieConsent'])

    // Reset PostHog before clearing session
    if (posthog?.reset) {
      posthog.reset()
    }

    await apolloClient.clearStore()
    await signOut({ redirect: true, callbackUrl: callbackUrl ?? '/login' })
  }

  return { onLogout }
}
