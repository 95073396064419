export const serverErrorMessage =
  'Oh no! Something went wrong, please try again..'

export const defaultProfileDesc =
  'Here you can write something inspiring to let your observers know a little more about you.'

export const scriptLoadErrorMessage =
  'We are unable to load the video preview currently.'

export const intercomAppID = 'bvd7fggs'

export const linkAppURL = process.env.NEXT_PUBLIC_LINK_URL

export const webAppURL = process.env.NEXT_PUBLIC_WEB_URL

export const mailChimpClientId = process.env.NEXT_PUBLIC_MAILCHIMP_CLIENT_ID

export const mailChimpRedirectUrl =
  process.env.NEXT_PUBLIC_MAILCHIMP_REDIRECT_URL

export const hubspotChatID = process.env.NEXT_PUBLIC_HUBSPOT_CHAT_ID

export const NEXT_AUTH_ERRORS = {
  Signin: 'Try signing with a different account.',
  OAuthSignin:
    'We encountred an error while retrieving the authorization to sign you in',
  OAuthCallback:
    'An error occured while trying to link your Google/Apple account, Please try again',
  OAuthCreateAccount: 'An account with this email already exists',
  EmailCreateAccount: 'Email used already exists or incorrect',
  Callback: 'Account already signed up with email and password',
  OAuthAccountNotLinked:
    'To confirm your identity, sign in with the same account you used originally.',
  EmailSignin: 'We were unable to verify your email address.',
  CredentialsSignin:
    'Sign in failed. Check the details you provided are correct.',
  LoginRequiredForIntegration:
    'Please log in to your account first before connecting an integration. Once logged in, try again.',
  default: 'Unable to sign in. Try signing with a different account.',
}
