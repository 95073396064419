import gql from 'graphql-tag'

import { createRootApolloClient } from './apolloClient'

export const getAccessToken = async (
  userId: string
): Promise<string | null> => {
  const apolloClient = createRootApolloClient()
  const r = await apolloClient.mutate({
    mutation: gql`
      mutation nextAuthAdapterGetAccessToken($userId: ID!) {
        nextAuthAdapterGetAccessToken(input: { userId: $userId }) {
          token
        }
      }
    `,
    variables: {
      userId,
    },
  })
  const snipfeedAccessToken =
    r.data?.nextAuthAdapterGetAccessToken?.token ?? null
  return snipfeedAccessToken
}

export const validateUserCredentials = async (
  email: string,
  rawPassword: string
) => {
  const apolloClient = createRootApolloClient()

  const r = await apolloClient.mutate({
    mutation: gql`
      mutation nextAuthAdapterValidateUserCredentials(
        $input: NextAuthAdapterValidateUserCredentialsInput!
      ) {
        nextAuthAdapterValidateUserCredentials(input: $input) {
          token
          user {
            id
            ... on Creator {
              isDisabled
            }
          }
        }
      }
    `,
    variables: {
      input: {
        email,
        rawPassword,
      },
    },
  })

  const { user } = r.data?.nextAuthAdapterValidateUserCredentials ?? {}
  if (user) {
    user.snipfeedAccessToken =
      r.data?.nextAuthAdapterValidateUserCredentials?.token
  }

  return user
}

export const signinBySingleUseToken = async (token: string) => {
  const apolloClient = createRootApolloClient()
  const r = await apolloClient.mutate({
    mutation: gql`
      mutation signinBySingleUseToken($token: String!) {
        signinBySingleUseToken(input: { token: $token }) {
          ... on SigninBySingleUseTokenPayloadSuccess {
            token
            me {
              id
              ... on Creator {
                isDisabled
              }
            }
          }
          ... on UserError {
            code
            message
          }
        }
      }
    `,
    variables: {
      token,
    },
  })

  const { token: accessToken, me: user } = r.data?.signinBySingleUseToken ?? {}
  return accessToken ? { token: accessToken, user } : null
}

export const isJwtExpired = (token) =>
  token
    ? Date.now() >=
      JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).exp *
        1000
    : true
