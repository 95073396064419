interface LoaderProps {
  variation?: 'neutral' | 'brand'
  className?: string
  width?: number
  height?: number
}

function LoaderIcon({
  variation = 'neutral',
  className,
  width = 22,
  height = 22,
}: LoaderProps) {
  const color = variation === 'brand' ? '#62759F' : '#545453'

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      color={color}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <defs>
        <linearGradient id="loader-secondHalf">
          <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id="loader-firstHalf">
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <path
        d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11"
        stroke="url(#loader-secondHalf)"
        strokeWidth="2"
      />
      <path
        d="M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11"
        stroke="url(#loader-firstHalf)"
        strokeWidth="2"
      />
      <path
        d="M1 11L1 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />

      <animateTransform
        from="0 0 0"
        to="360 0 0"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1300ms"
      />
    </svg>
  )
}

interface Props extends LoaderProps {
  global?: boolean
}

export default function Loader({ global, ...props }: Props) {
  if (global) {
    return (
      <div className="flex items-center justify-center h-full w-full left-0 top-0 z-50 fixed before:content-[''] before:block before:w-full before:h-full before:bg-white before:opacity-60">
        {/* force brand variation for global to have a good contrast with the bg */}
        <LoaderIcon className="absolute" {...props} variation="brand" />
      </div>
    )
  }

  return <LoaderIcon {...props} />
}
