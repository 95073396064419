import type { SVGProps } from 'react'
const SvgAlertTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.25 11.95 9.58 2.17c-.34-.59-.95-.94-1.62-.94-.68 0-1.29.36-1.62.95L.75 11.97a1.89 1.89 0 0 0 0 1.87c.34.58.94.93 1.62.93h11.25c.68 0 1.29-.35 1.62-.94.34-.59.34-1.29 0-1.88h.01Zm-.87 1.38c-.16.27-.44.44-.76.44H2.38c-.32 0-.6-.16-.76-.44a.848.848 0 0 1 0-.87L7.2 2.67c.16-.28.44-.44.76-.44s.6.16.76.44l5.67 9.79c.16.27.16.6 0 .87h-.01Z"
    />
    <path
      fill="currentColor"
      d="M8 5.25c-.28 0-.5.22-.5.5v3.73c0 .28.22.5.5.5s.5-.22.5-.5V5.75c0-.28-.22-.5-.5-.5ZM8 11.73c-.28 0-.5.23-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5Z"
    />
  </svg>
)
export default SvgAlertTriangle
