import Cookies from 'js-cookie'
import Router from 'next/router'
import posthog from 'posthog-js'
import { PostHogProvider as BasePostHogProvider } from 'posthog-js/react'
import { useEffect } from 'react'

import type { PostHogBootstrapData } from '@snipfeed/utils/posthog'

interface PosthogProviderProps {
  children: React.ReactNode
}

export default function PosthogProvider({ children }: PosthogProviderProps) {
  useEffect(() => {
    // Check for required environment variables
    const missingVars: string[] = []
    if (!process.env.NEXT_PUBLIC_POSTHOG_KEY) {
      missingVars.push('NEXT_PUBLIC_POSTHOG_KEY')
    }
    if (!process.env.NEXT_PUBLIC_POSTHOG_HOST) {
      missingVars.push('NEXT_PUBLIC_POSTHOG_HOST')
    }
    if (!process.env.NEXT_PUBLIC_ENVIRONMENT) {
      missingVars.push('NEXT_PUBLIC_ENVIRONMENT')
    }

    if (missingVars.length > 0) {
      console.warn(
        `Posthog failed to initialize. Missing environment variables: ${missingVars.join(
          ', '
        )}`
      )
      return
    }

    try {
      // Get bootstrap data from cookie
      const bootstrapDataStr = Cookies.get('bootstrapData')
      let bootstrapData: PostHogBootstrapData | undefined

      if (bootstrapDataStr) {
        try {
          bootstrapData = JSON.parse(bootstrapDataStr)
        } catch (e) {
          console.warn('Failed to parse bootstrap data:', e)
        }
      }

      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
        api_host: '/ingest',
        ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST!,
        ...(bootstrapData && { bootstrap: bootstrapData }),
        loaded: (posthog) => {
          // Enable debug mode only in development
          if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'development') {
            posthog.debug()
          }

          // Opt out of capturing in non-production environments
          if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
            posthog.opt_out_capturing()
            posthog.set_config({ disable_session_recording: true })
          }
        },
      })

      // Only set up pageview tracking in production
      if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
        const handleRouteChange = () => posthog?.capture('$pageview')
        Router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
          Router.events.off('routeChangeComplete', handleRouteChange)
        }
      }
    } catch (error) {
      console.error('Failed to initialize Posthog:', error)
    }
  }, [])

  // If Posthog isn't initialized, still render children without the provider
  if (
    !process.env.NEXT_PUBLIC_POSTHOG_KEY ||
    !process.env.NEXT_PUBLIC_POSTHOG_HOST ||
    !process.env.NEXT_PUBLIC_ENVIRONMENT
  ) {
    return <>{children}</>
  }

  return <BasePostHogProvider client={posthog}>{children}</BasePostHogProvider>
}
