import { ApolloProvider } from '@apollo/client/react'
import { PusherProvider } from '@harelpls/use-pusher'
import type { NextPage } from 'next'
import { SessionProvider } from 'next-auth/react'
// import BaseApp, { AppContext } from 'next/app'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import Router from 'next/router'
import Script from 'next/script'
import nProgress from 'nprogress'
import { ReactNode, useCallback, useEffect } from 'react'

import * as Sentry from '@sentry/nextjs'
import { Toaster } from '@snipfeed/tint2'

import AuthGuard from '@/components/common/AuthGuard'
import GlobalErrorFallback from '@/components/common/GlobalErrorFallback'
import OnboardingGuard from '@/components/common/OnboardingGuard'
import PlanolyAuthGuard from '@/components/common/PlanolyAuthGuard'
import PosthogProvider from '@/components/common/PosthogProvider'
import DefaultSEO from '@/components/common/SEO'
import pusher from '@/config/pusher'
import useBuildId from '@/hooks/useBuildId'
// import useBuildId from '@/hooks/useBuildId'
import useNProgress from '@/hooks/useNProgress'
import usePrevious from '@/hooks/usePrevious'
// import usePrevious from '@/hooks/usePrevious'
import { PageProps, useApollo } from '@/lib/apolloClient'
import * as firstPromoter from '@/lib/firstPromoter'
import * as gtm from '@/lib/gtm'

import * as recaptcha from '@/lib/recaptcha'
import 'react-phone-input-2/lib/style.css'

import '@snipfeed/tint2/dist/styles.css'
import '@snipfeed/templates/styles.css'
import '@snipfeed/timezone-picker/styles.css'

import 'shepherd.js/dist/css/shepherd.css'

import '../styles.css'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactNode) => ReactNode
  auth?: boolean
  useRecaptcha?: boolean
  pageName?: string
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function App({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  useNProgress()

  const getLayout = Component.getLayout ?? ((page) => page)
  const useRecaptcha = Component.useRecaptcha ?? false
  const apolloClient = useApollo({
    pageProps: pageProps as PageProps,
  })

  const buildId = useBuildId()
  const prevBuildId = usePrevious(buildId)
  const reloadIfNoProgressIsOngoing = useCallback(() => {
    if (nProgress.isStarted()) {
      // recheck in 1s
      return setTimeout(reloadIfNoProgressIsOngoing, 1000)
    } else {
      if (Router.pathname === window.location.pathname) {
        window.location.reload()
      } else {
        Router.reload()
      }
    }
  }, [])

  useEffect(() => {
    if (prevBuildId && buildId && prevBuildId !== buildId) {
      reloadIfNoProgressIsOngoing()
    }
  }, [buildId, prevBuildId, reloadIfNoProgressIsOngoing])

  return (
    <>
      <Sentry.ErrorBoundary
        fallback={(errorData) => (
          <GlobalErrorFallback
            error={errorData.error}
            resetErrorBoundary={errorData.resetError}
          />
        )}
      >
        <DefaultSEO />

        {firstPromoter.FP_CLIENT_ID && (
          <>
            <Script
              id="firstPromoter-setup"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
           (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
            fpr("init", {cid:"${firstPromoter.FP_CLIENT_ID}"}); 
            fpr("click");
          `,
              }}
            />
            <Script
              id="firstPromoter"
              src="https://cdn.firstpromoter.com/fpr.js"
              async
            />
          </>
        )}

        <Script
          id="polyfills"
          strategy="beforeInteractive"
          src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=MediaQueryList.prototype.addEventListener%2CMediaQueryList.prototype.removeEventListener%2CIntl.DisplayNames%2CArray.prototype.at"
        />
        <Script
          id="paypal"
          src="https://www.paypalobjects.com/js/external/connect/api.js"
        />
        {useRecaptcha && recaptcha.RECAPTCHA_SITE_KEY ? (
          <Script
            id="recaptcha"
            src={`https://www.google.com/recaptcha/api.js?render=${recaptcha.RECAPTCHA_SITE_KEY}`}
          />
        ) : (
          <style>{`
            .grecaptcha-badge {
                visibility: hidden;
            }
          `}</style>
        )}
        <PosthogProvider>
          <SessionProvider
            session={pageProps.session}
            // should be less than backend JWT ttl
            refetchInterval={60 * 30}
            // nice to have, but not required
            refetchOnWindowFocus={true}
          >
            <Toaster />

            <ApolloProvider client={apolloClient}>
              <PusherProvider {...pusher}>
                {Component.auth ? (
                  <PlanolyAuthGuard>
                    <AuthGuard>
                      <OnboardingGuard>
                        {getLayout(<Component {...pageProps} />)}
                      </OnboardingGuard>
                    </AuthGuard>
                  </PlanolyAuthGuard>
                ) : (
                  getLayout(<Component {...pageProps} />)
                )}
              </PusherProvider>
            </ApolloProvider>
          </SessionProvider>
        </PosthogProvider>
      </Sentry.ErrorBoundary>
    </>
  )
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  //make sure that datalayer is initialized
  window.dataLayer = window.dataLayer || []

  switch (metric.name) {
    case 'Next.js-hydration':
      // register right after hydration
      return gtm.pageview()
    case 'Next.js-route-change-to-render':
      // register pageviews after client-side transitions
      return gtm.pageview()
    default:
  }
}

export default App
