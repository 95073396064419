import * as Sonner from 'sonner'

import { AlertTriangle, CheckCircle } from '@snipfeed/icons'
import { InfoCircle } from '@snipfeed/icons'

import Loader from './Loader'
import AlertCircle from './icons/AlertCircle'

export const toast = Sonner.toast

export function Toaster() {
  return (
    <Sonner.Toaster
      closeButton
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-neutral-1000 group-[.toaster]:text-white group-[.toaster]:rounded-sm group-[.toaster]:border-none group-[.toaster]:[&[data-type="error"]]:bg-cherry-300 group-[.toaster]:[&[data-type="error"]]:text-neutral-800',
          title: 'pr-6',
          actionButton: 'group-[.toast]:!mr-5 group-[.toast]:text-white',
          cancelButton: 'group-[.toast]:bg-black group-[.toast]:text-white',
          closeButton:
            'absolute left-[unset] right-[12px] top-1/2 -translate-y-1/2 border-none w-6 h-6 [&_svg]:w-full [&_svg]:h-full bg-transparent group-[.toast]:bg-transparent rounded-none group-[.toaster]:hover:bg-transparent',
          error: 'group-[.toast]:bg-cherry-300 group-[.toast]:text-neutral-800',
        },
      }}
      icons={{
        success: <CheckCircle />,
        info: <InfoCircle />,
        warning: <AlertTriangle />,
        error: <AlertCircle className="text-cherry-700" />,
        loading: <Loader />,
      }}
    />
  )
}
