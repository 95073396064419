import { AlertCircle } from '@snipfeed/icons'
import { Button } from '@snipfeed/tint2'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

interface GlobalErrorFallbackProps {
  error?: Error
  resetErrorBoundary?: () => void
}

export default function GlobalErrorFallback({
  error,
  resetErrorBoundary,
}: GlobalErrorFallbackProps): JSX.Element {
  const router = useRouter()

  // Log the error to console in development
  useEffect(() => {
    if (process.env.VERCEL_ENV !== 'production') {
      console.error('Global error caught by error boundary:', error)
    }
  }, [error])

  const handleRefresh = () => {
    if (resetErrorBoundary) {
      resetErrorBoundary()
    } else {
      window.location.reload()
    }
  }

  const handleGoBack = () => {
    router.back()
  }

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-neutral-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-sm p-8 text-center">
        <div className="flex justify-center mb-6">
          <AlertCircle width={48} height={48} className="text-cherry-700" />
        </div>

        <h1 className="text-xl font-normal mb-3 font-decorative">
          Something went wrong
        </h1>

        <p className="text-neutral-600 mb-8">
          We encountered an unexpected error while loading this page. Our team
          has been notified and is working to fix the issue.
        </p>

        <div className="flex flex-col sm:flex-row gap-3 justify-center">
          <Button
            variant="primary"
            onClick={handleGoBack}
            className="min-w-[120px]"
          >
            Go Back
          </Button>

          <Button
            variant="secondary"
            onClick={handleRefresh}
            className="min-w-[120px]"
          >
            Refresh Page
          </Button>
        </div>

        <p className="text-2xs text-neutral-600 mt-8">
          If the problem persists, please contact our support team at{' '}
          <a
            href="mailto:support@planoly.com"
            className="font-medium text-primary-600"
          >
            support@planoly.com
          </a>
        </p>
      </div>
    </div>
  )
}
