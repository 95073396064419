import * as DialogPrimitive from '@radix-ui/react-dialog'
import React from 'react'
import { cn } from '../utils/className'
import { IconProps } from '../utils/prop-types'
import Button, { ButtonVariants } from './Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  dialogContentClasses,
} from './Dialog'

type IconType = React.ComponentType<React.PropsWithChildren<IconProps>> | null
type ModalTitleType = {
  text: string
  icon?: IconType
}
type ModalActionsType = {
  primary: {
    text: string
    onClick: () => void
    variant?: ButtonVariants
    disabled?: boolean
    loading?: boolean
  }
  secondary?: {
    text: string
    onClick: () => void
    disabled?: boolean
    loading?: boolean
  }
}

export interface ModalProps {
  children: React.ReactNode
  isOpen?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  title?: ModalTitleType
  description?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  actions?: ModalActionsType
  className?: string
}

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = 'DialogFooter'

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('mt-4 text-2xs font-normal text-neutral-800', className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

const Modal = ({
  title: { text: titleText, icon: Icon } = { text: '' },
  description,
  actions,
  isOpen,
  size = 'lg',
  onOpenChange,
  children,
  defaultOpen,
  className,
}: ModalProps) => {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      modal
    >
      <DialogPortal forceMount>
        {isOpen && (
          <>
            <DialogOverlay forceMount />

            <DialogContent
              forceMount
              className={cn(dialogContentClasses({ isOpen, size }), className)}
            >
              <DialogHeader>
                {titleText && (
                  <DialogTitle>
                    {Icon && (
                      <div className="mr-4">
                        <Icon width={22} height={22} />
                      </div>
                    )}
                    {titleText}
                  </DialogTitle>
                )}
              </DialogHeader>

              <div className="mt-6">{children}</div>

              {description && (
                <DialogDescription>{description}</DialogDescription>
              )}

              {actions && (
                <div className="mt-6 flex justify-end gap-2">
                  {actions.secondary && (
                    <DialogPrimitive.Close asChild>
                      <Button
                        variant="secondary"
                        className="w-full md:w-auto"
                        onClick={actions.secondary.onClick}
                        disabled={actions.secondary.disabled}
                        loading={actions.secondary.loading}
                      >
                        {actions.secondary.text}
                      </Button>
                    </DialogPrimitive.Close>
                  )}

                  {actions.primary && (
                    <Button
                      onClick={actions.primary.onClick}
                      className="w-full md:w-auto"
                      variant={actions.primary.variant}
                      disabled={actions.primary.disabled}
                      loading={actions.primary.loading}
                      data-testid="modal-primary-action"
                    >
                      {actions.primary.text}
                    </Button>
                  )}
                </div>
              )}
            </DialogContent>
          </>
        )}
      </DialogPortal>
    </Dialog>
  )
}

export { DialogFooter, DialogTitle, DialogDescription }

export default Modal
