export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DateTime: any
  URL: any
  CSSColor: any
  TimeZone: any
  BigInt: any
}

export enum ECourseStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type ECourseMyProgress = {
  __typename?: 'ECourseMyProgress'
  completedLessonFilesCount: Scalars['Int']
  totalLessonFilesCount: Scalars['Int']
}

export type ECourseLesson = {
  __typename?: 'ECourseLesson'
  files: Maybe<Array<Maybe<ECourseLessonFile>>>
  id: Scalars['ID']
  title: Scalars['String']
  visible: Scalars['Boolean']
}

export type ECourseLessonFileAssetPreview = {
  __typename?: 'ECourseLessonFileAssetPreview'
  id: Scalars['ID']
  fileContentType: Scalars['String']
  fileVideoDuration: Maybe<Scalars['Float']>
}

export type ECourseLessonFileAssetUnion = Asset | ECourseLessonFileAssetPreview

export type ECourseLessonFile = {
  __typename?: 'ECourseLessonFile'
  description: Maybe<Scalars['String']>
  fileAsset: Maybe<ECourseLessonFileAssetUnion>
  id: Scalars['ID']
  isCompleted: Maybe<Scalars['Boolean']>
  title: Scalars['String']
  visible: Scalars['Boolean']
  descriptionV2: Maybe<Scalars['String']>
  descriptionHTML: Maybe<Scalars['String']>
}

export type ECourse = Node &
  RatingInterface &
  TestimonialInterface & {
    __typename?: 'ECourse'
    coverAsset: Maybe<Asset>
    databaseId: Scalars['String']
    description: Maybe<Scalars['String']>
    discountCoupons: Maybe<Array<DiscountCoupon>>
    id: Scalars['ID']
    isDeletable: Scalars['Boolean']
    isInsideLinkBlock: Scalars['Boolean']
    lessons: Maybe<Array<Maybe<ECourseLesson>>>
    myProgress: Maybe<ECourseMyProgress>
    owner: Creator
    price: Maybe<Money>
    receiptEmailCustomMessageTemplate: Maybe<Scalars['String']>
    saleDiscount: Maybe<Discount>
    status: ECourseStatus
    title: Maybe<Scalars['String']>
    trailer: Maybe<Array<Maybe<ECourseLessonFile>>>
    /** @deprecated use viewer.hasPurchased instead */
    viewerHasPurchased: Scalars['Boolean']
    ratingConfig: Maybe<MonetizableServiceRatingConfig>
    rating: Maybe<MonetizableServiceRating>
    ratings: Maybe<RatingsConnection>
    testimonialConfig: Maybe<MonetizableServiceTestimonialConfig>
    testimonials: Maybe<TestimonialsConnection>
    slug: Maybe<Scalars['String']>
    shortUrl: Maybe<ShortUrlEmbedded>
    viewer: ECourseViewer
    descriptionV2: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplateV2: Maybe<Scalars['String']>
    questions: Maybe<Array<Maybe<CustomQuestion>>>
  }

export type ECourseRatingsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type ActorDisabled = {
  __typename?: 'ActorDisabled'
  at: Scalars['DateTime']
  by: Maybe<Scalars['String']>
  reason: Maybe<Scalars['String']>
}

export type Actor = {
  id: Scalars['ID']
  username: Scalars['String']
  createdAt: Scalars['DateTime']
  phone: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  isDisabled: Scalars['Boolean']
  disabled: Maybe<ActorDisabled>
  name: Maybe<Scalars['String']>
}

export type Node = {
  id: Scalars['ID']
}

export type Query = {
  __typename?: 'Query'
  node: Maybe<
    | ECourse
    | User
    | LiveStream
    | Asset
    | ExclusiveContent
    | ExclusiveContentOrder
    | ShoutoutOrder
    | Shoutout
    | Creator
    | CreatorLink
    | TextBlock
    | OneTimeTip
    | TipFeedItem
    | TipSubscription
    | Goal
    | TipBlock
    | CustomBlock
    | ProductBlock
    | SocialIconsBlock
    | MusicPreviewBlock
    | TwitterEmbedBlock
    | VideoPreviewBlock
    | MailingListBlock
    | PremiumBlock
    | CarouselBlock
    | ThumbnailsBlock
    | SocialFeedBlock
    | ShopifyProductBlock
    | Consultation
    | ConsultationOrder
    | CrmCustomer
    | CrmEmailBroadcast
    | CrmEmailFlow
    | ECourseOrder
    | Invoice
    | InvoiceOrder
    | LiveStreamOrder
    | Membership
    | MembershipSubscription
    | MembershipSubscriptionTransaction
    | RecurringTip
    | CrmEmailFlowStep
  >
  viewer: Maybe<User | Creator>
  creatorByUsername: Maybe<Creator>
  creatorById: Maybe<Creator>
  creatorLinkByUsername: Maybe<CreatorLink>
  linkTemplates: Array<LinkTemplate>
  creatorByCustomDomainName: Maybe<Creator>
  creatorGlobalInformations: Maybe<CreatorGlobalInformations>
  getZapierTriggerSamples: Maybe<GetZapierTriggerSamplesPayload>
  testZapierConnection: Maybe<Creator>
  dummyAssets: Maybe<DummyAssets>
  getSocialFeedBlocksByPlanolyCreator: Maybe<GetSocialFeedBlocksByPlanolyCreatorPayload>
  getCrmCustomerEmailSubscriptionStatus: Maybe<CrmEmailSubscriptionStatus>
  invoiceByNumber: Maybe<InvoiceByNumberPayload>
  getCheckoutMembershipSubscriptionById: Maybe<CheckoutMembershipSubscription>
  calculateDiscounts: Maybe<CalculateDiscountsPayload>
  nodeByIdOrSlug: Maybe<
    | ECourse
    | User
    | LiveStream
    | Asset
    | ExclusiveContent
    | ExclusiveContentOrder
    | ShoutoutOrder
    | Shoutout
    | Creator
    | CreatorLink
    | TextBlock
    | OneTimeTip
    | TipFeedItem
    | TipSubscription
    | Goal
    | TipBlock
    | CustomBlock
    | ProductBlock
    | SocialIconsBlock
    | MusicPreviewBlock
    | TwitterEmbedBlock
    | VideoPreviewBlock
    | MailingListBlock
    | PremiumBlock
    | CarouselBlock
    | ThumbnailsBlock
    | SocialFeedBlock
    | ShopifyProductBlock
    | Consultation
    | ConsultationOrder
    | CrmCustomer
    | CrmEmailBroadcast
    | CrmEmailFlow
    | ECourseOrder
    | Invoice
    | InvoiceOrder
    | LiveStreamOrder
    | Membership
    | MembershipSubscription
    | MembershipSubscriptionTransaction
    | RecurringTip
    | CrmEmailFlowStep
  >
}

export type QueryNodeArgs = {
  id: Scalars['ID']
}

export type QueryCreatorByUsernameArgs = {
  username: Scalars['String']
}

export type QueryCreatorByIdArgs = {
  creatorId: Scalars['ID']
}

export type QueryCreatorLinkByUsernameArgs = {
  username: Scalars['String']
}

export type QueryCreatorByCustomDomainNameArgs = {
  customDomainName: Scalars['String']
}

export type QueryGetZapierTriggerSamplesArgs = {
  input: GetZapierTriggerSamplesInput
}

export type QueryGetSocialFeedBlocksByPlanolyCreatorArgs = {
  input: GetSocialFeedBlocksByPlanolyCreatorInput
}

export type QueryGetCrmCustomerEmailSubscriptionStatusArgs = {
  crmCustomerId: Scalars['ID']
}

export type QueryInvoiceByNumberArgs = {
  invoiceNumber: Scalars['String']
  creatorUsername: InputMaybe<Scalars['String']>
  creatorCustomDomainName: InputMaybe<Scalars['String']>
}

export type QueryGetCheckoutMembershipSubscriptionByIdArgs = {
  membershipSubscriptionId: Scalars['ID']
}

export type QueryCalculateDiscountsArgs = {
  input: CalculateDiscountsInput
}

export type QueryNodeByIdOrSlugArgs = {
  identifier: Scalars['String']
  creatorId: Scalars['String']
  nodeType: SluggableNode
}

export type User = Node &
  Actor & {
    __typename?: 'User'
    id: Scalars['ID']
    email: Maybe<Scalars['String']>
    username: Scalars['String']
    phone: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    isDisabled: Scalars['Boolean']
    disabled: Maybe<ActorDisabled>
    emailLanguage: EmailLanguage
    isVerified: Scalars['Boolean']
    databaseId: Scalars['String']
    createdAt: Scalars['DateTime']
    orders: OrderConnection
  }

export type UserOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<OrderSortField>
  filterByField: InputMaybe<OrderFilterByField>
}

export type UpdateUserInput = {
  userId: Scalars['ID']
  email: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  name: InputMaybe<Scalars['String']>
}

export type UpdateUserPayloadSuccess = {
  __typename?: 'UpdateUserPayloadSuccess'
  user: User
}

export type UpdateUserPayload =
  | UpdateUserPayloadSuccess
  | InputValidationError
  | EmailTakenError
  | UsernameTakenError

export type Mutation = {
  __typename?: 'Mutation'
  updateUser: Maybe<UpdateUserPayload>
  verifyCreatorEmail: Maybe<VerifyCreatorEmailPayload>
  updateCreator: Maybe<UpdateCreatorPayload>
  connectPaypal: Maybe<ConnectPaypalPayload>
  connectMailchimp: Maybe<ConnectMailchimpPayload>
  connectGoogle: Maybe<ConnectGooglePayload>
  disconnectGoogle: Maybe<DisconnectGooglePayload>
  connectZoom: Maybe<ConnectZoomPayload>
  disconnectZoom: Maybe<DisconnectZoomPayload>
  disconnectMailchimp: Maybe<DisconnectMailchimpPayload>
  /** @deprecated Application Fees have been removed */
  updateCreatorApplicationFee: Maybe<UpdateCreatorApplicationFeePayload>
  updateCreatorUsername: UpdateCreatorUsernamePayload
  generateHubspotChatToken: Maybe<GenerateHubspotChatTokenPayload>
  addCreatorCategories: Maybe<AddCreatorCategoriesPayload>
  updateCreatorReferredBy: Maybe<UpdateCreatorReferredByPayload>
  deleteCreatorReferredBy: Maybe<DeleteCreatorReferredByPayload>
  updateCreatorCategories: Maybe<UpdateCreatorCategoriesPayload>
  updateCreatorOnboardingStep: Maybe<UpdateCreatorOnboardingStepPayload>
  upsertSocialLink: Maybe<UpsertSocialLinkPayload>
  updateVipStatus: Maybe<UpdateVipStatusPayload>
  addEmailToMailingList: Maybe<AddEmailToMailingListPayload>
  updateLinkProfile: Maybe<UpdateLinkProfilePayload>
  addCustomBlock: Maybe<AddCustomBlockPayload>
  updateCustomBlock: Maybe<UpdateCustomBlockPayload>
  addProductBlock: Maybe<AddProductBlockPayload>
  updateProductBlock: Maybe<UpdateProductBlockPayload>
  addCoverAssetToProductBlock: Maybe<AddCoverAssetToProductBlockPayload>
  deleteCoverAssetFromProductBlock: Maybe<DeleteCoverAssetFromProductBlockPayload>
  updateLinkBlockVisibility: Maybe<UpdateLinkBlockVisibilityPayload>
  updatePremiumBlock: Maybe<UpdatePremiumBlockPayload>
  updateCarouselBlock: Maybe<UpdateCarouselBlockPayload>
  addItemToCarouselBlock: Maybe<AddItemToCarouselBlockPayload>
  /** @deprecated Use upsertSocialIcons field instead */
  upsertSocialIcon: Maybe<UpsertSocialIconPayload>
  /** @deprecated Use upsertSocialIcons field instead */
  deleteSocialIcon: Maybe<DeleteSocialIconPayload>
  sortBlocks: Maybe<SortBlocksPayload>
  /** @deprecated Use updateCarouselBlock mutation with field 'sortedCardIds' instead */
  sortBlockCards: Maybe<SortBlockCardsPayload>
  updateMailingListBlock: Maybe<UpdateMailingListBlockPayload>
  sortSocialIcons: Maybe<SortSocialIconsPayload>
  updateVideoBlock: Maybe<UpdateVideoBlockPayload>
  updateMusicBlock: Maybe<UpdateMusicBlockPayload>
  updateTwitterEmbedBlock: Maybe<UpdateTwitterEmbedBlockPayload>
  addVideoBlock: Maybe<AddVideoBlockPayload>
  addMusicBlock: Maybe<AddMusicBlockPayload>
  addTwitterEmbedBlock: Maybe<AddTwitterEmbedBlockPayload>
  addPremiumBlock: Maybe<AddPremiumBlockPayload>
  addTipBlock: Maybe<AddTipBlockPayload>
  addTextBlock: Maybe<AddTextBlockPayload>
  updateTextBlock: Maybe<UpdateTextBlockPayload>
  addMailingListBlock: Maybe<AddMailingListBlockPayload>
  addSocialIconsBlock: Maybe<AddSocialIconsBlockPayload>
  addCarouselBlock: Maybe<AddCarouselBlockPayload>
  /** @deprecated Use updateLinkDesign instead */
  updateLinkBackground: Maybe<UpdateLinkBackgroundPayload>
  assignLinkCustomDomainName: Maybe<AssignLinkCustomDomainNamePayload>
  unassignLinkCustomDomainName: Maybe<UnassignLinkCustomDomainNamePayload>
  checkLinkCustomDomainNameAvailability: Maybe<CheckLinkCustomDomainNameAvailabilityPayload>
  buyLinkCustomDomainName: Maybe<BuyLinkCustomDomainNamePayload>
  /** @deprecated Use updateLinkDesign instead */
  updateLinkColors: Maybe<UpdateLinkColorsPayload>
  /** @deprecated Use updateLinkDesign instead */
  updateLinkBlockStyles: Maybe<UpdateLinkBlockStylesPayload>
  /** @deprecated Use updateLinkDesign instead */
  updateLinkStyles: Maybe<UpdateLinkStylesPayload>
  nextAuthAdapterUpdateUser: NextAuthAdapterUpdateUserResult
  nextAuthAdapterGetAccessToken: Maybe<NextAuthAdapterGetAccessTokenResult>
  nextAuthAdapterCreateUser: Maybe<NextAuthAdapterCreateUserResult>
  nextAuthAdapterValidateUserCredentials: Maybe<NextAuthAdapterValidateUserCredentialsResult>
  nextAuthAdapterGetUserByEmail: Maybe<NextAuthAdapterGetUserByEmailResult>
  nextAuthAdapterGetUserById: Maybe<NextAuthAdapterGetUserByIdResult>
  nextAuthAdapterGetUserByAccount: Maybe<NextAuthAdapterGetUserByAccountResult>
  nextAuthAdapterLinkAccount: Maybe<NextAuthAdapterLinkAccountResult>
  createImportContentLinkRequest: CreateImportContentLinkRequestPayload
  authorizeApprovedPaypalOrder: Maybe<AuthorizeApprovedPaypalOrderPayload>
  reauthorizeApprovedPaypalOrder: Maybe<ReauthorizeApprovedPaypalOrderPayload>
  addStripeIntent: Maybe<AddStripeIntentPayload>
  refundTransaction: Maybe<RefundTransactionPayload>
  captureApprovedPaypalOrder: Maybe<CaptureApprovedPaypalOrderPayload>
  disableActor: Maybe<DisableActorPayload>
  enableActor: Maybe<EnableActorPayload>
  applySaleDiscount: Maybe<ApplySaleDiscountPayload>
  unapplySaleDiscount: Maybe<UnapplySaleDiscountPayload>
  addCreatorToFirstPromoter: Maybe<AddCreatorToFirstPromoterPayload>
  exportDataRequest: Maybe<ExportDataRequestPayload>
  generateFirstPromoterIframeToken: Maybe<GenerateFirstPromoterIframeTokenPayload>
  updateCreatorSelectedGoogleCalendars: Maybe<UpdateCreatorSelectedGoogleCalendarsPayload>
  completeUserProfile: Maybe<CompleteUserProfilePayload>
  findOrCreatePlanolyUser: Maybe<FindOrCreatePlanolyUserPayload>
  requestPasswordReset: Maybe<RequestPasswordResetPayload>
  resetPassword: Maybe<ResetPasswordPayload>
  connectShopify: Maybe<ConnectShopifyPayload>
  disconnectShopify: Maybe<DisconnectShopifyPayload>
  signinAsCreator: Maybe<SigninAsCreatorPayload>
  signinBySingleUseToken: Maybe<SigninBySingleUseTokenPayload>
  signinCreator: Maybe<SigninCreatorPayload>
  signupCreator: Maybe<SignupCreatorPayload>
  unlinkCreatorPlanolyAccount: Maybe<UnlinkCreatorAccountFromPlanolyPayload>
  updateCreatorReplyToEmail: Maybe<UpdateCreatorReplyToEmailPayload>
  updatePassword: Maybe<UpdatePasswordPayload>
  updateZapierWebhookSubscription: Maybe<UpdateZapierWebhookSubscriptionPayload>
  createAsset: Maybe<CreateAssetPayload>
  deleteAsset: Maybe<DeleteAssetPayload>
  updateAssetModeration: Maybe<UpdateAssetModerationPayload>
  addItemToSocialFeedBlock: Maybe<AddItemToSocialFeedBlockPayload>
  addItemToSocialFeedBlockFromPlanoly: Maybe<AddItemToSocialFeedBlockFromPlanolyPayload>
  addShopifyProductBlock: Maybe<AddShopifyBlockPayload>
  addSocialFeedBlock: Maybe<AddSocialFeedBlockPayload>
  addThumbnailsBlock: Maybe<AddThumbnailsBlockPayload>
  deleteItemFromCarouselBlock: Maybe<DeleteItemFromCarouselBlockPayload>
  deleteLinkBlock: Maybe<DeleteLinkBlockPayload>
  deleteSocialFeedBlockItem: Maybe<DeleteSocialFeedBlockItemPayload>
  reassignPurchasedLinkCustomDomainName: Maybe<ReassignPurchasedLinkCustomDomainNamePayload>
  setPremiumTemplate: Maybe<SetPremiumTemplatePayload>
  /** @deprecated use updateSocialFeedBlock instead by providing sortedItemIds */
  sortSocialFeedBlockItems: Maybe<SortSocialFeedBlockItemsPayload>
  unsetPremiumTemplate: Maybe<UnsetPremiumTemplatePayload>
  updateBrandingVisibility: Maybe<UpdateBrandingVisibilityPayload>
  updateLinkDesign: Maybe<UpdateLinkDesignPayload>
  updateShopifyProductBlock: Maybe<UpdateShopifyBlockPayload>
  updateSocialFeedBlock: Maybe<UpdateSocialFeedBlockPayload>
  updateSocialFeedBlockItem: Maybe<UpdateSocialFeedBlockItemPayload>
  updateThumbnailsBlock: Maybe<UpdateThumbnailsBlockPayload>
  upsertSocialIcons: Maybe<UpsertSocialIconsPayload>
  addConsultation: Maybe<AddConsultationPayload>
  addConsultationOrder: Maybe<AddConsultationOrderPayload>
  addFreeConsultationOrder: Maybe<AddFreeConsultationOrderPayload>
  addPaypalConsultationOrder: Maybe<AddPaypalConsultationOrderPayload>
  cancelConsultationOrder: Maybe<CancelConsultationOrderPayload>
  deleteConsultation: Maybe<DeleteConsultationPayload>
  publishConsultation: Maybe<PublishConsultationPayload>
  rescheduleConsultationOrder: Maybe<RescheduleConsultationOrderPayload>
  unpublishConsultation: Maybe<UnpublishConsultationPayload>
  updateConsultation: Maybe<UpdateConsultationPayload>
  /** @deprecated use updateConsultation or addConsultation mutations instead */
  updateConsultationAvailabilities: Maybe<UpdateConsultationAvailabilitiesPayload>
  updateCustomConsultationQuestions: Maybe<UpdateCustomConsultationQuestionsPayload>
  addEmailBroadcast: Maybe<AddEmailBroadcastPayload>
  addEmailFlow: Maybe<AddEmailFlowPayload>
  deleteEmailBroadcast: Maybe<DeleteEmailBroadcastPayload>
  deleteEmailFlow: Maybe<DeleteEmailFlowPayload>
  sendTestCrmEmail: Maybe<SendTestCrmEmailPayload>
  updateCrmCustomerSubscriptionStatus: Maybe<UpdateCrmCustomerSubscriptionStatusPayload>
  updateDraftEmailFlow: Maybe<UpdateDraftEmailFlowPayload>
  updateEmailBroadcast: Maybe<UpdateEmailBroadcastPayload>
  updatePublishedEmailFlow: Maybe<UpdatePublishedEmailFlowPayload>
  addECourse: Maybe<AddECoursePayload>
  /** @deprecated Use addECourse or UpdateECourse instead */
  addECourseLesson: Maybe<AddECourseLessonPayload>
  /** @deprecated Use addECourse or updateECourse instead */
  addECourseLessonFile: Maybe<AddECourseLessonFilePayload>
  addECourseOrder: Maybe<AddECourseOrderPayload>
  /** @deprecated Use addECourse or updateECourse instead */
  addECourseTrailer: Maybe<AddECourseTrailerPayload>
  addFreeECourseOrder: Maybe<AddFreeECourseOrderPayload>
  addPaypalECourseOrder: Maybe<AddPaypalECourseOrderPayload>
  deleteECourse: Maybe<DeleteECoursePayload>
  /** @deprecated Use  updateECourse instead */
  deleteECourseLesson: Maybe<DeleteECourseLessonPayload>
  /** @deprecated Use updateECourse instead */
  deleteECourseLessonFile: Maybe<DeleteECourseLessonFilePayload>
  /** @deprecated Use updateECourse instead */
  deleteECourseTrailer: Maybe<DeleteECourseTrailerPayload>
  /** @deprecated Use updateECourse instead */
  markECourseLessonAsVisibleOrInvisible: Maybe<MarkECourseLessonAsVisibleOrInvisiblePayload>
  markECourseLessonFileAsCompletedOrUncompleted: Maybe<MarkECourseLessonFileAsCompletedOrUncompletedPayload>
  /** @deprecated Use updateECourse instead */
  markECourseLessonFileAsVisibleOrInvisible: Maybe<MarkECourseLessonFileAsVisibleOrInvisiblePayload>
  /** @deprecated Use addECourse or updateECourse instead */
  publishECourse: Maybe<PublishECoursePayload>
  /** @deprecated Use updateECourse instead */
  sortECourseLessonFiles: Maybe<SortECourseLessonFilesPayload>
  /** @deprecated Use updateECourse instead */
  sortECourseLessons: Maybe<SortECourseLessonsPayload>
  /** @deprecated Use updateECourse instead */
  sortECourseTrailers: Maybe<SortECourseTrailersPayload>
  /** @deprecated Use updateECourse instead */
  unpublishECourse: Maybe<UnpublishECoursePayload>
  updateCustomerECourseAccess: Maybe<UpdateCustomerECourseAccessPayload>
  updateECourse: Maybe<UpdateECoursePayload>
  /** @deprecated Use updateECourse instead */
  updateECourseLesson: Maybe<UpdateECourseLessonPayload>
  /** @deprecated Use updateECourse instead */
  updateECourseLessonFile: Maybe<UpdateECourseLessonFilePayload>
  /** @deprecated Use updateECourse instead */
  updateECourseTrailer: Maybe<UpdateECourseTrailerPayload>
  addExclusiveContent: Maybe<AddExclusiveContentPayload>
  /** @deprecated Use updateExclusiveContent instead */
  addExclusiveContentFileBlock: Maybe<AddExclusiveContentFileBlockPayload>
  addExclusiveContentOrder: Maybe<AddExclusiveContentOrderPayload>
  /** @deprecated Use updateExclusiveContent instead */
  addExclusiveContentTextBlock: Maybe<AddExclusiveContentTextBlockPayload>
  addFreeExclusiveContentOrder: Maybe<AddFreeExclusiveContentOrderPayload>
  addPaypalExclusiveContentOrder: Maybe<AddPaypalExclusiveContentOrderPayload>
  deleteExclusiveContent: Maybe<DeleteExclusiveContentPayload>
  /** @deprecated Use updateExclusiveContent instead */
  deleteExclusiveContentBlock: Maybe<DeleteExclusiveContentBlockPayload>
  /** @deprecated Use updateExclusiveContent instead */
  publishExclusiveContent: Maybe<PublishExclusiveContentPayload>
  /** @deprecated Use updateExclusiveContent instead */
  sortExclusiveContentBlocks: Maybe<SortExclusiveContentBlocksPayload>
  unpublishExclusiveContent: Maybe<UnpublishExclusiveContentPayload>
  updateCustomerExclusiveContentAccess: Maybe<UpdateCustomerExclusiveContentAccessPayload>
  updateExclusiveContent: Maybe<UpdateExclusiveContentPayload>
  /** @deprecated Use updateExclusiveContent instead */
  updateExclusiveContentBlockVisibility: Maybe<UpdateExclusiveContentBlockVisibilityPayload>
  /** @deprecated Use updateExclusiveContent instead */
  updateExclusiveContentFileBlock: Maybe<UpdateExclusiveContentFileBlockPayload>
  /** @deprecated Use updateExclusiveContent instead */
  updateExclusiveContentBlockMonetization: Maybe<UpdateExclusiveContentBlockMonetizationPayload>
  /** @deprecated Use updateExclusiveContent instead */
  updateExclusiveContentTextBlock: Maybe<UpdateExclusiveContentTextBlockPayload>
  add30DayTrialWithoutPaymentMethod: Maybe<Add30DayTrialWithoutPaymentMethodPayload>
  addCreatorSubscription: Maybe<AddCreatorSubscriptionPayload>
  addInternalSubscriptionSetupIntent: Maybe<AddInternalSubscriptionSetupIntentPayload>
  addFreeInvoiceOrder: Maybe<AddFreeInvoiceOrderPayload>
  addInvoice: Maybe<AddInvoicePayload>
  addInvoiceOrder: Maybe<AddInvoiceOrderPayload>
  addPaypalInvoiceOrder: Maybe<AddPaypalInvoiceOrderPayload>
  deleteInvoice: Maybe<DeleteInvoicePayload>
  publishInvoice: Maybe<PublishInvoicePayload>
  sendInvoiceViaEmail: Maybe<SendInvoiceViaEmailPayload>
  updateInvoice: Maybe<UpdateInvoicePayload>
  addFreeLiveStreamOrder: Maybe<AddFreeLiveStreamOrderPayload>
  addLiveStream: Maybe<AddLiveStreamPayload>
  addLiveStreamOrder: Maybe<AddLiveStreamOrderPayload>
  addPaypalLiveStreamOrder: Maybe<AddPaypalLiveStreamOrderPayload>
  deleteLiveStream: Maybe<DeleteLiveStreamPayload>
  publishLiveStream: Maybe<PublishLiveStreamPayload>
  unpublishLiveStream: Maybe<UnpublishLiveStreamPayload>
  updateLiveStream: Maybe<UpdateLiveStreamPayload>
  addMembership: Maybe<AddMembershipPayload>
  addMembershipSubscription: Maybe<AddMembershipSubscriptionPayload>
  deleteMembership: Maybe<DeleteMembershipPayload>
  updateMembership: Maybe<UpdateMembershipPayload>
  updateMembershipSubscription: Maybe<UpdateMembershipSubscriptionPayload>
  addDiscountCoupon: Maybe<AddDiscountCouponPayload>
  deleteDiscountCoupon: Maybe<DeleteDiscountCouponPayload>
  updateDiscountCoupon: Maybe<UpdateDiscountCouponPayload>
  updateDiscountCouponStatus: Maybe<UpdateDiscountCouponStatusPayload>
  generateMagicLinkToPurchase: Maybe<GenerateMagicLinkToPurchasePayload>
  generatePaypalOnboardingLink: Maybe<GeneratePaypalOnboardingLinkPayload>
  setInvoicesPreferences: Maybe<SetInvoicesPreferences>
  setPaypalPreferences: Maybe<SetPaypalPreferences>
  requestMagicLinkToPurchase: Maybe<RequestMagicLinkToPurchasePayload>
  cancelBaseSubscription: Maybe<CancelBaseSubscriptionPayload>
  addReceiptEmailCustomMessage: Maybe<AddReceiptEmailCustomMessagePayload>
  addResourceShortUrl: Maybe<AddResourceShortUrlPayload>
  addShortUrl: Maybe<AddShortUrlPayload>
  deleteShortUrl: Maybe<DeleteShortUrlPayload>
  updateShortUrl: Maybe<UpdateShortUrlPayload>
  acceptShoutoutOrder: Maybe<AcceptShoutoutOrderPayload>
  addFreeShoutoutOrder: Maybe<AddFreeShoutoutOrderPayload>
  addPaypalShoutoutOrder: Maybe<AddPaypalShoutoutOrderPayload>
  addShoutout: Maybe<AddShoutoutPayload>
  addShoutoutOrder: Maybe<AddShoutoutOrderPayload>
  deleteShoutout: Maybe<DeleteShoutoutPayload>
  fulfillShoutoutOrder: Maybe<FulfillShoutoutOrderPayload>
  publishShoutout: Maybe<PublishShoutoutPayload>
  rejectShoutoutOrder: Maybe<RejectShoutoutOrderPayload>
  unpublishShoutout: Maybe<UnpublishShoutoutPayload>
  updateCustomShoutoutQuestions: Maybe<UpdateCustomShoutoutQuestionsPayload>
  updateShoutout: Maybe<UpdateShoutoutPayload>
  addPaypalTipOrder: Maybe<AddPaypalTipOrderPayload>
  addTip: Maybe<AddTipPayload>
  /** @deprecated Use updateTipBlock instead */
  addTipBlockGoal: Maybe<AddTipBlockGoalPayload>
  addTipStripeSubscription: Maybe<AddTipStripeSubscriptionPayload>
  cancelSubscription: Maybe<CancelSubscriptionPayload>
  /** @deprecated Use updateTipBlock instead by passing an explicit 'null' to the 'goal' field */
  deleteTipBlockGoal: Maybe<DeleteTipBlockGoalPayload>
  resetTipBlockGoal: Maybe<ResetTipBlockGoalPayload>
  retryStripeInvoicePayment: Maybe<RetryStripeInvoicePaymentPayload>
  updateTipBlock: Maybe<UpdateTipBlockPayload>
  /** @deprecated Use updateTipBlock instead */
  updateTipBlockGoal: Maybe<UpdateTipBlockGoalPayload>
  bulkUpsertCreatorPolicies: Maybe<BulkUpsertCreatorPoliciesPayload>
  connectStripe: Maybe<ConnectStripePayload>
  deleteCreatorAccount: Maybe<DeleteCreatorAccountPayload>
  deleteCreatorPlanolyAccount: Maybe<DeleteCreatorPlanolyAccountPayload>
  deleteCreatorPlanolyWorkspace: Maybe<DeleteCreatorPlanolyWorkspacePayload>
  disconnectPaypal: Maybe<DisconnectPaypalPayload>
  disconnectStripe: Maybe<DisconnectStripePayload>
  deleteUserAccount: Maybe<DeleteUserAccountPayload>
  updateServiceCustomQuestions: Maybe<UpdateServiceCustomQuestionsPayload>
  updateServiceRatingConfiguration: Maybe<UpdateServiceRatingConfigurationPayload>
  upsertRating: Maybe<UpsertRatingPayload>
  /** @deprecated use upsertRating mutation instead */
  upsertTransactionRating: Maybe<UpsertTransactionRatingPayload>
  updateTestimonials: Maybe<UpdateTestimonialsPayload>
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationVerifyCreatorEmailArgs = {
  input: VerifyCreatorEmailInput
}

export type MutationUpdateCreatorArgs = {
  input: UpdateCreatorInput
}

export type MutationConnectMailchimpArgs = {
  input: ConnectMailchimpInput
}

export type MutationConnectGoogleArgs = {
  input: ConnectGoogleInput
}

export type MutationDisconnectGoogleArgs = {
  input: DisconnectGoogleInput
}

export type MutationConnectZoomArgs = {
  input: ConnectZoomInput
}

export type MutationDisconnectZoomArgs = {
  input: DisconnectZoomInput
}

export type MutationDisconnectMailchimpArgs = {
  input: DisconnectMailchimpInput
}

export type MutationUpdateCreatorApplicationFeeArgs = {
  input: UpdateCreatorApplicationFeeInput
}

export type MutationUpdateCreatorUsernameArgs = {
  input: UpdateCreatorUsernameInput
}

export type MutationAddCreatorCategoriesArgs = {
  input: AddCreatorCategoriesInput
}

export type MutationUpdateCreatorReferredByArgs = {
  input: UpdateCreatorReferredByInput
}

export type MutationDeleteCreatorReferredByArgs = {
  input: DeleteCreatorReferredByInput
}

export type MutationUpdateCreatorCategoriesArgs = {
  input: UpdateCreatorCategoriesInput
}

export type MutationUpdateCreatorOnboardingStepArgs = {
  input: UpdateCreatorOnboardingStepInput
}

export type MutationUpsertSocialLinkArgs = {
  input: UpsertSocialLinkInput
}

export type MutationUpdateVipStatusArgs = {
  input: UpdateVipStatusInput
}

export type MutationAddEmailToMailingListArgs = {
  input: AddEmailToMailingListInput
}

export type MutationUpdateLinkProfileArgs = {
  input: UpdateLinkProfileInput
}

export type MutationAddCustomBlockArgs = {
  input: AddCustomBlockInput
}

export type MutationUpdateCustomBlockArgs = {
  input: UpdateCustomBlockInput
}

export type MutationAddProductBlockArgs = {
  input: AddProductBlockInput
}

export type MutationUpdateProductBlockArgs = {
  input: UpdateProductBlockInput
}

export type MutationAddCoverAssetToProductBlockArgs = {
  input: AddCoverAssetToProductBlockInput
}

export type MutationDeleteCoverAssetFromProductBlockArgs = {
  input: DeleteCoverAssetFromProductBlockInput
}

export type MutationUpdateLinkBlockVisibilityArgs = {
  input: UpdateLinkBlockVisibilityInput
}

export type MutationUpdatePremiumBlockArgs = {
  input: UpdatePremiumBlockInput
}

export type MutationUpdateCarouselBlockArgs = {
  input: UpdateCarouselBlockInput
}

export type MutationAddItemToCarouselBlockArgs = {
  input: AddItemToCarouselBlockInput
}

export type MutationUpsertSocialIconArgs = {
  input: UpsertSocialIconInput
}

export type MutationDeleteSocialIconArgs = {
  input: DeleteSocialIconInput
}

export type MutationSortBlocksArgs = {
  input: SortBlocksInput
}

export type MutationSortBlockCardsArgs = {
  input: SortBlockCardsInput
}

export type MutationUpdateMailingListBlockArgs = {
  input: UpdateMailingListBlockInput
}

export type MutationSortSocialIconsArgs = {
  input: SortSocialIconsInput
}

export type MutationUpdateVideoBlockArgs = {
  input: UpdateVideoBlockInput
}

export type MutationUpdateMusicBlockArgs = {
  input: UpdateMusicBlockInput
}

export type MutationUpdateTwitterEmbedBlockArgs = {
  input: UpdateTwitterEmbedBlockInput
}

export type MutationAddVideoBlockArgs = {
  input: AddVideoBlockInput
}

export type MutationAddMusicBlockArgs = {
  input: AddMusicBlockInput
}

export type MutationAddTwitterEmbedBlockArgs = {
  input: AddTwitterEmbedBlockInput
}

export type MutationAddPremiumBlockArgs = {
  input: AddPremiumBlockInput
}

export type MutationAddTipBlockArgs = {
  input: AddTipBlockInput
}

export type MutationAddTextBlockArgs = {
  input: AddTextBlockInput
}

export type MutationUpdateTextBlockArgs = {
  input: UpdateTextBlockInput
}

export type MutationAddMailingListBlockArgs = {
  input: AddMailingListBlockInput
}

export type MutationAddSocialIconsBlockArgs = {
  input: AddSocialIconsBlockInput
}

export type MutationAddCarouselBlockArgs = {
  input: AddCarouselBlockInput
}

export type MutationUpdateLinkBackgroundArgs = {
  input: UpdateLinkBackgroundInput
}

export type MutationAssignLinkCustomDomainNameArgs = {
  input: AssignLinkCustomDomainNameInput
}

export type MutationUnassignLinkCustomDomainNameArgs = {
  input: UnassignLinkCustomDomainNameInput
}

export type MutationCheckLinkCustomDomainNameAvailabilityArgs = {
  input: CheckLinkCustomDomainNameAvailabilityInput
}

export type MutationBuyLinkCustomDomainNameArgs = {
  input: BuyLinkCustomDomainNameInput
}

export type MutationUpdateLinkColorsArgs = {
  input: UpdateLinkColorsInput
}

export type MutationUpdateLinkBlockStylesArgs = {
  input: UpdateLinkBlockStylesInput
}

export type MutationUpdateLinkStylesArgs = {
  input: UpdateLinkStylesInput
}

export type MutationNextAuthAdapterUpdateUserArgs = {
  input: NextAuthAdapterUpdateUserInput
}

export type MutationNextAuthAdapterGetAccessTokenArgs = {
  input: NextAuthAdapterGetAccessTokenInput
}

export type MutationNextAuthAdapterCreateUserArgs = {
  input: NextAuthAdapterCreateUserInput
}

export type MutationNextAuthAdapterValidateUserCredentialsArgs = {
  input: NextAuthAdapterValidateUserCredentialsInput
}

export type MutationNextAuthAdapterGetUserByEmailArgs = {
  input: NextAuthAdapterGetUserByEmailInput
}

export type MutationNextAuthAdapterGetUserByIdArgs = {
  input: NextAuthAdapterGetUserByIdInput
}

export type MutationNextAuthAdapterGetUserByAccountArgs = {
  input: NextAuthAdapterGetUserByAccountInput
}

export type MutationNextAuthAdapterLinkAccountArgs = {
  input: NextAuthAdapterLinkAccountInput
}

export type MutationCreateImportContentLinkRequestArgs = {
  input: CreateImportContentLinkRequestInput
}

export type MutationAuthorizeApprovedPaypalOrderArgs = {
  input: AuthorizeApprovedPaypalOrderInput
}

export type MutationReauthorizeApprovedPaypalOrderArgs = {
  input: ReauthorizeApprovedPaypalOrderInput
}

export type MutationAddStripeIntentArgs = {
  input: AddStripeIntentInput
}

export type MutationRefundTransactionArgs = {
  input: RefundTransactionInput
}

export type MutationCaptureApprovedPaypalOrderArgs = {
  input: CaptureApprovedPaypalOrderInput
}

export type MutationDisableActorArgs = {
  input: DisableActorInput
}

export type MutationEnableActorArgs = {
  input: EnableActorInput
}

export type MutationApplySaleDiscountArgs = {
  input: ApplySaleDiscountInput
}

export type MutationUnapplySaleDiscountArgs = {
  input: UnapplySaleDiscountInput
}

export type MutationAddCreatorToFirstPromoterArgs = {
  input: AddCreatorToFirstPromoterInput
}

export type MutationExportDataRequestArgs = {
  input: ExportDataRequestInput
}

export type MutationGenerateFirstPromoterIframeTokenArgs = {
  input: GenerateFirstPromoterIframeTokenInput
}

export type MutationUpdateCreatorSelectedGoogleCalendarsArgs = {
  input: InputMaybe<UpdateCreatorSelectedGoogleCalendarsInput>
}

export type MutationCompleteUserProfileArgs = {
  input: CompleteUserProfileInput
}

export type MutationFindOrCreatePlanolyUserArgs = {
  input: InputMaybe<FindOrCreatePlanolyUserInput>
}

export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationConnectShopifyArgs = {
  input: ConnectShopifyInput
}

export type MutationDisconnectShopifyArgs = {
  input: DisconnectShopifyInput
}

export type MutationSigninAsCreatorArgs = {
  input: SigninAsCreatorInput
}

export type MutationSigninBySingleUseTokenArgs = {
  input: SigninBySingleUseTokenInput
}

export type MutationSigninCreatorArgs = {
  input: SigninCreatorInput
}

export type MutationSignupCreatorArgs = {
  input: SignupCreatorInput
}

export type MutationUnlinkCreatorPlanolyAccountArgs = {
  input: UnlinkCreatorAccountFromPlanolyInput
}

export type MutationUpdateCreatorReplyToEmailArgs = {
  input: UpdateCreatorReplyToEmailInput
}

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput
}

export type MutationUpdateZapierWebhookSubscriptionArgs = {
  input: InputMaybe<UpdateZapierWebhookSubscriptionInput>
}

export type MutationCreateAssetArgs = {
  input: CreateAssetInput
}

export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput
}

export type MutationUpdateAssetModerationArgs = {
  input: UpdateAssetModerationInput
}

export type MutationAddItemToSocialFeedBlockArgs = {
  input: AddItemToSocialFeedBlockInput
}

export type MutationAddItemToSocialFeedBlockFromPlanolyArgs = {
  input: AddItemToSocialFeedBlockFromPlanolyInput
}

export type MutationAddShopifyProductBlockArgs = {
  input: AddShopifyProductBlockInput
}

export type MutationAddSocialFeedBlockArgs = {
  input: AddSocialFeedBlockInput
}

export type MutationAddThumbnailsBlockArgs = {
  input: AddThumbnailsBlockInput
}

export type MutationDeleteItemFromCarouselBlockArgs = {
  input: DeleteItemFromCarouselBlockInput
}

export type MutationDeleteLinkBlockArgs = {
  input: DeleteLinkBlockInput
}

export type MutationDeleteSocialFeedBlockItemArgs = {
  input: DeleteSocialFeedBlockItemInput
}

export type MutationReassignPurchasedLinkCustomDomainNameArgs = {
  input: InputMaybe<ReassignPurchasedLinkCustomDomainNameInput>
}

export type MutationSetPremiumTemplateArgs = {
  input: InputMaybe<SetPremiumTemplateInput>
}

export type MutationSortSocialFeedBlockItemsArgs = {
  input: SortSocialFeedBlockItemsInput
}

export type MutationUnsetPremiumTemplateArgs = {
  input: InputMaybe<UnsetPremiumTemplateInput>
}

export type MutationUpdateBrandingVisibilityArgs = {
  input: InputMaybe<UpdateBrandingVisibilityInput>
}

export type MutationUpdateLinkDesignArgs = {
  input: UpdateLinkDesignInput
}

export type MutationUpdateShopifyProductBlockArgs = {
  input: UpdateShopifyProductBlockInput
}

export type MutationUpdateSocialFeedBlockArgs = {
  input: UpdateSocialFeedBlockInput
}

export type MutationUpdateSocialFeedBlockItemArgs = {
  input: UpdateSocialFeedBlockItemInput
}

export type MutationUpdateThumbnailsBlockArgs = {
  input: UpdateThumbnailsBlockInput
}

export type MutationUpsertSocialIconsArgs = {
  input: UpsertSocialIconsInput
}

export type MutationAddConsultationArgs = {
  input: AddConsultationInput
}

export type MutationAddConsultationOrderArgs = {
  input: AddConsultationOrderInput
}

export type MutationAddFreeConsultationOrderArgs = {
  input: AddFreeConsultationOrderInput
}

export type MutationAddPaypalConsultationOrderArgs = {
  input: AddPaypalConsultationOrderInput
}

export type MutationCancelConsultationOrderArgs = {
  input: CancelConsultationOrderInput
}

export type MutationDeleteConsultationArgs = {
  input: DeleteConsultationInput
}

export type MutationPublishConsultationArgs = {
  input: PublishConsultationInput
}

export type MutationRescheduleConsultationOrderArgs = {
  input: RescheduleConsultationOrderInput
}

export type MutationUnpublishConsultationArgs = {
  input: UnpublishConsultationInput
}

export type MutationUpdateConsultationArgs = {
  input: UpdateConsultationInput
}

export type MutationUpdateConsultationAvailabilitiesArgs = {
  input: UpdateConsultationAvailabilitiesInput
}

export type MutationUpdateCustomConsultationQuestionsArgs = {
  input: UpdateCustomConsultationQuestionsInput
}

export type MutationAddEmailBroadcastArgs = {
  input: AddEmailBroadcastInput
}

export type MutationAddEmailFlowArgs = {
  input: AddEmailFlowInput
}

export type MutationDeleteEmailBroadcastArgs = {
  input: DeleteEmailBroadcastInput
}

export type MutationDeleteEmailFlowArgs = {
  input: DeleteEmailFlowInput
}

export type MutationSendTestCrmEmailArgs = {
  input: SendTestCrmEmailInput
}

export type MutationUpdateCrmCustomerSubscriptionStatusArgs = {
  input: UpdateCrmCustomerSubscriptionStatusInput
}

export type MutationUpdateDraftEmailFlowArgs = {
  input: UpdateDraftEmailFlowInput
}

export type MutationUpdateEmailBroadcastArgs = {
  input: UpdateEmailBroadcastInput
}

export type MutationUpdatePublishedEmailFlowArgs = {
  input: UpdatePublishedEmailFlowInput
}

export type MutationAddECourseArgs = {
  input: AddECourseInput
}

export type MutationAddECourseLessonArgs = {
  input: AddECourseLessonInput
}

export type MutationAddECourseLessonFileArgs = {
  input: AddECourseLessonFileInput
}

export type MutationAddECourseOrderArgs = {
  input: AddECourseOrderInput
}

export type MutationAddECourseTrailerArgs = {
  input: AddECourseTrailerInput
}

export type MutationAddFreeECourseOrderArgs = {
  input: AddFreeECourseOrderInput
}

export type MutationAddPaypalECourseOrderArgs = {
  input: AddPaypalECourseOrderInput
}

export type MutationDeleteECourseArgs = {
  input: DeleteECourseInput
}

export type MutationDeleteECourseLessonArgs = {
  input: DeleteECourseLessonInput
}

export type MutationDeleteECourseLessonFileArgs = {
  input: DeleteECourseLessonFileInput
}

export type MutationDeleteECourseTrailerArgs = {
  input: DeleteECourseTrailerInput
}

export type MutationMarkECourseLessonAsVisibleOrInvisibleArgs = {
  input: MarkECourseLessonAsVisibleOrInvisibleInput
}

export type MutationMarkECourseLessonFileAsCompletedOrUncompletedArgs = {
  input: MarkECourseLessonFileAsCompletedOrUncompletedInput
}

export type MutationMarkECourseLessonFileAsVisibleOrInvisibleArgs = {
  input: MarkECourseLessonFileAsVisibleOrInvisibleInput
}

export type MutationPublishECourseArgs = {
  input: PublishECourseInput
}

export type MutationSortECourseLessonFilesArgs = {
  input: SortECourseLessonFilesInput
}

export type MutationSortECourseLessonsArgs = {
  input: SortECourseLessonsInput
}

export type MutationSortECourseTrailersArgs = {
  input: SortECourseTrailersInput
}

export type MutationUnpublishECourseArgs = {
  input: UnpublishECourseInput
}

export type MutationUpdateCustomerECourseAccessArgs = {
  input: UpdateCustomerECourseAccessInput
}

export type MutationUpdateECourseArgs = {
  input: UpdateECourseInput
}

export type MutationUpdateECourseLessonArgs = {
  input: UpdateECourseLessonInput
}

export type MutationUpdateECourseLessonFileArgs = {
  input: UpdateECourseLessonFileInput
}

export type MutationUpdateECourseTrailerArgs = {
  input: UpdateECourseTrailerInput
}

export type MutationAddExclusiveContentArgs = {
  input: AddExclusiveContentInput
}

export type MutationAddExclusiveContentFileBlockArgs = {
  input: AddExclusiveContentFileBlockInput
}

export type MutationAddExclusiveContentOrderArgs = {
  input: AddExclusiveContentOrderInput
}

export type MutationAddExclusiveContentTextBlockArgs = {
  input: AddExclusiveContentTextBlockInput
}

export type MutationAddFreeExclusiveContentOrderArgs = {
  input: AddFreeExclusiveContentOrderInput
}

export type MutationAddPaypalExclusiveContentOrderArgs = {
  input: AddPaypalExclusiveContentOrderInput
}

export type MutationDeleteExclusiveContentArgs = {
  input: DeleteExclusiveContentInput
}

export type MutationDeleteExclusiveContentBlockArgs = {
  input: DeleteExclusiveContentBlockInput
}

export type MutationPublishExclusiveContentArgs = {
  input: PublishExclusiveContentInput
}

export type MutationSortExclusiveContentBlocksArgs = {
  input: SortExclusiveContentBlocksInput
}

export type MutationUnpublishExclusiveContentArgs = {
  input: UnpublishExclusiveContentInput
}

export type MutationUpdateCustomerExclusiveContentAccessArgs = {
  input: UpdateCustomerExclusiveContentAccessInput
}

export type MutationUpdateExclusiveContentArgs = {
  input: UpdateExclusiveContentInput
}

export type MutationUpdateExclusiveContentBlockVisibilityArgs = {
  input: UpdateExclusiveContentBlockVisibilityInput
}

export type MutationUpdateExclusiveContentFileBlockArgs = {
  input: UpdateExclusiveContentFileBlockInput
}

export type MutationUpdateExclusiveContentBlockMonetizationArgs = {
  input: UpdateExclusiveContentBlockMonetizationInput
}

export type MutationUpdateExclusiveContentTextBlockArgs = {
  input: UpdateExclusiveContentTextBlockInput
}

export type MutationAdd30DayTrialWithoutPaymentMethodArgs = {
  input: Add30DayTrialWithoutPaymentMethodInput
}

export type MutationAddCreatorSubscriptionArgs = {
  input: AddCreatorSubscriptionInput
}

export type MutationAddInternalSubscriptionSetupIntentArgs = {
  input: AddInternalSubscriptionSetupIntent
}

export type MutationAddFreeInvoiceOrderArgs = {
  input: AddFreeInvoiceOrderInput
}

export type MutationAddInvoiceArgs = {
  input: AddInvoiceInput
}

export type MutationAddInvoiceOrderArgs = {
  input: AddInvoiceOrderInput
}

export type MutationAddPaypalInvoiceOrderArgs = {
  input: AddPaypalInvoiceOrderInput
}

export type MutationDeleteInvoiceArgs = {
  input: DeleteInvoiceInput
}

export type MutationPublishInvoiceArgs = {
  input: PublishInvoiceInput
}

export type MutationSendInvoiceViaEmailArgs = {
  input: SendInvoiceViaEmailInput
}

export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput
}

export type MutationAddFreeLiveStreamOrderArgs = {
  input: AddFreeLiveStreamOrderInput
}

export type MutationAddLiveStreamArgs = {
  input: AddLiveStreamInput
}

export type MutationAddLiveStreamOrderArgs = {
  input: AddLiveStreamOrderInput
}

export type MutationAddPaypalLiveStreamOrderArgs = {
  input: AddPaypalLiveStreamOrderInput
}

export type MutationDeleteLiveStreamArgs = {
  input: DeleteLiveStreamInput
}

export type MutationPublishLiveStreamArgs = {
  input: PublishLiveStreamInput
}

export type MutationUnpublishLiveStreamArgs = {
  input: UnpublishLiveStreamInput
}

export type MutationUpdateLiveStreamArgs = {
  input: UpdateLiveStreamInput
}

export type MutationAddMembershipArgs = {
  input: AddMembershipInput
}

export type MutationAddMembershipSubscriptionArgs = {
  input: AddMembershipSubscriptionInput
}

export type MutationDeleteMembershipArgs = {
  input: DeleteMembershipInput
}

export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput
}

export type MutationUpdateMembershipSubscriptionArgs = {
  input: UpdateMembershipSubscriptionInput
}

export type MutationAddDiscountCouponArgs = {
  input: AddDiscountCouponInput
}

export type MutationDeleteDiscountCouponArgs = {
  input: DeleteDiscountCouponInput
}

export type MutationUpdateDiscountCouponArgs = {
  input: UpdateDiscountCouponInput
}

export type MutationUpdateDiscountCouponStatusArgs = {
  input: UpdateDiscountCouponStatusInput
}

export type MutationGenerateMagicLinkToPurchaseArgs = {
  input: GenerateMagicLinkToPurchaseInput
}

export type MutationGeneratePaypalOnboardingLinkArgs = {
  input: GeneratePaypalOnboardingLinkInput
}

export type MutationSetInvoicesPreferencesArgs = {
  input: SetInvoicesPreferencesInput
}

export type MutationSetPaypalPreferencesArgs = {
  input: SetPaypalPreferencesInput
}

export type MutationRequestMagicLinkToPurchaseArgs = {
  input: RequestMagicLinkToPurchaseInput
}

export type MutationCancelBaseSubscriptionArgs = {
  input: CancelBaseSubscriptionInput
}

export type MutationAddReceiptEmailCustomMessageArgs = {
  input: AddReceiptEmailCustomMessageInput
}

export type MutationAddResourceShortUrlArgs = {
  input: AddResourceShortUrlInput
}

export type MutationAddShortUrlArgs = {
  input: AddShortUrlInput
}

export type MutationDeleteShortUrlArgs = {
  input: DeleteShortUrlInput
}

export type MutationUpdateShortUrlArgs = {
  input: UpdateShortUrlInput
}

export type MutationAcceptShoutoutOrderArgs = {
  input: AcceptShoutoutOrderInput
}

export type MutationAddFreeShoutoutOrderArgs = {
  input: AddFreeShoutoutOrderInput
}

export type MutationAddPaypalShoutoutOrderArgs = {
  input: AddPaypalShoutoutOrderInput
}

export type MutationAddShoutoutArgs = {
  input: AddShoutoutInput
}

export type MutationAddShoutoutOrderArgs = {
  input: AddShoutoutOrderInput
}

export type MutationDeleteShoutoutArgs = {
  input: DeleteShoutoutInput
}

export type MutationFulfillShoutoutOrderArgs = {
  input: FulfillShoutoutOrderInput
}

export type MutationPublishShoutoutArgs = {
  input: PublishShoutoutInput
}

export type MutationRejectShoutoutOrderArgs = {
  input: RejectShoutoutOrderInput
}

export type MutationUnpublishShoutoutArgs = {
  input: UnpublishShoutoutInput
}

export type MutationUpdateCustomShoutoutQuestionsArgs = {
  input: UpdateCustomShoutoutQuestionsInput
}

export type MutationUpdateShoutoutArgs = {
  input: UpdateShoutoutInput
}

export type MutationAddPaypalTipOrderArgs = {
  input: AddPaypalTipOrderInput
}

export type MutationAddTipArgs = {
  input: AddTipInput
}

export type MutationAddTipBlockGoalArgs = {
  input: AddTipBlockGoalInput
}

export type MutationAddTipStripeSubscriptionArgs = {
  input: AddTipStripeSubscriptionInput
}

export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput
}

export type MutationDeleteTipBlockGoalArgs = {
  input: DeleteTipBlockGoalInput
}

export type MutationResetTipBlockGoalArgs = {
  input: ResetTipBlockGoalInput
}

export type MutationRetryStripeInvoicePaymentArgs = {
  input: RetryStripeInvoicePaymentInput
}

export type MutationUpdateTipBlockArgs = {
  input: UpdateTipBlockInput
}

export type MutationUpdateTipBlockGoalArgs = {
  input: UpdateTipBlockGoalInput
}

export type MutationBulkUpsertCreatorPoliciesArgs = {
  input: BulkUpsertCreatorPoliciesInput
}

export type MutationConnectStripeArgs = {
  input: ConnectStripeInput
}

export type MutationDeleteCreatorAccountArgs = {
  input: DeleteCreatorAccountInput
}

export type MutationDeleteCreatorPlanolyAccountArgs = {
  input: DeleteCreatorPlanolyAccountInput
}

export type MutationDeleteCreatorPlanolyWorkspaceArgs = {
  input: DeleteCreatorPlanolyWorkspaceInput
}

export type MutationDisconnectPaypalArgs = {
  input: DisconnectPaypalInput
}

export type MutationDisconnectStripeArgs = {
  input: DisconnectStripeInput
}

export type MutationDeleteUserAccountArgs = {
  input: DeleteUserAccountInput
}

export type MutationUpdateServiceCustomQuestionsArgs = {
  input: UpdateServiceCustomQuestionsInput
}

export type MutationUpdateServiceRatingConfigurationArgs = {
  input: UpdateServiceRatingConfigurationInput
}

export type MutationUpsertRatingArgs = {
  input: UpsertRatingInput
}

export type MutationUpsertTransactionRatingArgs = {
  input: UpsertTransactionRatingInput
}

export type MutationUpdateTestimonialsArgs = {
  input: UpdateTestimonialsInput
}

/** ISO 3166-1 alpha-2 country codes with some differences. */
export enum CountryCode {
  /** Afghanistan */
  Af = 'AF',
  /** Aland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** The Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Brazil */
  Br = 'BR',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cape Verde */
  Cv = 'CV',
  /** The Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** The Comoros */
  Km = 'KM',
  /** The Congo */
  Cg = 'CG',
  /** The Democratic Republic of the Congo */
  Cd = 'CD',
  /** Costa Rica */
  Cr = 'CR',
  /** Cote d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Cyprus */
  Cy = 'CY',
  /** The Czech Republic */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** The Dominican Republic */
  Do = 'DO',
  /** Timor-Leste */
  Tl = 'TL',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Ethiopia */
  Et = 'ET',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** The Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Greece */
  Gr = 'GR',
  /** Grenada */
  Gd = 'GD',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** The Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** The Democratic People's Republic of Korea */
  Kp = 'KP',
  /** The Republic of Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** The Marshall Islands */
  Mh = 'MH',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** The Netherlands */
  Nl = 'NL',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** The Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** The Philippines */
  Ph = 'PH',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Qatar */
  Qa = 'QA',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Vincent and The Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** The Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Swaziland */
  Sz = 'SZ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Macedonia */
  Mk = 'MK',
  /** Togo */
  Tg = 'TG',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** The United Arab Emirates */
  Ae = 'AE',
  /** The United Kingdom */
  Gb = 'GB',
  /** The United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Viet Nam */
  Vn = 'VN',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
  /** American Samoa */
  As = 'AS',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Aruba */
  Aw = 'AW',
  /** Bermuda */
  Bm = 'BM',
  /** Bouvet Island */
  Bv = 'BV',
  /** The British Indian Ocean Territory */
  Io = 'IO',
  /** Cayman Islands */
  Ky = 'KY',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos Islands */
  Cc = 'CC',
  /** Cook Islands */
  Ck = 'CK',
  /** Falkland Islands */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** The French Southern Territories */
  Tf = 'TF',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Hong Kong */
  Hk = 'HK',
  /** Macao */
  Mo = 'MO',
  /** Martinique */
  Mq = 'MQ',
  /** Mayotte */
  Yt = 'YT',
  /** Montserrat */
  Ms = 'MS',
  /** Netherlands Antilles */
  An = 'AN',
  /** New Caledonia */
  Nc = 'NC',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** The Occupied Palestinian Territory */
  Ps = 'PS',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Réunion */
  Re = 'RE',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena */
  Sh = 'SH',
  /** Saint Martin */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** South Georgia and The South Sandwich Islands */
  Gs = 'GS',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Taiwan */
  Tw = 'TW',
  /** Tokelau */
  Tk = 'TK',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** British Virgin Islands */
  Vg = 'VG',
  /** US Virgin Islands */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
}

/** List of available google fonts  */
export enum FontFamilyV2 {
  /** Lato */
  Lato = 'LATO',
  /** Inter */
  Inter = 'INTER',
  /** Poppins */
  Poppins = 'POPPINS',
  /** Montserrat */
  Montserrat = 'MONTSERRAT',
  /** Alegreya */
  Alegreya = 'ALEGREYA',
  /** Abril Fatface */
  AbrilFatface = 'ABRIL_FATFACE',
  /** Anton */
  Anton = 'ANTON',
  /** PT Serif */
  PtSerif = 'PT_SERIF',
  /** Concert One */
  ConcertOne = 'CONCERT_ONE',
  /** Playfair Display */
  PlayfairDisplay = 'PLAYFAIR_DISPLAY',
  /** Pacifico */
  Pacifico = 'PACIFICO',
  /** Kalam */
  Kalam = 'KALAM',
  /** Rakkas */
  Rakkas = 'RAKKAS',
  /** Dancing Script */
  DancingScript = 'DANCING_SCRIPT',
  /** BioRhyme */
  Biorhyme = 'BIORHYME',
  /** IBM Plex Sans */
  IbmPlexSans = 'IBM_PLEX_SANS',
  /** Roboto */
  Roboto = 'ROBOTO',
  /** Nunito */
  Nunito = 'NUNITO',
  /** Amatic SC */
  AmaticSc = 'AMATIC_SC',
  /** Avro */
  Avro = 'AVRO',
  /** Inconsolata */
  Inconsolata = 'INCONSOLATA',
  /** Permanent Marker */
  PermanentMarker = 'PERMANENT_MARKER',
  /** Ranchers */
  Ranchers = 'RANCHERS',
  /** Space Grotesk */
  SpaceGrotesk = 'SPACE_GROTESK',
  /** DM Sans */
  DmSans = 'DM_SANS',
  /** Cormorant */
  Cormorant = 'CORMORANT',
  /** Archivo */
  Archivo = 'ARCHIVO',
  /** Chivo */
  Chivo = 'CHIVO',
}

/** List of available google fonts  */
export enum LinkHeadingFontFamily {
  /** Lato */
  Lato = 'LATO',
  /** Inter */
  Inter = 'INTER',
  /** Poppins */
  Poppins = 'POPPINS',
  /** Montserrat */
  Montserrat = 'MONTSERRAT',
  /** Alegreya */
  Alegreya = 'ALEGREYA',
  /** Abril Fatface */
  AbrilFatface = 'ABRIL_FATFACE',
  /** Anton */
  Anton = 'ANTON',
  /** PT Serif */
  PtSerif = 'PT_SERIF',
  /** Concert One */
  ConcertOne = 'CONCERT_ONE',
  /** Playfair Display */
  PlayfairDisplay = 'PLAYFAIR_DISPLAY',
  /** Pacifico */
  Pacifico = 'PACIFICO',
  /** Kalam */
  Kalam = 'KALAM',
  /** Rakkas */
  Rakkas = 'RAKKAS',
  /** Dancing Script */
  DancingScript = 'DANCING_SCRIPT',
  /** BioRhyme */
  Biorhyme = 'BIORHYME',
  /** IBM Plex Sans */
  IbmPlexSans = 'IBM_PLEX_SANS',
  /** Roboto */
  Roboto = 'ROBOTO',
  /** Nunito */
  Nunito = 'NUNITO',
  /** Amatic SC */
  AmaticSc = 'AMATIC_SC',
  /** Avro */
  Avro = 'AVRO',
  /** Inconsolata */
  Inconsolata = 'INCONSOLATA',
  /** Permanent Marker */
  PermanentMarker = 'PERMANENT_MARKER',
  /** Ranchers */
  Ranchers = 'RANCHERS',
  /** Space Grotesk */
  SpaceGrotesk = 'SPACE_GROTESK',
  /** DM Sans */
  DmSans = 'DM_SANS',
  /** Cormorant */
  Cormorant = 'CORMORANT',
  /** Archivo */
  Archivo = 'ARCHIVO',
  /** Chivo */
  Chivo = 'CHIVO',
}

/** List of google fonts for link body */
export enum LinkBodyFontFamily {
  /** Lato */
  Lato = 'LATO',
  /** Inter */
  Inter = 'INTER',
  /** Poppins */
  Poppins = 'POPPINS',
  /** Montserrat */
  Montserrat = 'MONTSERRAT',
  /** Alegreya */
  Alegreya = 'ALEGREYA',
  /** PT Serif */
  PtSerif = 'PT_SERIF',
  /** Concert One */
  ConcertOne = 'CONCERT_ONE',
  /** Playfair Display */
  PlayfairDisplay = 'PLAYFAIR_DISPLAY',
  /** Kalam */
  Kalam = 'KALAM',
  /** BioRhyme */
  Biorhyme = 'BIORHYME',
  /** IBM Plex Sans */
  IbmPlexSans = 'IBM_PLEX_SANS',
  /** Roboto */
  Roboto = 'ROBOTO',
  /** Nunito */
  Nunito = 'NUNITO',
  /** Avro */
  Avro = 'AVRO',
  /** Inconsolata */
  Inconsolata = 'INCONSOLATA',
  /** Space Grotesk */
  SpaceGrotesk = 'SPACE_GROTESK',
  /** DM Sans */
  DmSans = 'DM_SANS',
  /** Cormorant */
  Cormorant = 'CORMORANT',
  /** Archivo */
  Archivo = 'ARCHIVO',
  /** Chivo */
  Chivo = 'CHIVO',
}

export type PageInfo = {
  __typename?: 'PageInfo'
  hasNextPage: Scalars['Boolean']
  endCursor: Maybe<Scalars['String']>
  startCursor: Maybe<Scalars['String']>
  hasPreviousPage: Scalars['Boolean']
}

export type LiveStreamAttendeesConnection = {
  __typename?: 'LiveStreamAttendeesConnection'
  edges: Maybe<Array<Maybe<LiveStreamAttendeesEdge>>>
  nodes: Maybe<Array<Maybe<User>>>
}

export enum LiveStreamStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type LiveStreamAttendeesEdge = {
  __typename?: 'LiveStreamAttendeesEdge'
  addedAt: Scalars['DateTime']
  cursor: Scalars['String']
  node: User
}

export type LiveStream = Node &
  RatingInterface &
  TestimonialInterface & {
    __typename?: 'LiveStream'
    coverAsset: Maybe<Asset>
    databaseId: Scalars['String']
    discountCoupons: Maybe<Array<DiscountCoupon>>
    id: Scalars['ID']
    isDeletable: Scalars['Boolean']
    isInsideLinkBlock: Scalars['Boolean']
    joinUrl: Maybe<Scalars['URL']>
    owner: Creator
    platform: LiveStreamPlatform
    price: Maybe<Money>
    registeredUsers: LiveStreamAttendeesConnection
    registeredUsersCount: Scalars['Int']
    saleDiscount: Maybe<Discount>
    startUrl: Maybe<Scalars['URL']>
    title: Maybe<Scalars['String']>
    viewerIsRegistered: Scalars['Boolean']
    ratingConfig: Maybe<MonetizableServiceRatingConfig>
    rating: Maybe<MonetizableServiceRating>
    ratings: Maybe<RatingsConnection>
    testimonialConfig: Maybe<MonetizableServiceTestimonialConfig>
    testimonials: Maybe<TestimonialsConnection>
    shortUrl: Maybe<ShortUrlEmbedded>
    slug: Maybe<Scalars['String']>
    startAt: Maybe<Scalars['DateTime']>
    description: Maybe<Scalars['String']>
    descriptionV2: Maybe<Scalars['String']>
    descriptionHTML: Maybe<Scalars['String']>
    maxUser: Maybe<Scalars['Int']>
    createdAt: Scalars['DateTime']
    requirePhone: Scalars['Boolean']
    timezone: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplate: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplateV2: Maybe<Scalars['String']>
    status: LiveStreamStatus
    duration: Maybe<Scalars['Int']>
    questions: Maybe<Array<Maybe<CustomQuestion>>>
  }

export type LiveStreamRegisteredUsersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
}

export type LiveStreamRatingsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type Asset = Node & {
  __typename?: 'Asset'
  id: Scalars['ID']
  pusherChannel: Maybe<Scalars['String']>
  file: AssetFile
  state: Array<AssetState>
  facades: Maybe<AssetFacade>
}

export enum ExclusiveContentStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export enum StripeStatus {
  Canceled = 'CANCELED',
  Active = 'ACTIVE',
}

export enum PayoutPlatform {
  Stripe = 'STRIPE',
}

export enum EmailLanguage {
  En = 'EN',
  Fr = 'FR',
}

export type TipSetting = {
  __typename?: 'TipSetting'
  subscriptionTiers: Maybe<Array<SubscriptionPlan>>
  subscriptionEnabled: Scalars['Boolean']
}

export type CreatorSetting = {
  __typename?: 'CreatorSetting'
  tips: TipSetting
}

export type CreatorMetadata = {
  __typename?: 'CreatorMetadata'
  hasExclusiveContent: Scalars['Boolean']
  hasECourses: Scalars['Boolean']
  hasLiveStreams: Scalars['Boolean']
  hasShoutouts: Scalars['Boolean']
  hasConsultations: Scalars['Boolean']
  hasCategories: Scalars['Boolean']
  hasPassedLinkImportStep: Scalars['Boolean']
  hasInvoices: Scalars['Boolean']
}

export enum OnboardingStep {
  ImportBioStep = 'importBioStep',
  MonetizationFeaturesStep = 'monetizationFeaturesStep',
  CategoryChoiceStep = 'categoryChoiceStep',
  TemplateChoiceStep = 'templateChoiceStep',
  PlanChoiceStep = 'planChoiceStep',
}

export enum MonetizationFeature {
  Tipping = 'tipping',
  Shoutouts = 'shoutouts',
  Livestreams = 'livestreams',
  ExclusiveContents = 'exclusiveContents',
  Meetings = 'meetings',
  ECourses = 'eCourses',
  Invoices = 'invoices',
  Memberships = 'memberships',
}

export enum OnboardingFeature {
  Tipping = 'tipping',
  Shoutouts = 'shoutouts',
  Livestreams = 'livestreams',
  ExclusiveContents = 'exclusiveContents',
  Meetings = 'meetings',
  ECourses = 'eCourses',
  Invoices = 'invoices',
  Memberships = 'memberships',
  MailingList = 'mailingList',
  CustomLink = 'customLink',
}

export type MonetizationFeaturesStepInput = {
  selectedFeatures: InputMaybe<Array<InputMaybe<OnboardingFeature>>>
}

export enum OnboardingSocialIcon {
  Instagram = 'INSTAGRAM',
  Tiktok = 'TIKTOK',
  Youtube = 'YOUTUBE',
  Linkedin = 'LINKEDIN',
  Pinterest = 'PINTEREST',
  Website = 'WEBSITE',
}

export type SocialLink = {
  url: Scalars['String']
  platform: OnboardingSocialIcon
}

export type ImportBioStepInput = {
  bioLink: InputMaybe<Scalars['String']>
  socialLinks: InputMaybe<Array<SocialLink>>
}

export type CategoryChoiceStepInput = {
  choices: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TemplateChoiceStepInput = {
  choice: InputMaybe<Scalars['ID']>
}

export type PlanChoiceStepInput = {
  choice: InputMaybe<PlanChoice>
  pricingPlansVariation: InputMaybe<PricingPlansVariation>
}

export type UpdateCreatorOnboardingStepData = {
  importBioStep: InputMaybe<ImportBioStepInput>
  monetizationFeaturesStep: InputMaybe<MonetizationFeaturesStepInput>
  categoryChoiceStep: InputMaybe<CategoryChoiceStepInput>
  templateChoiceStep: InputMaybe<TemplateChoiceStepInput>
  planChoiceStep: InputMaybe<PlanChoiceStepInput>
}

export type MonetizationFeaturesStep = {
  __typename?: 'MonetizationFeaturesStep'
  selectedFeatures: Maybe<Array<Maybe<OnboardingFeature>>>
}

export type ImportBioStep = {
  __typename?: 'ImportBioStep'
  bioLink: Maybe<Scalars['String']>
  socialLinks: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CategoryChoiceStep = {
  __typename?: 'CategoryChoiceStep'
  choices: Maybe<Array<Maybe<Scalars['String']>>>
}

export type TemplateChoiceStep = {
  __typename?: 'TemplateChoiceStep'
  choice: Maybe<Scalars['ID']>
}

export enum PlanChoice {
  Free = 'FREE',
  Starter = 'STARTER',
  Pro = 'PRO',
  ProPlus = 'PRO_PLUS',
}

export enum PricingPlansVariation {
  BasicAndPro = 'BASIC_AND_PRO',
  StarterAndPro = 'STARTER_AND_PRO',
  AllPlans = 'All_PLANS',
}

export type PlanChoiceStep = {
  __typename?: 'PlanChoiceStep'
  choice: Maybe<PlanChoice>
  pricingPlansVariation: Maybe<PricingPlansVariation>
}

export type OnboardingData = {
  __typename?: 'OnboardingData'
  importBioStep: Maybe<ImportBioStep>
  monetizationFeaturesStep: Maybe<MonetizationFeaturesStep>
  categoryChoiceStep: Maybe<CategoryChoiceStep>
  templateChoiceStep: Maybe<TemplateChoiceStep>
  planChoiceStep: Maybe<PlanChoiceStep>
}

export type PaypalPreferences = {
  __typename?: 'PaypalPreferences'
  enablePayLaterOption: Scalars['Boolean']
}

export type Preferences = {
  __typename?: 'Preferences'
  paypal: Maybe<PaypalPreferences>
  invoices: Maybe<InvoicesPreferences>
}

export type GoogleCalendar = {
  __typename?: 'GoogleCalendar'
  id: Scalars['String']
  primary: Scalars['Boolean']
  selected: Scalars['Boolean']
  title: Scalars['String']
}

export type GoogleCalendars = {
  __typename?: 'GoogleCalendars'
  list: Array<Maybe<GoogleCalendar>>
}

export type ExclusiveContent = Node &
  RatingInterface &
  TestimonialInterface & {
    __typename?: 'ExclusiveContent'
    id: Scalars['ID']
    ratingConfig: Maybe<MonetizableServiceRatingConfig>
    rating: Maybe<MonetizableServiceRating>
    ratings: Maybe<RatingsConnection>
    testimonialConfig: Maybe<MonetizableServiceTestimonialConfig>
    testimonials: Maybe<TestimonialsConnection>
    shortUrl: Maybe<ShortUrlEmbedded>
    slug: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    description: Maybe<Scalars['String']>
    descriptionV2: Maybe<Scalars['String']>
    descriptionHTML: Maybe<Scalars['String']>
    price: Maybe<Money>
    databaseId: Scalars['String']
    owner: Creator
    coverAsset: Maybe<Asset>
    saleDiscount: Maybe<Discount>
    status: ExclusiveContentStatusEnum
    blocks: Array<Maybe<ExclusiveContentBlockItem>>
    isInsideLinkBlock: Scalars['Boolean']
    isDeletable: Scalars['Boolean']
    viewerHasPurchased: Scalars['Boolean']
    discountCoupons: Maybe<Array<DiscountCoupon>>
    display: ExclusiveContentDisplayEnum
    receiptEmailCustomMessageTemplate: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplateV2: Maybe<Scalars['String']>
    questions: Maybe<Array<Maybe<CustomQuestion>>>
  }

export type ExclusiveContentRatingsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type ExclusiveContentOrder = Node &
  Transaction &
  OrderRatingInterface & {
    __typename?: 'ExclusiveContentOrder'
    id: Scalars['ID']
    user: User
    price: Money
    platform: TransactionPlatform
    status: TransactionStatus
    createdAt: Scalars['DateTime']
    exclusiveContent: Maybe<ExclusiveContent>
    creator: Creator
    isPayedOut: Scalars['Boolean']
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    ownerMessage: Maybe<Scalars['String']>
    refund: Maybe<TransactionRefund>
    paidAt: Maybe<Scalars['DateTime']>
    rating: Maybe<Rating>
    questionAnswers: Maybe<Array<Maybe<CustomQuestionAnswer>>>
    accessRevokedAt: Maybe<Scalars['DateTime']>
  }

export type ExclusiveContentOrderConnection = {
  __typename?: 'ExclusiveContentOrderConnection'
  edges: Maybe<Array<Maybe<ExclusiveContentOrderEdge>>>
  nodes: Maybe<Array<Maybe<ExclusiveContentOrder>>>
  pageInfo: PageInfo
}

export type ExclusiveContentOrderEdge = {
  __typename?: 'ExclusiveContentOrderEdge'
  cursor: Scalars['String']
  node: ExclusiveContentOrder
}

export type ShoutoutOrder = Node &
  Transaction &
  OrderRatingInterface & {
    __typename?: 'ShoutoutOrder'
    id: Scalars['ID']
    user: User
    price: Money
    platform: TransactionPlatform
    status: TransactionStatus
    createdAt: Scalars['DateTime']
    isPayedOut: Scalars['Boolean']
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    to: Scalars['String']
    from: Maybe<Scalars['String']>
    asset: Maybe<Asset>
    creator: Creator
    expiresAt: Maybe<Scalars['DateTime']>
    description: Scalars['String']
    isForOwner: Scalars['Boolean']
    requestStatus: ShoutoutOrderStatus
    occasion: ShoutoutOrderOccasion
    displayOnCreatorProfile: Scalars['Boolean']
    shoutout: Maybe<Shoutout>
    creatorMessage: Maybe<Scalars['String']>
    questionAnswers: Maybe<Array<Maybe<CustomQuestionAnswer>>>
    paidAt: Maybe<Scalars['DateTime']>
    couponCode: Maybe<Scalars['String']>
    refund: Maybe<TransactionRefund>
    rating: Maybe<Rating>
    expiredAt: Maybe<Scalars['DateTime']>
    expiresInDays: Scalars['Int']
  }

export enum ShoutoutOrderOccasion {
  Gift = 'GIFT',
  /** @deprecated No longer supported */
  Birthday = 'BIRTHDAY',
  /** @deprecated No longer supported */
  FathersDay = 'FATHERS_DAY',
  /** @deprecated No longer supported */
  MothersDay = 'MOTHERS_DAY',
  /** @deprecated No longer supported */
  Advice = 'ADVICE',
  /** @deprecated No longer supported */
  Announcement = 'ANNOUNCEMENT',
  /** @deprecated No longer supported */
  Wedding = 'WEDDING',
  /** @deprecated No longer supported */
  Anniversary = 'ANNIVERSARY',
  Question = 'QUESTION',
  Other = 'OTHER',
  ShoutOut = 'SHOUT_OUT',
  Reading = 'READING',
  Consultation = 'CONSULTATION',
}

export enum ShoutoutOrderStatus {
  Pending = 'PENDING',
  Fulfilled = 'FULFILLED',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Expired = 'EXPIRED',
}

export type ShoutoutOrderConnection = {
  __typename?: 'ShoutoutOrderConnection'
  edges: Maybe<Array<Maybe<ShoutoutOrderEdge>>>
  nodes: Maybe<Array<Maybe<ShoutoutOrder>>>
  pageInfo: PageInfo
}

export type ShoutoutOrderEdge = {
  __typename?: 'ShoutoutOrderEdge'
  cursor: Scalars['String']
  node: ShoutoutOrder
}

export type Shoutout = Node &
  RatingInterface &
  TestimonialInterface & {
    __typename?: 'Shoutout'
    id: Scalars['ID']
    databaseId: Scalars['String']
    price: Maybe<Money>
    description: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    owner: Creator
    status: ShoutoutStatus
    coverAsset: Maybe<Asset>
    isDeletable: Scalars['Boolean']
    createdAt: Scalars['DateTime']
    isInsideLinkBlock: Scalars['Boolean']
    responseType: ShoutoutResponseType
    questions: Maybe<Array<Maybe<CustomQuestion>>>
    saleDiscount: Maybe<Discount>
    discountCoupons: Maybe<Array<DiscountCoupon>>
    receiptEmailCustomMessageTemplate: Maybe<Scalars['String']>
    /** Number of days the shoutout will be available for the creator to fulfill the order */
    expiresInDays: Scalars['Int']
    ratingConfig: Maybe<MonetizableServiceRatingConfig>
    rating: Maybe<MonetizableServiceRating>
    ratings: Maybe<RatingsConnection>
    testimonialConfig: Maybe<MonetizableServiceTestimonialConfig>
    testimonials: Maybe<TestimonialsConnection>
    shortUrl: Maybe<ShortUrlEmbedded>
    slug: Maybe<Scalars['String']>
    descriptionV2: Maybe<Scalars['String']>
    descriptionHTML: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplateV2: Maybe<Scalars['String']>
  }

export type ShoutoutRatingsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export enum ShoutoutResponseType {
  Video = 'VIDEO',
  Text = 'TEXT',
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  Image = 'IMAGE',
}

export enum ShoutoutStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type ShoutoutConnection = {
  __typename?: 'ShoutoutConnection'
  edges: Maybe<Array<Maybe<ShoutoutEdge>>>
  nodes: Maybe<Array<Maybe<Shoutout>>>
  pageInfo: PageInfo
}

export type ShoutoutEdge = {
  __typename?: 'ShoutoutEdge'
  cursor: Scalars['String']
  node: Shoutout
}

export type Creator = Node &
  Actor & {
    __typename?: 'Creator'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isUpgraded: Scalars['Boolean']
    /** @deprecated Name typo, use isInternalSubscriptionPastDue instead */
    isInternalSupscriptionPastDue: Scalars['Boolean']
    isInternalSubscriptionPastDue: Scalars['Boolean']
    isEligibleForTrial: Scalars['Boolean']
    willInternalSubscriptionCancel: Scalars['Boolean']
    email: Maybe<Scalars['String']>
    username: Scalars['String']
    phone: Maybe<Scalars['String']>
    name: Maybe<Scalars['String']>
    settings: Maybe<CreatorSetting>
    metadata: CreatorMetadata
    referralCode: Maybe<Scalars['String']>
    referredBy: Maybe<Scalars['String']>
    hasStripeAccount: Scalars['Boolean']
    hasPaypalAccount: Scalars['Boolean']
    hasGoogleAccount: Scalars['Boolean']
    hasGoogleAccountError: Scalars['Boolean']
    hasZoomAccount: Scalars['Boolean']
    hasMailchimpAccount: Scalars['Boolean']
    hasShopifyAccount: Scalars['Boolean']
    stripeAccountId: Maybe<Scalars['String']>
    country: Maybe<CountryCode>
    timezone: Maybe<Scalars['String']>
    currencyCode: CurrencyCode
    isDisabled: Scalars['Boolean']
    isVerified: Scalars['Boolean']
    isProfileComplete: Scalars['Boolean']
    isOAuthUserHasPassword: Maybe<Scalars['Boolean']>
    isOAuthUser: Maybe<Scalars['Boolean']>
    stripeCustomerId: Maybe<Scalars['String']>
    stripeAccountStatus: Maybe<StripeAccountStatus>
    disabled: Maybe<ActorDisabled>
    emailLanguage: EmailLanguage
    googleCalendars: GoogleCalendars
    paypalMerchantId: Maybe<Scalars['String']>
    preferences: Preferences
    deletedAt: Maybe<Scalars['DateTime']>
    /** List of the creator links, non-null only for approved accounts */
    creatorlinks: Maybe<CreatorLink>
    /** List of the Creator Shoutout Orders */
    shoutoutOrders: ShoutoutOrderConnection
    consultationOrders: Maybe<ConsultationOrderConnection>
    /** List of the Creator ExclusiveContent Orders */
    exclusiveContentOrders: ExclusiveContentOrderConnection
    /** List of Tips the creator received */
    tipOrders: OneTimeTipOrderConnection
    tipSubscriptions: TipSubscriptionConnection
    createdAt: Scalars['DateTime']
    /** List of the Creator Consultations */
    consultations: ConsultationConnection
    /** List of the Creator Shoutout */
    shoutouts: ShoutoutConnection
    /** List of the Creator's Exclusive Content */
    exclusiveContents: ExclusiveContentConnection
    /** Returns List of signed up emails */
    mailingListEntries: Array<MailingListEntry>
    /** Returns List of Mailchimp Audiences if Creator has connected an account */
    mailchimpAudiences: Array<MailchimpAudience>
    transactions: Maybe<TransactionsConnection>
    categories: Maybe<Array<Maybe<Scalars['String']>>>
    onboardingData: Maybe<OnboardingData>
    isFromPlanoly: Maybe<Scalars['Boolean']>
    planolyAccount: Maybe<PlanolyAccount>
    isEligibleForPlanolyTrial: Maybe<Scalars['Boolean']>
    zapier: CreatorZapier
    firstPromoterId: Maybe<Scalars['Int']>
    replyToEmail: Maybe<Scalars['String']>
    /** @deprecated This field is deprecated, no need to specify it */
    onboardingVersion: Maybe<OnboardingVersion>
    customers: CustomerConnection
    ShortUrls: ShortUrlConnection
    crmCustomers: CrmCustomersConnection
    crmEmailFlows: CrmEmailFlowConnection
    crmEmailBroadcasts: CrmEmailBroadcastConnection
    crmCustomersExport: CrmCustomersExport
    shopifyCollections: Maybe<Array<ShopifyCollectionType>>
    shopifyProducts: Maybe<Array<ShopifyProductType>>
    orders: OrderConnection
    ordersExport: OrdersExport
    activeInternalSubscription: Maybe<InternalSubscription>
    liveStreamOrders: LiveStreamOrderConnection
    /** @deprecated Use liveStreamOrders instead */
    registeredLivesStream: LiveStreamConnection
    eCourseOrders: ECourseOrdersConnection
    eCourses: ECourseConnection
    invoices: InvoiceConnection
    liveStreams: LiveStreamConnection
    memberships: MembershipConnection
    membershipSubscriptions: MembershipSubscriptionConnection
    policies: Maybe<Array<Maybe<Policy>>>
  }

export type CreatorCreatorlinksArgs = {
  showDisabled?: InputMaybe<Scalars['Boolean']>
}

export type CreatorShoutoutOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  statuses: InputMaybe<Array<ShoutoutOrderStatus>>
}

export type CreatorConsultationOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  showPast: InputMaybe<Scalars['Boolean']>
  showUpcoming: InputMaybe<Scalars['Boolean']>
}

export type CreatorExclusiveContentOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type CreatorTipOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type CreatorTipSubscriptionsArgs = {
  after: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Array<SubscriptionStatus>>
}

export type CreatorConsultationsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Array<InputMaybe<ConsultationStatus>>>
  canBeAddedToPageOnly?: InputMaybe<Scalars['Boolean']>
  orderBy?: InputMaybe<CreatorMonetizableContentsOrderBy>
}

export type CreatorShoutoutsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Array<InputMaybe<ShoutoutStatus>>>
  canBeAddedToPageOnly?: InputMaybe<Scalars['Boolean']>
  orderBy?: InputMaybe<CreatorMonetizableContentsOrderBy>
}

export type CreatorExclusiveContentsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Array<InputMaybe<ExclusiveContentStatus>>>
  canBeAddedToPageOnly?: InputMaybe<Scalars['Boolean']>
  orderBy?: InputMaybe<CreatorMonetizableContentsOrderBy>
}

export type CreatorMailingListEntriesArgs = {
  mailingListBlockId: InputMaybe<Scalars['ID']>
}

export type CreatorTransactionsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type CreatorCustomersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<CustomerSortField>
  filterByField: InputMaybe<CustomerFilterByField>
}

export type CreatorShortUrlsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<ShortUrlSortField>
}

export type CreatorCrmCustomersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<CrmCustomerSortField>
  filter: InputMaybe<CrmCustomerFilter>
}

export type CreatorCrmEmailFlowsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type CreatorCrmEmailBroadcastsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type CreatorCrmCustomersExportArgs = {
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<CrmCustomerSortField>
  filter: InputMaybe<CrmCustomerFilter>
}

export type CreatorOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<OrderSortField>
  filterByField: InputMaybe<OrderFilterByField>
}

export type CreatorOrdersExportArgs = {
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<OrderSortField>
  filterByField: InputMaybe<OrderFilterByField>
}

export type CreatorLiveStreamOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type CreatorRegisteredLivesStreamArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type CreatorECourseOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type CreatorECoursesArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Array<ECourseStatus>>
  canBeAddedToPageOnly?: InputMaybe<Scalars['Boolean']>
  orderBy?: InputMaybe<CreatorMonetizableContentsOrderBy>
}

export type CreatorInvoicesArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  status?: InputMaybe<Array<InvoiceStatus>>
  orderBy?: InputMaybe<CreatorMonetizableContentsOrderBy>
}

export type CreatorLiveStreamsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  showPast?: InputMaybe<Scalars['Boolean']>
  showUpcoming?: InputMaybe<Scalars['Boolean']>
  status?: InputMaybe<Array<LiveStreamStatus>>
  orderBy?: InputMaybe<CreatorMonetizableContentsOrderBy>
  canBeAddedToPageOnly?: InputMaybe<Scalars['Boolean']>
}

export type CreatorMembershipsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<MembershipSortField>
  filterByField: InputMaybe<MembershipFilterByField>
}

export type CreatorMembershipSubscriptionsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<MembershipSubscriptionsSortField>
  filterByField: InputMaybe<MembershipSubscriptionsFilterByField>
}

export type CreatorMonetizableContentsOrderBy = {
  direction: InputMaybe<OrderByDirection>
  field: InputMaybe<CreatorMonetizableContentsOrderByField>
}

export enum CreatorMonetizableContentsOrderByField {
  CreatedAt = 'CREATED_AT',
}

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StripeAccountStatus = {
  __typename?: 'StripeAccountStatus'
  /** Reason why the account is disabled https://stripe.com/docs/api/accounts/object#account_object-requirements-disabled_reason */
  disabledReason: Maybe<Scalars['String']>
  cardPaymentsStatus: Maybe<Scalars['String']>
}

export type ReferralMonthlyEarnings = {
  __typename?: 'ReferralMonthlyEarnings'
  period: Scalars['Date']
  total: Money
}

export type MailchimpAudience = {
  __typename?: 'MailchimpAudience'
  id: Scalars['String']
  name: Scalars['String']
}

export type MailingListEntryAdditionalInputField = {
  __typename?: 'MailingListEntryAdditionalInputField'
  id: Scalars['ID']
  label: Scalars['String']
  required: Scalars['Boolean']
  show: Scalars['Boolean']
  value: Scalars['String']
}

export type MailingListEntry = {
  __typename?: 'MailingListEntry'
  name: Maybe<Scalars['String']>
  email: Scalars['String']
  addedAt: Scalars['DateTime']
  additionalInputFields: Maybe<
    Array<Maybe<MailingListEntryAdditionalInputField>>
  >
  phone: Maybe<Scalars['String']>
}

export type ConsultationConnection = {
  __typename?: 'ConsultationConnection'
  edges: Maybe<Array<Maybe<ConsultationEdge>>>
  nodes: Maybe<Array<Maybe<Consultation>>>
  pageInfo: PageInfo
}

export type ConsultationEdge = {
  __typename?: 'ConsultationEdge'
  cursor: Scalars['String']
  node: Consultation
}

export type ExclusiveContentConnection = {
  __typename?: 'ExclusiveContentConnection'
  edges: Maybe<Array<Maybe<ExclusiveContentEdge>>>
  nodes: Maybe<Array<Maybe<ExclusiveContent>>>
  pageInfo: PageInfo
}

export type ExclusiveContentEdge = {
  __typename?: 'ExclusiveContentEdge'
  cursor: Scalars['String']
  node: ExclusiveContent
}

export type CreatorConnection = {
  __typename?: 'CreatorConnection'
  edges: Maybe<Array<Maybe<CreatorEdge>>>
  nodes: Maybe<Array<Maybe<Creator>>>
  pageInfo: PageInfo
}

export type CreatorEdge = {
  __typename?: 'CreatorEdge'
  cursor: Scalars['String']
  node: Creator
}

export type VerifyCreatorEmailInput = {
  creatorId: Scalars['ID']
}

export type VerifyCreatorEmailPayloadSuccess = {
  __typename?: 'VerifyCreatorEmailPayloadSuccess'
  email: Scalars['String']
}

export type UpdateCreatorInput = {
  creatorId: Scalars['ID']
  name: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  country: InputMaybe<CountryCode>
  timezone: InputMaybe<Scalars['String']>
  currencyCode: InputMaybe<CurrencyCode>
  emailLanguage: InputMaybe<EmailLanguage>
}

export type UpdateCreatorApplicationFeeInput = {
  creatorId: Scalars['ID']
  applicationFee: InputMaybe<Scalars['Float']>
  tipsFee: Scalars['Float']
  bundlesFee: Scalars['Float']
  shoutoutsFee: Scalars['Float']
  livestreamsFee: Scalars['Float']
  consultationsFee: Scalars['Float']
  eCoursesFee: Scalars['Float']
  invoicesFee: Scalars['Float']
  membershipsFee: Scalars['Float']
}

export type UpdateCreatorUsernameInput = {
  creatorId: Scalars['ID']
  username: Scalars['String']
}

export type UpdateCreatorUsernamePayloadSuccess = {
  __typename?: 'UpdateCreatorUsernamePayloadSuccess'
  creator: Creator
}

export type UpdateCreatorPayloadSuccess = {
  __typename?: 'UpdateCreatorPayloadSuccess'
  creator: Creator
}

export type UpdateCreatorOnboardingStepInput = {
  creatorId: Scalars['ID']
  step: OnboardingStep
  data: UpdateCreatorOnboardingStepData
}

export type UpdateCreatorOnboardingStepPayloadSuccess = {
  __typename?: 'UpdateCreatorOnboardingStepPayloadSuccess'
  creator: Creator
}

export type VerifyCreatorEmailPayload =
  | VerifyCreatorEmailPayloadSuccess
  | NotFoundError

export type UpdateCreatorPayload =
  | UpdateCreatorPayloadSuccess
  | InputValidationError
  | EmailTakenError
  | UsernameTakenError
  | NotFoundError

export type DisconnectGoogleInput = {
  creatorId: Scalars['ID']
}

export type DisconnectZoomInput = {
  creatorId: Scalars['ID']
}

export type DisconnectMailchimpInput = {
  creatorId: Scalars['ID']
}

export type ConnectMailchimpInput = {
  code: Scalars['String']
}

export type ConnectGoogleInput = {
  code: Scalars['String']
  redirectUrl: InputMaybe<Scalars['String']>
}

export type ConnectZoomInput = {
  code: Scalars['String']
  redirectUrl: InputMaybe<Scalars['String']>
}

export type AddCreatorCategoriesInput = {
  categories: Array<InputMaybe<Scalars['String']>>
}

export type UpdateCreatorReferredByInput = {
  creatorId: Scalars['ID']
  referralCode: Scalars['String']
}

export type DeleteCreatorReferredByInput = {
  creatorId: Scalars['ID']
}

export type UpdateCreatorCategoriesInput = {
  username: Scalars['String']
  categories: Array<InputMaybe<Scalars['String']>>
}

export type UpdateCreatorReferredByPayloadSuccess = {
  __typename?: 'UpdateCreatorReferredByPayloadSuccess'
  creator: Maybe<Creator>
}

export type DeleteCreatorReferredByPayloadSuccess = {
  __typename?: 'DeleteCreatorReferredByPayloadSuccess'
  creator: Maybe<Creator>
}

export type ConnectPaypalPayloadSuccess = {
  __typename?: 'ConnectPaypalPayloadSuccess'
  creator: Maybe<Creator>
}

export type DisconnectGooglePayloadSuccess = {
  __typename?: 'DisconnectGooglePayloadSuccess'
  creator: Maybe<Creator>
}

export type DisconnectZoomPayloadSuccess = {
  __typename?: 'DisconnectZoomPayloadSuccess'
  creator: Maybe<Creator>
}

export type DisconnectMailchimpPayloadSuccess = {
  __typename?: 'DisconnectMailchimpPayloadSuccess'
  creator: Maybe<Creator>
}

export type ConnectMailchimpPayloadSuccess = {
  __typename?: 'ConnectMailchimpPayloadSuccess'
  creator: Maybe<Creator>
}

export type ConnectGooglePayloadSuccess = {
  __typename?: 'ConnectGooglePayloadSuccess'
  creator: Maybe<Creator>
}

export type ConnectZoomPayloadSuccess = {
  __typename?: 'ConnectZoomPayloadSuccess'
  creator: Maybe<Creator>
}

export type UpdateCreatorApplicationFeePayloadSuccess = {
  __typename?: 'UpdateCreatorApplicationFeePayloadSuccess'
  creator: Maybe<Creator>
}

export type GenerateHubspotChatTokenPayloadSuccess = {
  __typename?: 'GenerateHubspotChatTokenPayloadSuccess'
  token: Maybe<Scalars['String']>
}

export type AddCreatorCategoriesPayloadSuccess = {
  __typename?: 'AddCreatorCategoriesPayloadSuccess'
  creator: Maybe<Creator>
}

export type UpdateCreatorCategoriesPayloadSuccess = {
  __typename?: 'UpdateCreatorCategoriesPayloadSuccess'
  creator: Maybe<Creator>
}

export type UpdateCreatorReferredByPayload =
  | UpdateCreatorReferredByPayloadSuccess
  | NotFoundError
  | InputValidationError

export type DeleteCreatorReferredByPayload =
  | DeleteCreatorReferredByPayloadSuccess
  | NotFoundError

export type PaypalError = {
  __typename?: 'PaypalError'
  message: Scalars['String']
  code: Scalars['String']
}

export enum PaypalAccountReadinessErrorReason {
  UnconfirmedPrimaryEmail = 'UNCONFIRMED_PRIMARY_EMAIL',
  PaymentsUnreceivable = 'PAYMENTS_UNRECEIVABLE',
  MissingOauthScopes = 'MISSING_OAUTH_SCOPES',
  Other = 'OTHER',
}

export type PaypalAccountReadinessError = {
  __typename?: 'PaypalAccountReadinessError'
  reason: PaypalAccountReadinessErrorReason
}

export type ConnectPaypalPayload =
  | ConnectPaypalPayloadSuccess
  | PaypalError
  | PaypalAccountReadinessError

export type DisconnectGooglePayload =
  | DisconnectGooglePayloadSuccess
  | NotFoundError

export type DisconnectZoomPayload = DisconnectZoomPayloadSuccess | NotFoundError

export type DisconnectMailchimpPayload =
  | DisconnectMailchimpPayloadSuccess
  | NotFoundError

export type ConnectMailchimpPayload =
  | ConnectMailchimpPayloadSuccess
  | ExternalServiceRequestError

export type ConnectGooglePayload =
  | ConnectGooglePayloadSuccess
  | ExternalServiceRequestError

export type ConnectZoomPayload =
  | ConnectZoomPayloadSuccess
  | ExternalServiceRequestError

export type UpdateCreatorApplicationFeePayload =
  | UpdateCreatorApplicationFeePayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateCreatorUsernamePayload =
  | UpdateCreatorUsernamePayloadSuccess
  | InputValidationError
  | NotFoundError
  | UsernameTakenError

export type GenerateHubspotChatTokenPayload =
  | GenerateHubspotChatTokenPayloadSuccess
  | ExternalServiceRequestError

export type AddCreatorCategoriesPayload =
  | AddCreatorCategoriesPayloadSuccess
  | InputValidationError

export type UpdateCreatorCategoriesPayload =
  | UpdateCreatorCategoriesPayloadSuccess
  | InputValidationError

export type UpdateCreatorOnboardingStepPayload =
  | UpdateCreatorOnboardingStepPayloadSuccess
  | NotFoundError
  | InputValidationError

export type CreatorLinkPremiumTemplate = {
  __typename?: 'CreatorLinkPremiumTemplate'
  name: Scalars['String']
  theme: Scalars['String']
}

export type CreatorLink = Node & {
  __typename?: 'CreatorLink'
  id: Scalars['ID']
  username: Scalars['String']
  isDisabled: Scalars['Boolean']
  isUpgraded: Scalars['Boolean']
  isVip: Scalars['Boolean']
  displayBranding: Scalars['Boolean']
  profile: LinkProfile
  blocks: Array<LinkBlockItem>
  tipBlock: Maybe<TipBlock>
  backgroundStyles: LinkBackgroundStyle
  colors: LinkColors
  fonts: LinkFonts
  blockStyles: LinkBlockStyles
  owner: Creator
  creatorId: Scalars['ID']
  selectedTemplate: Maybe<Scalars['ID']>
  carousels: Array<CarouselBlock>
  customDomainNames: Array<CustomDomainName>
  mainCustomDomainName: Maybe<CustomDomainName>
  purchasedDomainName: Maybe<Scalars['String']>
  premiumTemplate: Maybe<CreatorLinkPremiumTemplate>
}

export type CreatorLinkCarouselsArgs = {
  service: CarouselService
}

export type CreatorLinkCustomDomainNamesArgs = {
  checkConfiguration?: Scalars['Boolean']
}

export enum CustomDomainNameManager {
  Creator = 'CREATOR',
  Snipfeed = 'SNIPFEED',
}

export type CustomDomainName = {
  __typename?: 'CustomDomainName'
  domainName: Scalars['String']
  isConfigured: Scalars['Boolean']
  lastConfigurationCheck: Maybe<Scalars['DateTime']>
  managedBy: CustomDomainNameManager
}

export enum LinkTemplateTier {
  Free = 'FREE',
  Pro = 'PRO',
}

export type LinkTemplateCategory = {
  __typename?: 'LinkTemplateCategory'
  id: Scalars['String']
  name: Scalars['String']
}

export type LinkTemplate = {
  __typename?: 'LinkTemplate'
  id: Scalars['ID']
  tier: LinkTemplateTier
  name: Scalars['String']
  previewThumbnail: Scalars['String']
  backgroundStyles: LinkBackgroundStyle
  colors: LinkColors
  fonts: LinkFonts
  categories: Maybe<Array<LinkTemplateCategory>>
  blockStyles: LinkBlockStyles
}

export type LinkBlock = {
  id: Scalars['ID']
  databaseId: Scalars['String']
  isVisible: Scalars['Boolean']
  errors: Maybe<Array<LinkBlockValidationError>>
  isValid: Scalars['Boolean']
}

export type LinkProfile = {
  __typename?: 'LinkProfile'
  name: Maybe<Scalars['String']>
  avatarAsset: Maybe<Asset>
  description: Maybe<Scalars['String']>
}

export type LinkFonts = {
  __typename?: 'LinkFonts'
  /** @deprecated Use heading field instead */
  text: FontFamilyV2
  heading: FontFamilyV2
  body: FontFamilyV2
}

export type LinkStyle = {
  __typename?: 'LinkStyle'
  background: LinkBackgroundStyle
  fonts: LinkFonts
  blocks: LinkBlockStyles
  colors: LinkColors
}

export type LinkPhotoBackground = {
  __typename?: 'LinkPhotoBackground'
  transparency: Maybe<Scalars['Float']>
  imageAsset: Maybe<Asset>
  imageUrl: Maybe<Scalars['URL']>
}

export type LinkCoverBackground = {
  __typename?: 'LinkCoverBackground'
  imageAsset: Maybe<Asset>
  color: Maybe<Scalars['String']>
  imageUrl: Maybe<Scalars['URL']>
  transparency: Maybe<Scalars['Float']>
}

export type LinkGradientBackground = {
  __typename?: 'LinkGradientBackground'
  color: Maybe<Scalars['String']>
}

export type LinkSolidBackground = {
  __typename?: 'LinkSolidBackground'
  color: Maybe<Scalars['String']>
}

export type LinkBackgroundStyle =
  | LinkPhotoBackground
  | LinkCoverBackground
  | LinkSolidBackground
  | LinkGradientBackground

export enum LinkBackgroundStyleType {
  Solid = 'SOLID',
  Gradient = 'GRADIENT',
  CoverPhoto = 'COVER_PHOTO',
  BackgroundPhoto = 'BACKGROUND_PHOTO',
}

export type LinkColors = {
  __typename?: 'LinkColors'
  text: Scalars['CSSColor']
  buttonText: Scalars['CSSColor']
  innerButtonText: Scalars['CSSColor']
  tipButtonText: Scalars['CSSColor']
  buttonBackground: Scalars['CSSColor']
  innerButtonBackground: Scalars['CSSColor']
  tipButtonBackground: Scalars['CSSColor']
}

export type LinkBlockStyles = {
  __typename?: 'LinkBlockStyles'
  buttonType: LinkButtonType
  buttonShape: LinkButtonShape
  transparency: Scalars['Float']
}

export enum LinkButtonShape {
  Square = 'SQUARE',
  RoundedSlight = 'ROUNDED_SLIGHT',
  Rounded = 'ROUNDED',
}

export enum LinkButtonType {
  Fill = 'FILL',
  Outline = 'OUTLINE',
  Shadow = 'SHADOW',
}

export enum TipBlockPriceOptionStyle {
  TextOnly = 'TEXT_ONLY',
  TextWithEmoji = 'TEXT_WITH_EMOJI',
}

export enum TipBlockIcons {
  Star = 'STAR',
  Heart = 'HEART',
  Gift = 'GIFT',
  Money = 'MONEY',
}

export enum TipBlockCardStyle {
  TextOnly = 'TEXT_ONLY',
  TextWithIcon = 'TEXT_WITH_ICON',
}

export enum LinkBlockAnimationTypes {
  /** None */
  None = 'NONE',
  /** Bounce */
  Bounce = 'BOUNCE',
  /** Fade */
  Blink = 'BLINK',
  /** Flash */
  Flash = 'FLASH',
  /** Breathe */
  Pulse = 'PULSE',
  /** Jump */
  ShakeY = 'SHAKE_Y',
  /** Slide */
  ShakeX = 'SHAKE_X',
  /** Ta-da */
  Tada = 'TADA',
  /** Heartbeat */
  HeartBeat = 'HEART_BEAT',
}

export type TextBlock = Node &
  LinkBlock & {
    __typename?: 'TextBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    content: Maybe<Scalars['String']>
    contentV2: Maybe<Scalars['String']>
    isAnimated: Scalars['Boolean']
    isVisible: Scalars['Boolean']
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
  }

export type TipBlockPriceOption = {
  __typename?: 'TipBlockPriceOption'
  amount: Scalars['Int']
  currencyCode: CurrencyCode
  emoji: Maybe<Scalars['String']>
}

export type TipBlockPriceOptions = {
  __typename?: 'TipBlockPriceOptions'
  style: TipBlockPriceOptionStyle
  options: Array<TipBlockPriceOption>
}

export type TipFeedEdge = {
  __typename?: 'TipFeedEdge'
  cursor: Scalars['String']
  node: TipFeedItem
}

export type TipFeedConnection = {
  __typename?: 'TipFeedConnection'
  edges: Array<Maybe<TipFeedEdge>>
  nodes: Array<Maybe<TipFeedItem>>
  pageInfo: PageInfo
}

export enum ReccurenceInterval {
  Week = 'WEEK',
  Month = 'MONTH',
}

export type SubscriptionPlanIdentifier = {
  __typename?: 'SubscriptionPlanIdentifier'
  platform: PayoutPlatform
  id: Scalars['String']
}

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan'
  price: Money
  interval: ReccurenceInterval
  plan: Array<SubscriptionPlanIdentifier>
}

export type ISubscription = {
  id: Scalars['ID']
  status: Maybe<SubscriptionStatus>
  owner: User
  creator: Creator
  createdAt: Scalars['Date']
  platform: TransactionPlatform
  plan: SubscriptionPlan
}

export enum SubscriptionStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
}

export type OneTimeTip = Transaction &
  Node & {
    __typename?: 'OneTimeTip'
    id: Scalars['ID']
    user: User
    status: TransactionStatus
    owner: User
    price: Money
    message: Maybe<Scalars['String']>
    isPrivateMessage: Scalars['Boolean']
    creator: Creator
    createdAt: Scalars['DateTime']
    paidAt: Maybe<Scalars['DateTime']>
    platform: TransactionPlatform
    tipBlockId: Scalars['ID']
    isPayedOut: Scalars['Boolean']
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    refund: Maybe<TransactionRefund>
    description: Maybe<Scalars['String']>
  }

export type TipFeedItemOwner = {
  __typename?: 'TipFeedItemOwner'
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
}

export type TipFeedItem = Node & {
  __typename?: 'TipFeedItem'
  id: Scalars['ID']
  owner: TipFeedItemOwner
  price: Money
  paidAt: Maybe<Scalars['DateTime']>
  message: Maybe<Scalars['String']>
}

export type TipSubscription = ISubscription &
  Node & {
    __typename?: 'TipSubscription'
    id: Scalars['ID']
    status: Maybe<SubscriptionStatus>
    owner: User
    creator: Creator
    createdAt: Scalars['Date']
    platform: TransactionPlatform
    plan: SubscriptionPlan
  }

export type OneTimeTipOrderConnection = {
  __typename?: 'OneTimeTipOrderConnection'
  nodes: Maybe<Array<Maybe<OneTimeTip>>>
  edges: Maybe<Array<Maybe<OneTimeTipEdge>>>
  pageInfo: PageInfo
}

export type OneTimeTipEdge = {
  __typename?: 'OneTimeTipEdge'
  node: OneTimeTip
  cursor: Scalars['String']
}

export type TipSubscriptionEdge = {
  __typename?: 'TipSubscriptionEdge'
  node: TipSubscription
  cursor: Scalars['String']
}

export type TipSubscriptionConnection = {
  __typename?: 'TipSubscriptionConnection'
  nodes: Maybe<Array<Maybe<TipSubscription>>>
  edges: Maybe<Array<Maybe<TipSubscriptionEdge>>>
  pageInfo: PageInfo
}

export type Goal = Node & {
  __typename?: 'Goal'
  id: Scalars['ID']
  target: Money
  accumulatedAmount: Money
  title: Scalars['String']
  description: Maybe<Scalars['String']>
  coverAsset: Maybe<Asset>
  transactionsCount: Scalars['Int']
  createdAt: Scalars['Date']
}

export type TipBlock = Node &
  LinkBlock & {
    __typename?: 'TipBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    theme: Scalars['String']
    isVisible: Scalars['Boolean']
    buttonText: Scalars['String']
    animationType: LinkBlockAnimationTypes
    /** @deprecated Use animationType field instead */
    isAnimated: Scalars['Boolean']
    goal: Maybe<Goal>
    icon: Maybe<TipBlockIcons>
    cardStyle: TipBlockCardStyle
    showTipingFeed: Scalars['Boolean']
    tippingFeed: TipFeedConnection
    priceOptions: TipBlockPriceOptions
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
    receiptEmailCustomMessageTemplate: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplateV2: Maybe<Scalars['String']>
  }

export type TipBlockTippingFeedArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type LinkBlockValidationError = {
  __typename?: 'LinkBlockValidationError'
  field: Scalars['String']
  message: Scalars['String']
  code: Scalars['String']
}

export type CustomBlock = Node &
  LinkBlock & {
    __typename?: 'CustomBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isVisible: Scalars['Boolean']
    url: Maybe<Scalars['URL']>
    title: Maybe<Scalars['String']>
    coverAsset: Maybe<Asset>
    description: Maybe<Scalars['String']>
    animationType: LinkBlockAnimationTypes
    /** @deprecated Use animationType field instead */
    isAnimated: Scalars['Boolean']
    cardStyle: CustomBlockCardStyle
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
    ctaTitle: Maybe<Scalars['String']>
  }

export type ProductBlock = Node &
  LinkBlock & {
    __typename?: 'ProductBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isVisible: Scalars['Boolean']
    url: Maybe<Scalars['URL']>
    title: Maybe<Scalars['String']>
    price: Maybe<Money>
    coverAssets: Maybe<Array<Asset>>
    description: Maybe<Scalars['String']>
    isAnimated: Scalars['Boolean']
    cardStyle: LinkBlockCardStyle
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
  }

export type SocialIconsItem = {
  __typename?: 'SocialIconsItem'
  id: Scalars['ID']
  databaseId: Scalars['String']
  url: Scalars['URL']
  platform: SocialPlatform
}

export type SocialIconsBlock = Node &
  LinkBlock & {
    __typename?: 'SocialIconsBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isVisible: Scalars['Boolean']
    links: Array<SocialIconsItem>
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
  }

export type MusicPreviewBlock = Node &
  LinkBlock & {
    __typename?: 'MusicPreviewBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isVisible: Scalars['Boolean']
    url: Maybe<Scalars['URL']>
    platform: Maybe<MusicPlatform>
    playerSize: MusicPlayerSize
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
  }

export type TwitterEmbedBlock = Node &
  LinkBlock & {
    __typename?: 'TwitterEmbedBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isVisible: Scalars['Boolean']
    errors: Maybe<Array<LinkBlockValidationError>>
    tweetUrls: Array<Scalars['String']>
    isValid: Scalars['Boolean']
  }

export type VideoPreviewBlock = Node &
  LinkBlock & {
    __typename?: 'VideoPreviewBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isVisible: Scalars['Boolean']
    url: Maybe<Scalars['URL']>
    platform: Maybe<VideoPlatform>
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
  }

export type MailingListBlockDownloadable = {
  __typename?: 'MailingListBlockDownloadable'
  title: Scalars['String']
  asset: Asset
}

export type MailingListBlockAdditionalInputField = {
  __typename?: 'MailingListBlockAdditionalInputField'
  id: Scalars['ID']
  label: Scalars['String']
  required: Scalars['Boolean']
  show: Scalars['Boolean']
}

export type MailingListBlock = Node &
  LinkBlock & {
    __typename?: 'MailingListBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isVisible: Scalars['Boolean']
    title: Maybe<Scalars['String']>
    description: Maybe<Scalars['String']>
    requireName: Scalars['Boolean']
    requirePhone: Scalars['Boolean']
    showNameInput: Scalars['Boolean']
    showPhoneInput: Scalars['Boolean']
    emailFieldLabel: Maybe<Scalars['String']>
    nameFieldLabel: Maybe<Scalars['String']>
    phoneFieldLabel: Maybe<Scalars['String']>
    buttonText: Scalars['String']
    style: MailingListBlockStyle
    storage: MailingListBlockBackend
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
    downloadables: Maybe<Array<MailingListBlockDownloadable>>
    thankYouMessageTitle: Maybe<Scalars['String']>
    thankYouMessageDescription: Maybe<Scalars['String']>
    thumbnailAsset: Maybe<Asset>
    showThumbnail: Scalars['Boolean']
    additionalInputFields: Maybe<Array<MailingListBlockAdditionalInputField>>
  }

export type MailingListBlockBackend = {
  __typename?: 'MailingListBlockBackend'
  type: MailingListBlockBackendName
  audienceId: Maybe<Scalars['String']>
}

export enum MailingListBlockBackendName {
  Snipfeed = 'SNIPFEED',
  Mailchimp = 'MAILCHIMP',
}

export enum MailingListBlockStyle {
  Card = 'CARD',
  Compact = 'COMPACT',
}

export enum CustomBlockCardStyle {
  TextOnly = 'TEXT_ONLY',
  ThumbnailSquare = 'THUMBNAIL_SQUARE',
  ThumbnailLeft = 'THUMBNAIL_LEFT',
  Thumbnail = 'THUMBNAIL',
  Featured = 'FEATURED',
}

export enum LinkBlockCardStyle {
  Thumbnail = 'THUMBNAIL',
  ThumbnailLeft = 'THUMBNAIL_LEFT',
  TextOnly = 'TEXT_ONLY',
}

export enum PremiumBlockCardStyle {
  Thumbnail = 'THUMBNAIL',
  Featured = 'FEATURED',
  ThumbnailLeft = 'THUMBNAIL_LEFT',
  ThumbnailSquare = 'THUMBNAIL_SQUARE',
}

export type PremiumLinkCardRating = {
  __typename?: 'PremiumLinkCardRating'
  count: Scalars['Int']
  average: Scalars['Float']
}

export type PremiumLinkCard = {
  id: Scalars['ID']
  databaseId: Scalars['String']
  title: Scalars['String']
  price: Maybe<Money>
  description: Maybe<Scalars['String']>
  descriptionV2: Maybe<Scalars['String']>
  rating: Maybe<PremiumLinkCardRating>
}

export type BundleLinkCard = PremiumLinkCard & {
  __typename?: 'BundleLinkCard'
  id: Scalars['ID']
  databaseId: Scalars['String']
  title: Scalars['String']
  price: Maybe<Money>
  coverAsset: Maybe<Asset>
  description: Maybe<Scalars['String']>
  descriptionV2: Maybe<Scalars['String']>
  saleDiscount: Maybe<Discount>
  rating: Maybe<PremiumLinkCardRating>
}

export type ConsultationLinkCard = PremiumLinkCard & {
  __typename?: 'ConsultationLinkCard'
  id: Scalars['ID']
  databaseId: Scalars['String']
  title: Scalars['String']
  price: Maybe<Money>
  coverAsset: Maybe<Asset>
  duration: Scalars['Int']
  description: Maybe<Scalars['String']>
  descriptionV2: Maybe<Scalars['String']>
  platform: ConsultationPlatform
  saleDiscount: Maybe<Discount>
  rating: Maybe<PremiumLinkCardRating>
}

export type MembershipLinkCard = PremiumLinkCard & {
  __typename?: 'MembershipLinkCard'
  id: Scalars['ID']
  databaseId: Scalars['String']
  title: Scalars['String']
  coverAsset: Maybe<Asset>
  description: Maybe<Scalars['String']>
  descriptionV2: Maybe<Scalars['String']>
  price: Maybe<Money>
  rating: Maybe<PremiumLinkCardRating>
}

export type ShoutoutLinkCard = PremiumLinkCard & {
  __typename?: 'ShoutoutLinkCard'
  id: Scalars['ID']
  databaseId: Scalars['String']
  title: Scalars['String']
  price: Maybe<Money>
  saleDiscount: Maybe<Discount>
  coverAsset: Maybe<Asset>
  description: Maybe<Scalars['String']>
  descriptionV2: Maybe<Scalars['String']>
  rating: Maybe<PremiumLinkCardRating>
}

export enum LiveStreamPlatform {
  Zoom = 'ZOOM',
  Irl = 'IRL',
}

export type LiveStreamLinkCard = PremiumLinkCard & {
  __typename?: 'LiveStreamLinkCard'
  id: Scalars['ID']
  databaseId: Scalars['String']
  title: Scalars['String']
  price: Maybe<Money>
  saleDiscount: Maybe<Discount>
  coverAsset: Maybe<Asset>
  startAt: Maybe<Scalars['DateTime']>
  platform: Maybe<LiveStreamPlatform>
  description: Maybe<Scalars['String']>
  descriptionV2: Maybe<Scalars['String']>
  rating: Maybe<PremiumLinkCardRating>
}

export type ExclusiveContentLinkCard = PremiumLinkCard & {
  __typename?: 'ExclusiveContentLinkCard'
  id: Scalars['ID']
  databaseId: Scalars['String']
  title: Scalars['String']
  description: Maybe<Scalars['String']>
  descriptionV2: Maybe<Scalars['String']>
  price: Maybe<Money>
  saleDiscount: Maybe<Discount>
  coverAsset: Maybe<Asset>
  rating: Maybe<PremiumLinkCardRating>
}

export type ECourseLinkCard = PremiumLinkCard & {
  __typename?: 'ECourseLinkCard'
  id: Scalars['ID']
  databaseId: Scalars['String']
  title: Scalars['String']
  description: Maybe<Scalars['String']>
  descriptionV2: Maybe<Scalars['String']>
  price: Maybe<Money>
  saleDiscount: Maybe<Discount>
  coverAsset: Maybe<Asset>
  rating: Maybe<PremiumLinkCardRating>
}

export type PremiumBlock = Node &
  LinkBlock & {
    __typename?: 'PremiumBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    coverAsset: Maybe<Asset>
    title: Maybe<Scalars['String']>
    isVisible: Scalars['Boolean']
    /** @deprecated No longer supported */
    isAnimated: Scalars['Boolean']
    /** @deprecated No longer supported */
    description: Maybe<Scalars['String']>
    cardStyle: PremiumBlockCardStyle
    service: Maybe<PremiumService>
    card: Maybe<
      | BundleLinkCard
      | ConsultationLinkCard
      | MembershipLinkCard
      | ShoutoutLinkCard
      | LiveStreamLinkCard
      | ExclusiveContentLinkCard
      | ECourseLinkCard
    >
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
    ctaTitle: Maybe<Scalars['String']>
  }

export type CarouselBlock = Node &
  LinkBlock & {
    __typename?: 'CarouselBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    title: Maybe<Scalars['String']>
    service: CarouselService
    cards: Maybe<Array<CarouselBlockItem>>
    isVisible: Scalars['Boolean']
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
  }

export enum ThumbnailsBlockCardStyleEnum {
  CircleGrid = 'CIRCLE_GRID',
  CircleCarousel = 'CIRCLE_CAROUSEL',
  SquareGrid = 'SQUARE_GRID',
  SquareCarousel = 'SQUARE_CAROUSEL',
}

export type ThumbnailsBlock = LinkBlock &
  Node & {
    __typename?: 'ThumbnailsBlock'
    cardStyle: ThumbnailsBlockCardStyleEnum
    databaseId: Scalars['String']
    errors: Maybe<Array<LinkBlockValidationError>>
    id: Scalars['ID']
    isValid: Scalars['Boolean']
    isVisible: Scalars['Boolean']
    title: Scalars['String']
    items: Array<ThumbnailsBlockItem>
  }

export type SocialFeedBlock = LinkBlock &
  Node & {
    __typename?: 'SocialFeedBlock'
    cardStyle: SocialFeedBlockCardStyleEnum
    databaseId: Scalars['String']
    errors: Maybe<Array<LinkBlockValidationError>>
    id: Scalars['ID']
    isValid: Scalars['Boolean']
    isVisible: Scalars['Boolean']
    items: Array<SocialFeedBlockItem>
    title: Scalars['String']
    platform: SocialFeedBlockPlatformEnum
  }

export enum SocialFeedBlockPlatformEnum {
  Tiktok = 'TIKTOK',
  Instagram = 'INSTAGRAM',
}

export enum SocialFeedBlockCardStyleEnum {
  CircleGrid = 'CIRCLE_GRID',
  RectangleGrid = 'RECTANGLE_GRID',
  SquareGrid = 'SQUARE_GRID',
}

export type SocialFeedBlockItem = {
  __typename?: 'SocialFeedBlockItem'
  databaseId: Scalars['String']
  id: Scalars['ID']
  title: Scalars['String']
  url: Maybe<Scalars['String']>
  coverAsset: Asset
  originalCoverUrl: Maybe<Scalars['String']>
  postId: Maybe<Scalars['String']>
}

export enum ShopifyBlockCardStyleEnum {
  FeaturedLeft = 'FEATURED_LEFT',
  FeaturedSquare = 'FEATURED_SQUARE',
  FeaturedTop = 'FEATURED_TOP',
}

export type ShopifyProductPriceRange = {
  __typename?: 'ShopifyProductPriceRange'
  minVariantPrice: Maybe<Money>
  maxVariantPrice: Maybe<Money>
}

export type ShopifyProductBlockItem = {
  __typename?: 'ShopifyProductBlockItem'
  shopifyProductId: Scalars['String']
  title: Scalars['String']
  url: Scalars['URL']
  description: Maybe<Scalars['String']>
  productImageUrls: Array<Scalars['String']>
  priceRange: ShopifyProductPriceRange
}

export type ShopifyProductBlock = Node &
  LinkBlock & {
    __typename?: 'ShopifyProductBlock'
    id: Scalars['ID']
    databaseId: Scalars['String']
    isVisible: Scalars['Boolean']
    title: Maybe<Scalars['String']>
    displayProductDescription: Scalars['Boolean']
    isAnimated: Scalars['Boolean']
    cardStyle: ShopifyBlockCardStyleEnum
    errors: Maybe<Array<LinkBlockValidationError>>
    isValid: Scalars['Boolean']
    products: Array<ShopifyProductBlockItem>
    collectionId: Maybe<Scalars['String']>
    items: Array<ShopifyProductBlockItem>
    shopifyCollectionId: Maybe<Scalars['String']>
  }

export type CarouselBlockItem =
  | BundleLinkCard
  | ShoutoutLinkCard
  | LiveStreamLinkCard
  | ProductBlock
  | ConsultationLinkCard
  | ExclusiveContentLinkCard
  | ECourseLinkCard
  | MembershipLinkCard

export type LinkBlockItem =
  | CustomBlock
  | TipBlock
  | SocialIconsBlock
  | PremiumBlock
  | VideoPreviewBlock
  | MusicPreviewBlock
  | TwitterEmbedBlock
  | MailingListBlock
  | ProductBlock
  | TextBlock
  | CarouselBlock
  | ThumbnailsBlock
  | SocialFeedBlock
  | ShopifyProductBlock

export enum VideoPlatform {
  Youtube = 'YOUTUBE',
  Twitch = 'TWITCH',
  Vimeo = 'VIMEO',
  Tiktok = 'TIKTOK',
}

export enum MusicPlayerSize {
  Large = 'LARGE',
  Small = 'SMALL',
}

export enum BlockStyle {
  Card = 'CARD',
  Compact = 'COMPACT',
}

export enum PremiumService {
  Bundle = 'BUNDLE',
  Consultation = 'CONSULTATION',
  Shoutout = 'SHOUTOUT',
  /** @deprecated Use SHOUTOUT instead */
  Booking = 'BOOKING',
  Livestream = 'LIVESTREAM',
  ExclusiveContent = 'EXCLUSIVE_CONTENT',
  Ecourse = 'ECOURSE',
  Membership = 'MEMBERSHIP',
}

export enum CarouselService {
  Bundle = 'BUNDLE',
  Consultation = 'CONSULTATION',
  Shoutout = 'SHOUTOUT',
  Livestream = 'LIVESTREAM',
  Product = 'PRODUCT',
  ExclusiveContent = 'EXCLUSIVE_CONTENT',
  Ecourse = 'ECOURSE',
  Membership = 'MEMBERSHIP',
}

export enum MusicPlatform {
  Spotify = 'SPOTIFY',
  Soundcloud = 'SOUNDCLOUD',
  AppleMusic = 'APPLE_MUSIC',
  Bandcamp = 'BANDCAMP',
  Deezer = 'DEEZER',
}

export enum SocialPlatform {
  Shop = 'SHOP',
  Etsy = 'ETSY',
  Email = 'EMAIL',
  Tidal = 'TIDAL',
  Deezer = 'DEEZER',
  Tiktok = 'TIKTOK',
  Twitch = 'TWITCH',
  Napster = 'NAPSTER',
  Website = 'WEBSITE',
  Spotify = 'SPOTIFY',
  Youtube = 'YOUTUBE',
  Twitter = 'TWITTER',
  Groover = 'GROOVER',
  Patreon = 'PATREON',
  Bandcamp = 'BANDCAMP',
  Facebook = 'FACEBOOK',
  Linkedin = 'LINKEDIN',
  Snapchat = 'SNAPCHAT',
  Pinterest = 'PINTEREST',
  Instagram = 'INSTAGRAM',
  Soundcloud = 'SOUNDCLOUD',
  AppleMusic = 'APPLE_MUSIC',
  AmazonMusic = 'AMAZON_MUSIC',
  ApplePodcast = 'APPLE_PODCAST',
  Tumblr = 'TUMBLR',
  Threads = 'THREADS',
}

export type UpsertSocialLinkInput = {
  creatorLinkId: Scalars['ID']
  url: InputMaybe<Scalars['URL']>
  platform: SocialPlatform
  isVisible: InputMaybe<Scalars['Boolean']>
}

export type UpdateVipStatusInput = {
  creatorLinkId: Scalars['ID']
  isVip: Scalars['Boolean']
}

export type AddEmailToMailingListAdditionalInputFieldInput = {
  id: Scalars['ID']
  value: Scalars['String']
}

export type AddEmailToMailingListInput = {
  creatorLinkId: Scalars['ID']
  mailingListBlockId: Scalars['ID']
  email: Scalars['String']
  name: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  additionalInputFields: InputMaybe<
    Array<AddEmailToMailingListAdditionalInputFieldInput>
  >
}

export type UpdateLinkProfileInput = {
  creatorLinkId: Scalars['ID']
  name: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  avatarUrl: InputMaybe<Scalars['URL']>
  avatarAsset: InputMaybe<Scalars['ID']>
}

export type AddTextBlockInput = {
  creatorLinkId: Scalars['ID']
  content: InputMaybe<Scalars['String']>
}

export type UpdateTextBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  content: InputMaybe<Scalars['String']>
}

export type AddCustomBlockInput = {
  creatorLinkId: Scalars['ID']
  url: InputMaybe<Scalars['URL']>
  coverAsset: InputMaybe<Scalars['ID']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  isVisible: InputMaybe<Scalars['Boolean']>
  animationType: InputMaybe<LinkBlockAnimationTypes>
  isAnimated: InputMaybe<Scalars['Boolean']>
  cardStyle: InputMaybe<CustomBlockCardStyle>
}

export type UpdateCustomBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  url: InputMaybe<Scalars['URL']>
  coverAsset: InputMaybe<Scalars['ID']>
  description: InputMaybe<Scalars['String']>
  title: InputMaybe<Scalars['String']>
  animationType: InputMaybe<LinkBlockAnimationTypes>
  isAnimated: InputMaybe<Scalars['Boolean']>
  cardStyle: InputMaybe<CustomBlockCardStyle>
  ctaTitle: InputMaybe<Scalars['String']>
}

export type AddProductBlockInput = {
  creatorLinkId: Scalars['ID']
  carouselBlockId: InputMaybe<Scalars['ID']>
  url: InputMaybe<Scalars['URL']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  price: InputMaybe<MoneyInput>
  isVisible: InputMaybe<Scalars['Boolean']>
  isAnimated: InputMaybe<Scalars['Boolean']>
  cardStyle: InputMaybe<LinkBlockCardStyle>
}

export type UpdateProductBlockInput = {
  creatorLinkId: Scalars['ID']
  carouselBlockId: InputMaybe<Scalars['ID']>
  blockId: Scalars['ID']
  url: InputMaybe<Scalars['URL']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  price: InputMaybe<MoneyInput>
  isVisible: InputMaybe<Scalars['Boolean']>
  isAnimated: InputMaybe<Scalars['Boolean']>
  cardStyle: InputMaybe<LinkBlockCardStyle>
  coverAssets: InputMaybe<Array<Scalars['ID']>>
}

export type AddCoverAssetToProductBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  carouselBlockId: InputMaybe<Scalars['ID']>
  coverAsset: Scalars['ID']
}

export type DeleteCoverAssetFromProductBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  carouselBlockId: InputMaybe<Scalars['ID']>
  coverAsset: Scalars['ID']
}

export type UpdateLinkBlockVisibilityInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  isVisible: Scalars['Boolean']
}

export type UpdatePremiumBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  cardStyle: InputMaybe<PremiumBlockCardStyle>
  ctaTitle: InputMaybe<Scalars['String']>
}

export type ProductCarouselCardInput = {
  id: InputMaybe<Scalars['ID']>
  url: InputMaybe<Scalars['URL']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  price: InputMaybe<Scalars['Float']>
  currencyCode: InputMaybe<CurrencyCode>
  coverAssets: InputMaybe<Array<Scalars['ID']>>
}

export type UpdateCarouselBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  sortedCardIds: InputMaybe<Array<Scalars['ID']>>
  productCards: InputMaybe<Array<ProductCarouselCardInput>>
}

export type AddItemToCarouselBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  resourceId: Scalars['ID']
  service: CarouselService
}

export type UpsertSocialIconInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  url: Scalars['URL']
  platform: SocialPlatform
}

export type DeleteSocialIconInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  platform: SocialPlatform
}

export type SortBlocksInput = {
  creatorLinkId: Scalars['ID']
  sortedBlockIds: InputMaybe<Array<Scalars['ID']>>
}

export type SortBlockCardsInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  sortedCardIds: InputMaybe<Array<Scalars['ID']>>
}

export type MailingListBlockBackendInput = {
  type: EmailListStorageType
  audienceId: InputMaybe<Scalars['String']>
}

export enum EmailListStorageType {
  Snipfeed = 'SNIPFEED',
  Mailchimp = 'MAILCHIMP',
}

export type MailingListBlockDownloadableInput = {
  title: Scalars['String']
  asset: Scalars['ID']
}

export type MailingListBlockAdditionalInputFieldInput = {
  label: Scalars['String']
  required: Scalars['Boolean']
  show: Scalars['Boolean']
}

export type UpdateMailingListBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  requireName: InputMaybe<Scalars['Boolean']>
  requirePhone: InputMaybe<Scalars['Boolean']>
  showNameInput: InputMaybe<Scalars['Boolean']>
  showPhoneInput: InputMaybe<Scalars['Boolean']>
  emailFieldLabel: InputMaybe<Scalars['String']>
  nameFieldLabel: InputMaybe<Scalars['String']>
  phoneFieldLabel: InputMaybe<Scalars['String']>
  buttonText: InputMaybe<Scalars['String']>
  style: InputMaybe<MailingListBlockStyle>
  storage: InputMaybe<MailingListBlockBackendInput>
  downloadables: InputMaybe<Array<MailingListBlockDownloadableInput>>
  thankYouMessageTitle: InputMaybe<Scalars['String']>
  thankYouMessageDescription: InputMaybe<Scalars['String']>
  thumbnailAsset: InputMaybe<Scalars['ID']>
  showThumbnail: InputMaybe<Scalars['Boolean']>
  additionalInputFields: InputMaybe<
    Array<MailingListBlockAdditionalInputFieldInput>
  >
}

export type SortSocialIconsInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  sortedIconsIds: InputMaybe<Array<Scalars['ID']>>
}

export type UpdateMusicBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  url: InputMaybe<Scalars['URL']>
  playerSize: InputMaybe<MusicPlayerSize>
}

export type UpdateTwitterEmbedBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  tweetUrls: Array<Scalars['String']>
}

export type UpdateVideoBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  url: Scalars['URL']
}

export type AddSocialIconsBlockInput = {
  creatorLinkId: Scalars['ID']
}

export type UpdateLinkBackgroundInput = {
  creatorLinkId: Scalars['ID']
  type: LinkBackgroundStyleType
  color: InputMaybe<Scalars['CSSColor']>
  imageAsset: InputMaybe<Scalars['ID']>
  transparency: InputMaybe<Scalars['Float']>
}

export type UpdateLinkColorsInput = {
  creatorLinkId: Scalars['ID']
  text: InputMaybe<Scalars['CSSColor']>
  buttonText: InputMaybe<Scalars['CSSColor']>
  tipButtonText: InputMaybe<Scalars['CSSColor']>
  buttonBackground: InputMaybe<Scalars['CSSColor']>
  tipButtonBackground: InputMaybe<Scalars['CSSColor']>
}

export type AssignLinkCustomDomainNameInput = {
  creatorLinkId: Scalars['ID']
  domainName: Scalars['String']
}

export type UnassignLinkCustomDomainNameInput = {
  creatorLinkId: Scalars['ID']
  domainName: Scalars['String']
}

export type CheckLinkCustomDomainNameAvailabilityInput = {
  domainName: Scalars['String']
}

export type BuyLinkCustomDomainNameInput = {
  creatorLinkId: Scalars['ID']
  domainName: Scalars['String']
}

export type UpdateLinkBlockStylesInput = {
  creatorLinkId: Scalars['ID']
  transparency: InputMaybe<Scalars['Float']>
  buttonType: InputMaybe<LinkButtonType>
  buttonShape: InputMaybe<LinkButtonShape>
}

export type UpdateLinkStylesInput = {
  creatorLinkId: Scalars['ID']
  templateId: Scalars['ID']
}

export type UpsertSocialLinkPayloadSuccess = {
  __typename?: 'UpsertSocialLinkPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateVipStatusPayloadSuccess = {
  __typename?: 'UpdateVipStatusPayloadSuccess'
  creatorLink: CreatorLink
}

export type AddEmailToMailingListPayloadSuccess = {
  __typename?: 'AddEmailToMailingListPayloadSuccess'
  email: Scalars['String']
  phoneNumber: Maybe<Scalars['String']>
}

export type AddVideoBlockInput = {
  creatorLinkId: Scalars['ID']
  url: InputMaybe<Scalars['URL']>
}

export type AddMusicBlockInput = {
  creatorLinkId: Scalars['ID']
  url: InputMaybe<Scalars['URL']>
  playerSize: InputMaybe<MusicPlayerSize>
}

export type AddTwitterEmbedBlockInput = {
  creatorLinkId: Scalars['ID']
  tweetUrls: InputMaybe<Array<Scalars['String']>>
}

export type AddPremiumBlockInput = {
  creatorLinkId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  service: PremiumService
  resourceId: Scalars['ID']
  cardStyle: InputMaybe<PremiumBlockCardStyle>
  position: InputMaybe<Scalars['Int']>
}

export type AddTipBlockInput = {
  creatorLinkId: Scalars['ID']
  theme: InputMaybe<Scalars['String']>
  buttonText: InputMaybe<Scalars['String']>
  animationType: InputMaybe<LinkBlockAnimationTypes>
  isAnimated: InputMaybe<Scalars['Boolean']>
  cardStyle: InputMaybe<TipBlockCardStyle>
  icon: InputMaybe<Scalars['String']>
  showTipingFeed: InputMaybe<Scalars['Boolean']>
}

export type AddMailingListBlockInput = {
  creatorLinkId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  requireName: InputMaybe<Scalars['Boolean']>
  requirePhone: InputMaybe<Scalars['Boolean']>
  showNameInput: InputMaybe<Scalars['Boolean']>
  showPhoneInput: InputMaybe<Scalars['Boolean']>
  buttonText: InputMaybe<Scalars['String']>
  style: InputMaybe<MailingListBlockStyle>
  storage: InputMaybe<MailingListBlockBackendInput>
}

export type AddCarouselBlockInput = {
  creatorLinkId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  resourceIds: InputMaybe<Array<Scalars['ID']>>
  service: CarouselService
}

export type UpdateLinkProfilePayloadSuccess = {
  __typename?: 'UpdateLinkProfilePayloadSuccess'
  creatorLink: CreatorLink
}

export type AddTextBlockPayloadSuccess = {
  __typename?: 'AddTextBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: TextBlock
}

export type AddCustomBlockPayloadSuccess = {
  __typename?: 'AddCustomBlockPayloadSuccess'
  creatorLink: CreatorLink
  insertedBlockId: Scalars['ID']
  addedBlock: CustomBlock
}

export type AddProductBlockPayloadSuccess = {
  __typename?: 'AddProductBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: ProductBlock
}

export type UpdateProductBlockPayloadSuccess = {
  __typename?: 'UpdateProductBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type AddCoverAssetToProductBlockPayloadSuccess = {
  __typename?: 'AddCoverAssetToProductBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type DeleteCoverAssetFromProductBlockPayloadSuccess = {
  __typename?: 'DeleteCoverAssetFromProductBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateCustomBlockPayloadSuccess = {
  __typename?: 'UpdateCustomBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateLinkBlockVisibilityPayloadSuccess = {
  __typename?: 'UpdateLinkBlockVisibilityPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateTextBlockPayloadSuccess = {
  __typename?: 'UpdateTextBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdatePremiumBlockPayloadSuccess = {
  __typename?: 'UpdatePremiumBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateCarouselBlockPayloadSuccess = {
  __typename?: 'UpdateCarouselBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type AddItemToCarouselBlockPayloadSuccess = {
  __typename?: 'AddItemToCarouselBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpsertSocialIconPayloadSuccess = {
  __typename?: 'UpsertSocialIconPayloadSuccess'
  creatorLink: CreatorLink
}

export type DeleteSocialIconPayloadSuccess = {
  __typename?: 'DeleteSocialIconPayloadSuccess'
  creatorLink: CreatorLink
}

export type SortBlocksPayloadSuccess = {
  __typename?: 'SortBlocksPayloadSuccess'
  creatorLink: CreatorLink
}

export type SortBlockCardsPayloadSuccess = {
  __typename?: 'SortBlockCardsPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateMailingListBlockPayloadSuccess = {
  __typename?: 'UpdateMailingListBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type SortSocialIconsPayloadSuccess = {
  __typename?: 'SortSocialIconsPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateVideoBlockPayloadSuccess = {
  __typename?: 'UpdateVideoBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateMusicBlockPayloadSuccess = {
  __typename?: 'UpdateMusicBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateTwitterEmbedBlockPayloadSuccess = {
  __typename?: 'UpdateTwitterEmbedBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateLinkBackgroundPayloadSuccess = {
  __typename?: 'UpdateLinkBackgroundPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateLinkColorsPayloadSuccess = {
  __typename?: 'UpdateLinkColorsPayloadSuccess'
  creatorLink: CreatorLink
}

export type AssignLinkCustomDomainNamePayloadSuccess = {
  __typename?: 'AssignLinkCustomDomainNamePayloadSuccess'
  creatorLink: CreatorLink
}

export type UnassignLinkCustomDomainNamePayloadSuccess = {
  __typename?: 'UnassignLinkCustomDomainNamePayloadSuccess'
  creatorLink: CreatorLink
}

export type CheckLinkCustomDomainNameAvailabilityPayloadSuccess = {
  __typename?: 'CheckLinkCustomDomainNameAvailabilityPayloadSuccess'
  isAvailable: Scalars['Boolean']
}

export type BuyLinkCustomDomainNamePayloadSuccess = {
  __typename?: 'BuyLinkCustomDomainNamePayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateLinkBlockStylesPayloadSuccess = {
  __typename?: 'UpdateLinkBlockStylesPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateLinkStylesPayloadSuccess = {
  __typename?: 'UpdateLinkStylesPayloadSuccess'
  creatorLink: CreatorLink
}

export type AddVideoBlockPayloadSuccess = {
  __typename?: 'AddVideoBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: VideoPreviewBlock
}

export type AddMusicBlockPayloadSuccess = {
  __typename?: 'AddMusicBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: MusicPreviewBlock
}

export type AddTwitterEmbedBlockPayloadSuccess = {
  __typename?: 'AddTwitterEmbedBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: TwitterEmbedBlock
}

export type AddPremiumBlockPayloadSuccess = {
  __typename?: 'AddPremiumBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: PremiumBlock
}

export type AddTipBlockPayloadSuccess = {
  __typename?: 'AddTipBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: TipBlock
}

export type AddMailingListBlockPayloadSuccess = {
  __typename?: 'AddMailingListBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: MailingListBlock
}

export type AddSocialIconsBlockPayloadSuccess = {
  __typename?: 'AddSocialIconsBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: SocialIconsBlock
}

export type AddCarouselBlockPayloadSuccess = {
  __typename?: 'AddCarouselBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: CarouselBlock
}

export type UpsertSocialLinkPayload =
  | UpsertSocialLinkPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateVipStatusPayload =
  | UpdateVipStatusPayloadSuccess
  | NotFoundError

export type AddEmailToMailingListPayload =
  | AddEmailToMailingListPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateLinkProfilePayload =
  | UpdateLinkProfilePayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type AddTextBlockPayload =
  | AddTextBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateTextBlockPayload =
  | UpdateTextBlockPayloadSuccess
  | NotFoundError
  | InputValidationError
  | AssetNotLinkableToResourceError

export type AddCustomBlockPayload =
  | AddCustomBlockPayloadSuccess
  | NotFoundError
  | InputValidationError
  | AssetNotLinkableToResourceError

export type UpdateCustomBlockPayload =
  | UpdateCustomBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type AddProductBlockPayload =
  | AddProductBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateProductBlockPayload =
  | UpdateProductBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type AddCoverAssetToProductBlockPayload =
  | AddCoverAssetToProductBlockPayloadSuccess
  | NotFoundError
  | InputValidationError
  | AssetNotLinkableToResourceError

export type DeleteCoverAssetFromProductBlockPayload =
  | DeleteCoverAssetFromProductBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateLinkBlockVisibilityPayload =
  | UpdateLinkBlockVisibilityPayloadSuccess
  | NotFoundError

export type UpdatePremiumBlockPayload =
  | UpdatePremiumBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateCarouselBlockPayload =
  | UpdateCarouselBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type AddItemToCarouselBlockPayload =
  | AddItemToCarouselBlockPayloadSuccess
  | NotFoundError

export type UpsertSocialIconPayload =
  | UpsertSocialIconPayloadSuccess
  | NotFoundError
  | InputValidationError

export type DeleteSocialIconPayload =
  | DeleteSocialIconPayloadSuccess
  | NotFoundError

export type SortBlocksPayload =
  | SortBlocksPayloadSuccess
  | InputValidationError
  | NotFoundError

export type SortBlockCardsPayload =
  | SortBlockCardsPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateMailingListBlockPayload =
  | UpdateMailingListBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type SortSocialIconsPayload =
  | SortSocialIconsPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateVideoBlockPayload =
  | UpdateVideoBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddMusicBlockPayload =
  | AddMusicBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddTwitterEmbedBlockPayload =
  | AddTwitterEmbedBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddVideoBlockPayload =
  | AddVideoBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddPremiumBlockPayload =
  | AddPremiumBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddTipBlockPayload =
  | AddTipBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddMailingListBlockPayload =
  | AddMailingListBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddCarouselBlockPayload =
  | AddCarouselBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddSocialIconsBlockPayload =
  | AddSocialIconsBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateMusicBlockPayload =
  | UpdateMusicBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateTwitterEmbedBlockPayload =
  | UpdateTwitterEmbedBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateLinkBackgroundPayload =
  | UpdateLinkBackgroundPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateLinkColorsPayload =
  | UpdateLinkColorsPayloadSuccess
  | NotFoundError

export type AssignLinkCustomDomainNamePayload =
  | AssignLinkCustomDomainNamePayloadSuccess
  | InputValidationError
  | NotFoundError

export type UnassignLinkCustomDomainNamePayload =
  | UnassignLinkCustomDomainNamePayloadSuccess
  | InputValidationError
  | NotFoundError

export type CheckLinkCustomDomainNameAvailabilityPayload =
  | CheckLinkCustomDomainNameAvailabilityPayloadSuccess
  | InputValidationError

export type BuyLinkCustomDomainNamePayload =
  | BuyLinkCustomDomainNamePayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateLinkBlockStylesPayload =
  | UpdateLinkBlockStylesPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateLinkStylesPayload =
  | UpdateLinkStylesPayloadSuccess
  | NotFoundError

export type TransactionRefund = {
  __typename?: 'TransactionRefund'
  amount: Scalars['Int']
  reason: Maybe<Scalars['String']>
  timestamp: Scalars['DateTime']
}

export type Transaction = {
  id: Scalars['ID']
  user: User
  price: Money
  createdAt: Scalars['DateTime']
  status: TransactionStatus
  platform: TransactionPlatform
  creator: Creator
  isPayedOut: Scalars['Boolean']
  isRefunded: Scalars['Boolean']
  isRefundable: Scalars['Boolean']
  saleDiscount: Maybe<SaleDiscount>
  couponDiscount: Maybe<DiscountCoupon>
  refund: Maybe<TransactionRefund>
  paidAt: Maybe<Scalars['DateTime']>
}

export type TransactionsConnection = {
  __typename?: 'TransactionsConnection'
  edges: Maybe<Array<Maybe<TransactionsEdge>>>
  nodes: Maybe<
    Array<
      Maybe<
        | ExclusiveContentOrder
        | ShoutoutOrder
        | OneTimeTip
        | ConsultationOrder
        | ECourseOrder
        | InvoiceOrder
        | LiveStreamOrder
        | MembershipSubscriptionTransaction
        | RecurringTip
      >
    >
  >
  pageInfo: PageInfo
}

export type TransactionsEdge = {
  __typename?: 'TransactionsEdge'
  cursor: Scalars['String']
  node:
    | ExclusiveContentOrder
    | ShoutoutOrder
    | OneTimeTip
    | ConsultationOrder
    | ECourseOrder
    | InvoiceOrder
    | LiveStreamOrder
    | MembershipSubscriptionTransaction
    | RecurringTip
}

export enum TransactionStatus {
  Draft = 'DRAFT',
  OnHold = 'ON_HOLD',
  Paid = 'PAID',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED',
}

export enum TransactionPlatform {
  Paypal = 'PAYPAL',
  Stripe = 'STRIPE',
  Snipfeed = 'SNIPFEED',
}

export enum OnboardingVersion {
  V0 = 'V0',
  V1 = 'V1',
}

export enum AccountType {
  User = 'USER',
  Creator = 'CREATOR',
}

export type SocialAuthInput = {
  token: Scalars['String']
}

export type NextAuthAdapterUpdateUserInput = {
  userId: Scalars['ID']
  email: InputMaybe<Scalars['String']>
  emailVerified: InputMaybe<Scalars['Boolean']>
  name: InputMaybe<Scalars['String']>
}

export type NextAuthAdapterUpdateUserResult = {
  __typename?: 'NextAuthAdapterUpdateUserResult'
  success: Scalars['Boolean']
  error: Maybe<Scalars['String']>
  user: Maybe<User>
  token: Maybe<Scalars['String']>
}

export type NextAuthAdapterGetAccessTokenInput = {
  userId: Scalars['ID']
}

export type NextAuthAdapterGetAccessTokenResult = {
  __typename?: 'NextAuthAdapterGetAccessTokenResult'
  token: Scalars['String']
  creator: Creator
}

export enum NextAuthUserKind {
  Creator = 'CREATOR',
}

export type NextAuthAdapterCreateUserInput = {
  email: Scalars['String']
  emailVerified: InputMaybe<Scalars['Boolean']>
  password: InputMaybe<Scalars['String']>
  name: InputMaybe<Scalars['String']>
  emailLanguage: InputMaybe<EmailLanguage>
  kind: NextAuthUserKind
}

export type NextAuthAdapterCreateUserResult = {
  __typename?: 'NextAuthAdapterCreateUserResult'
  token: Maybe<Scalars['String']>
  user: Maybe<User | Creator>
}

export type NextAuthAdapterValidateUserCredentialsInput = {
  email: Scalars['String']
  rawPassword: Scalars['String']
}

export type NextAuthAdapterValidateUserCredentialsResult = {
  __typename?: 'NextAuthAdapterValidateUserCredentialsResult'
  token: Maybe<Scalars['String']>
  user: Maybe<User | Creator>
}

export type NextAuthAdapterGetUserByEmailInput = {
  email: Scalars['String']
}

export type NextAuthAdapterGetUserByEmailResult = {
  __typename?: 'nextAuthAdapterGetUserByEmailResult'
  token: Maybe<Scalars['String']>
  user: Maybe<User | Creator>
}

export type NextAuthAdapterGetUserByIdInput = {
  id: Scalars['ID']
}

export type NextAuthAdapterGetUserByIdResult = {
  __typename?: 'NextAuthAdapterGetUserByIdResult'
  token: Maybe<Scalars['String']>
  user: Maybe<User | Creator>
}

export type NextAuthAdapterGetUserByAccountInput = {
  provider: Scalars['String']
  providerAccountId: Scalars['ID']
}

export type NextAuthAdapterGetUserByAccountResult = {
  __typename?: 'NextAuthAdapterGetUserByAccountResult'
  token: Maybe<Scalars['String']>
  user: Maybe<User | Creator>
}

export type NextAuthAdapterLinkAccountInput = {
  provider: Scalars['String']
  providerAccountId: Scalars['ID']
  account: Scalars['String']
  userId: Scalars['ID']
}

export type NextAuthAdapterLinkAccountResult = {
  __typename?: 'NextAuthAdapterLinkAccountResult'
  token: Scalars['String']
  user: User | Creator
}

/** Represents an error in the input of a mutation. */
export type UserError = {
  /** The error code */
  code: Scalars['String']
  /** The error message. */
  message: Scalars['String']
}

/** Represents a field causing an error in the input of a mutation */
export type ValidationErrorItem = {
  __typename?: 'ValidationErrorItem'
  /** The field name */
  field: Scalars['String']
  /** The error message. */
  message: Scalars['String']
  code: Maybe<Scalars['String']>
}

/** Represents an item causing error in the add/remove Content from a bundle. */
export type UpdateCollectionContentErrorItem = {
  __typename?: 'UpdateCollectionContentErrorItem'
  /** The reason the content can't be added/removed from the collection */
  reason: Scalars['String']
  /** The content global identifier */
  contentId: Scalars['ID']
}

/** Represents an error in the input of a mutation form. */
export type InputValidationError = UserError & {
  __typename?: 'InputValidationError'
  message: Scalars['String']
  code: Scalars['String']
  /** List of invalid fields */
  errors: Array<ValidationErrorItem>
}

/** Represents an error in the input of a mutation form. */
export type CaptchaVerificationFailedError = UserError & {
  __typename?: 'CaptchaVerificationFailedError'
  message: Scalars['String']
  code: Scalars['String']
  /** Action name that the user is supposed to do */
  intendedAction: Scalars['String']
}

/** Represents an error during the update of a user's email. */
export type EmailTakenError = UserError & {
  __typename?: 'EmailTakenError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an error during the update of a user's username. */
export type UsernameTakenError = UserError & {
  __typename?: 'UsernameTakenError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an error during user password sign-in. */
export type WrongCredentialsError = UserError & {
  __typename?: 'WrongCredentialsError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an error violating unique constraint on a field in mutation. */
export type UniqueConstraintViolationError = UserError & {
  __typename?: 'UniqueConstraintViolationError'
  code: Scalars['String']
  message: Scalars['String']
  field: Scalars['String']
}

/** Represents an error in mutation of a missing resource. */
export type NotFoundError = UserError & {
  __typename?: 'NotFoundError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an error in free order mutation where the coupon is only parital (i.e. doesn't make the service free) */
export type PartialCouponDiscountError = UserError & {
  __typename?: 'PartialCouponDiscountError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an error in mutation of a non-deletable resource. */
export type NotDeletableError = UserError & {
  __typename?: 'NotDeletableError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an error in mutation responsible of publishing the content. */
export type UnpublishableContentError = UserError & {
  __typename?: 'UnpublishableContentError'
  code: Scalars['String']
  message: Scalars['String']
  /** List of fields preventing content from being publisheed */
  errors: Array<ValidationErrorItem>
}

/** Represents an error in the add/remove Content from a bundle. */
export type UpdateCollectionContentError = UserError & {
  __typename?: 'UpdateCollectionContentError'
  code: Scalars['String']
  message: Scalars['String']
  /** List of Contents causing the error with the reason */
  errors: Array<UpdateCollectionContentErrorItem>
}

/** Error Returned By Stripe API */
export type StripeClientRequestError = UserError & {
  __typename?: 'StripeClientRequestError'
  code: Scalars['String']
  /** Stripe error message */
  message: Scalars['String']
  /** Stripe error type */
  type: Scalars['String']
}

/** Error Returned By Paypal API */
export type PaypalClientRequestError = UserError & {
  __typename?: 'PaypalClientRequestError'
  code: Scalars['String']
  /** Stripe error message */
  message: Scalars['String']
  /** Stripe error type */
  name: Scalars['String']
}

/** Represents an Error in CreateImportContentRequest mutation */
export type InvalidImportContentUrlError = UserError & {
  __typename?: 'InvalidImportContentUrlError'
  code: Scalars['String']
  /** Reason for not being able to scrap the url */
  message: Scalars['String']
}

/**
 * Represents an Error in AddBookingOrder mutation
 * Creator has the feature disabled.
 */
export type CreatorUnavailableError = UserError & {
  __typename?: 'CreatorUnavailableError'
  code: Scalars['String']
  /** Reason for not being able to place an order with the creator. */
  message: Scalars['String']
}

/**
 * Represents an Error when updating Booking Order Status
 * mutations: acceptBookingOrder, rejectBookingOrder
 */
export type UpdateOrderStatusError = UserError & {
  __typename?: 'UpdateOrderStatusError'
  code: Scalars['String']
  /** Reason for not being able to update the order status. */
  message: Scalars['String']
}

/**
 * Represents an Error specifying that the creator has no configured
 * payment method
 */
export type CreatorNoConnectedPaymentError = UserError & {
  __typename?: 'CreatorNoConnectedPaymentError'
  code: Scalars['String']
  message: Scalars['String']
}

export type PaymentDeclinedError = UserError & {
  __typename?: 'PaymentDeclinedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the User already bought the bundle. */
export type BundleAlreadyPurchasedError = UserError & {
  __typename?: 'BundleAlreadyPurchasedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the User already bought the exclusive content. */
export type ExclusiveContentAlreadyPurchasedError = UserError & {
  __typename?: 'ExclusiveContentAlreadyPurchasedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the User already bought the liveStream. */
export type LiveStreamAlreadyPurchasedError = UserError & {
  __typename?: 'LiveStreamAlreadyPurchasedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Live stream is full. */
export type LiveStreamAlreadyFullError = UserError & {
  __typename?: 'LiveStreamAlreadyFullError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Live stream has already happened. */
export type LiveStreamAlreadyHappenedError = UserError & {
  __typename?: 'LiveStreamAlreadyHappenedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Livestream is not free. */
export type LiveStreamIsMonetizedError = UserError & {
  __typename?: 'LiveStreamIsMonetizedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Livestream is free. */
export type LiveStreamIsNotMonetizedError = UserError & {
  __typename?: 'LiveStreamIsNotMonetizedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Livestream price cannot be updated. */
export type LiveStreamHasRegisteredUsersError = UserError & {
  __typename?: 'LiveStreamHasRegisteredUsersError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that a subscription already exists. */
export type ExistingSubscriptionError = UserError & {
  __typename?: 'ExistingSubscriptionError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Platform cannot be scrapped. */
export type UnsupportedPlatformError = UserError & {
  __typename?: 'UnsupportedPlatformError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error from a third party service. */
export type ExternalServiceRequestError = UserError & {
  __typename?: 'ExternalServiceRequestError'
  code: Scalars['String']
  message: Scalars['String']
}

export type ShoutoutIsMonetizedError = UserError & {
  __typename?: 'ShoutoutIsMonetizedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error which specifies that the link import request is already being processed. */
export type ImportContentLinkBeingProcessedError = UserError & {
  __typename?: 'ImportContentLinkBeingProcessedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error which specifies that the link import request is already processed. */
export type ImportContentLinkAlreadyProcessedError = UserError & {
  __typename?: 'ImportContentLinkAlreadyProcessedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error which specifies that the actor is already disabled. */
export type ActorAlreadyDisabledError = UserError & {
  __typename?: 'ActorAlreadyDisabledError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error which specifies that the actor is already enabled. */
export type ActorAlreadyEnabledError = UserError & {
  __typename?: 'ActorAlreadyEnabledError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error which specifies that the actor is disabled, hence, he/she can't be authenticated. */
export type ActorDisabledError = UserError & {
  __typename?: 'ActorDisabledError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error which specifies that the consultation availabilities is invalid */
export type InvalidConsultationAvailabilitiesError = UserError & {
  __typename?: 'InvalidConsultationAvailabilitiesError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the User already bought the consultation. */
export type ConsultationAlreadyPurchasedError = UserError & {
  __typename?: 'ConsultationAlreadyPurchasedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the consultation is unavailable at this date. */
export type ConsultationUnavailableError = UserError & {
  __typename?: 'ConsultationUnavailableError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Consultation is not free. */
export type ConsultationIsMonetizedError = UserError & {
  __typename?: 'ConsultationIsMonetizedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Consultation is free. */
export type ConsultationIsNotMonetizedError = UserError & {
  __typename?: 'ConsultationIsNotMonetizedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that an Asset isn't linkable to a resource. */
export type AssetNotLinkableToResourceError = UserError & {
  __typename?: 'AssetNotLinkableToResourceError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Exclusive Content is not free. */
export type ExclusiveContentIsMonetizedError = UserError & {
  __typename?: 'ExclusiveContentIsMonetizedError'
  code: Scalars['String']
  message: Scalars['String']
}

/** Represents an Error specifying that the Exclusive Content is free. */
export type ExclusiveContentIsNotMonetizedError = UserError & {
  __typename?: 'ExclusiveContentIsNotMonetizedError'
  code: Scalars['String']
  message: Scalars['String']
}

export type ImportContentLinkCreatorInput = {
  username: Scalars['String']
  email: Scalars['String']
}

export type CreateImportContentLinkRequestInput = {
  url: InputMaybe<Scalars['URL']>
  socialLinks: InputMaybe<Array<InputMaybe<Scalars['URL']>>>
  creatorInformation: InputMaybe<ImportContentLinkCreatorInput>
}

export type CreateImportContentLinkRequestPayloadSuccess = {
  __typename?: 'CreateImportContentLinkRequestPayloadSuccess'
  creator: Creator
}

export type CreateImportContentLinkRequestPayload =
  | CreateImportContentLinkRequestPayloadSuccess
  | UnsupportedPlatformError
  | InputValidationError
  | UniqueConstraintViolationError
  | InvalidImportContentUrlError
  | NotFoundError
  | ImportContentLinkBeingProcessedError
  | ImportContentLinkAlreadyProcessedError

/** Currency Codes */
export enum CurrencyCode {
  /** Euro (EUR). */
  Eur = 'EUR',
  /** Canadian Dollars (CAD). */
  Cad = 'CAD',
  /** United States Dollars (USD). */
  Usd = 'USD',
  /** United Kingdom Pounds (GBP). */
  Gbp = 'GBP',
  /** Australian Dollars (AUD). */
  Aud = 'AUD',
  /** Swiss Franc (CHF). */
  Chf = 'CHF',
}

export enum DiscountCouponStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type DiscountCoupon = {
  __typename?: 'DiscountCoupon'
  allowCombiningWithSaleDiscount: Scalars['Boolean']
  code: Scalars['ID']
  discountAmount: Maybe<Scalars['Int']>
  discountPercentage: Maybe<Scalars['Float']>
  expiryDateLimit: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  numberOfRedemptions: Scalars['Int']
  numberOfRedemptionsLimit: Maybe<Scalars['Int']>
  status: DiscountCouponStatus
  isApplicable: Scalars['Boolean']
}

/** A monetary value with currency. */
export type Money = {
  __typename?: 'Money'
  /** Integer money amount in cents */
  amount: Scalars['Int']
  /** Currency of the money. */
  currencyCode: CurrencyCode
}

export type Discount = {
  __typename?: 'Discount'
  price: Money
}

/** A monetary value with currency. */
export type MoneyInput = {
  /** Integer money amount in cents */
  amount: Scalars['Int']
  /** Currency of the money. */
  currencyCode: CurrencyCode
}

/** Stripe PaymentIntent information, with account public key */
export type PaymentIntent = {
  __typename?: 'PaymentIntent'
  id: Scalars['String']
  publicKey: Maybe<Scalars['String']>
  accountId: Scalars['String']
  clientSecret: Scalars['String']
  /**
   * Stripe PaymentIntent status, one of requires_payment_method, requires_confirmation, requires_action, processing, requires_capture, canceled, or succeeded.
   * See https://stripe.com/docs/payments/intents#intent-statuses
   */
  status: Maybe<Scalars['String']>
}

/** Paypal Order information, with Approve Link */
export type PaypalOrder = {
  __typename?: 'PaypalOrder'
  id: Scalars['String']
  approveLink: Maybe<Scalars['URL']>
}

export type PaypalOrderLink = {
  __typename?: 'PaypalOrderLink'
  rel: Scalars['String']
  href: Scalars['URL']
  method: Scalars['String']
}

export type UserCheckoutInput = {
  email: Scalars['String']
  name: Scalars['String']
  phone: InputMaybe<Scalars['String']>
  emailLanguage: InputMaybe<EmailLanguage>
  anonymousId: InputMaybe<Scalars['String']>
}

export type AuthorizeApprovedPaypalOrderInput = {
  transactionId: Scalars['ID']
  paypalClientMetadataId: Scalars['String']
}

export type AuthorizeApprovedPaypalOrderPayloadSuccess = {
  __typename?: 'AuthorizeApprovedPaypalOrderPayloadSuccess'
  transactionId: Scalars['String']
}

export type ReauthorizeApprovedPaypalOrderInput = {
  transactionId: Scalars['ID']
  paypalClientMetadataId: Scalars['String']
}

export type ReauthorizeApprovedPaypalOrderPayloadSuccess = {
  __typename?: 'ReauthorizeApprovedPaypalOrderPayloadSuccess'
  transactionId: Scalars['String']
}

export type CaptureApprovedPaypalOrderInput = {
  transactionId: Scalars['ID']
  paypalClientMetadataId: Scalars['String']
}

export type CaptureApprovedPaypalOrderPayloadSuccess = {
  __typename?: 'CaptureApprovedPaypalOrderPayloadSuccess'
  transactionId: Scalars['String']
}

export enum MonetizableService {
  Bundles = 'BUNDLES',
  Tips = 'TIPS',
  Livestreams = 'LIVESTREAMS',
  Shoutouts = 'SHOUTOUTS',
  Consultations = 'CONSULTATIONS',
  Ecourses = 'ECOURSES',
  Invoices = 'INVOICES',
}

export type AddStripeIntentInput = {
  amount: Scalars['Int']
  currencyCode: CurrencyCode
  creatorId: Scalars['ID']
  service: MonetizableService
  captureMethod: InputMaybe<CaptureMethodType>
}

export enum RefundTransactionReason {
  Duplicate = 'DUPLICATE',
  Fraudulent = 'FRAUDULENT',
  RequestedByCustomer = 'REQUESTED_BY_CUSTOMER',
}

export type RefundTransactionInput = {
  amount: InputMaybe<Scalars['Int']>
  transactionId: Scalars['ID']
  reason: InputMaybe<RefundTransactionReason>
}

export type AddStripeIntentPayloadSuccess = {
  __typename?: 'AddStripeIntentPayloadSuccess'
  paymentIntent: PaymentIntent
}

export type RefundTransactionPayloadSuccess = {
  __typename?: 'RefundTransactionPayloadSuccess'
  message: Scalars['String']
}

export type AuthorizeApprovedPaypalOrderPayload =
  | AuthorizeApprovedPaypalOrderPayloadSuccess
  | PaypalClientRequestError
  | NotFoundError

export type ReauthorizeApprovedPaypalOrderPayload =
  | ReauthorizeApprovedPaypalOrderPayloadSuccess
  | PaypalClientRequestError
  | NotFoundError

export type AddStripeIntentPayload =
  | AddStripeIntentPayloadSuccess
  | CreatorUnavailableError
  | InputValidationError
  | StripeClientRequestError
  | CreatorNoConnectedPaymentError

export type RefundTransactionPayload =
  | RefundTransactionPayloadSuccess
  | InputValidationError
  | StripeClientRequestError
  | PaypalClientRequestError
  | NotFoundError

export type CaptureApprovedPaypalOrderPayload =
  | CaptureApprovedPaypalOrderPayloadSuccess
  | PaypalClientRequestError
  | NotFoundError
  | PaymentDeclinedError

export type DisableActorInput = {
  actorId: Scalars['ID']
  reason: InputMaybe<Scalars['String']>
  by: InputMaybe<Scalars['String']>
}

export type EnableActorInput = {
  actorId: Scalars['ID']
}

export type DisableActorPayloadSuccess = {
  __typename?: 'DisableActorPayloadSuccess'
  actorId: Scalars['ID']
}

export type EnableActorPayloadSuccess = {
  __typename?: 'EnableActorPayloadSuccess'
  actorId: Scalars['ID']
}

export type DisableActorPayload =
  | DisableActorPayloadSuccess
  | NotFoundError
  | ActorAlreadyDisabledError
  | InputValidationError

export type EnableActorPayload =
  | EnableActorPayloadSuccess
  | NotFoundError
  | ActorAlreadyEnabledError

export type Consultation = Node &
  RatingInterface &
  TestimonialInterface & {
    __typename?: 'Consultation'
    id: Scalars['ID']
    owner: Creator
    title: Maybe<Scalars['String']>
    coverAsset: Maybe<Asset>
    description: Maybe<Scalars['String']>
    status: ConsultationStatus
    price: Maybe<Money>
    requirePhone: Scalars['Boolean']
    scheduleRange:
      | ConsultationScheduleRangeFutureDay
      | ConsultationScheduleRangeFutureIndefinitely
      | ConsultationScheduleRangeFutureRange
    databaseId: Scalars['String']
    /** Represents consultation duration in minutes. */
    duration: Maybe<Scalars['Int']>
    platform: Maybe<ConsultationPlatform>
    timezone: Maybe<Scalars['TimeZone']>
    availabilities: Array<
      ConsultationAvailabilityDay | ConsultationAvailabilityDate
    >
    isInsideLinkBlock: Scalars['Boolean']
    intervalStep: Scalars['Int']
    /** Represents the time in minutes where an user can schedule the consultation before the startDate */
    scheduleLimit: Scalars['Int']
    availabilityTimeBuffer: Maybe<ConsultationAvailabilityTimeBuffer>
    /** Represents all consultation available dates that can be booked. */
    availableSchedules: Array<ConsultationAvailableSchedule>
    isDeletable: Scalars['Boolean']
    questions: Maybe<Array<Maybe<CustomQuestion>>>
    saleDiscount: Maybe<Discount>
    discountCoupons: Maybe<Array<DiscountCoupon>>
    receiptEmailCustomMessageTemplate: Maybe<Scalars['String']>
    ratingConfig: Maybe<MonetizableServiceRatingConfig>
    rating: Maybe<MonetizableServiceRating>
    ratings: Maybe<RatingsConnection>
    testimonialConfig: Maybe<MonetizableServiceTestimonialConfig>
    testimonials: Maybe<TestimonialsConnection>
    shortUrl: Maybe<ShortUrlEmbedded>
    slug: Maybe<Scalars['String']>
    descriptionV2: Maybe<Scalars['String']>
    descriptionHTML: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplateV2: Maybe<Scalars['String']>
  }

export type ConsultationAvailableSchedulesArgs = {
  from: Scalars['Date']
  to: Scalars['Date']
}

export type ConsultationRatingsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export enum ConsultationScheduleRangeTypes {
  FutureDay = 'FUTURE_DAY',
  FutureIndefinitely = 'FUTURE_INDEFINITELY',
  FutureRange = 'FUTURE_RANGE',
}

export type ConsultationScheduleRange = {
  type: ConsultationScheduleRangeTypes
}

export type ConsultationAvailabilityTimeBuffer = {
  __typename?: 'ConsultationAvailabilityTimeBuffer'
  before: Maybe<Scalars['Int']>
  after: Maybe<Scalars['Int']>
}

export type ConsultationScheduleRangeItem =
  | ConsultationScheduleRangeFutureDay
  | ConsultationScheduleRangeFutureIndefinitely
  | ConsultationScheduleRangeFutureRange

export type ConsultationScheduleRangeFutureDay = ConsultationScheduleRange & {
  __typename?: 'ConsultationScheduleRangeFutureDay'
  type: ConsultationScheduleRangeTypes
  day: Scalars['Int']
}

export type ConsultationScheduleRangeFutureIndefinitely =
  ConsultationScheduleRange & {
    __typename?: 'ConsultationScheduleRangeFutureIndefinitely'
    type: ConsultationScheduleRangeTypes
  }

export type ConsultationScheduleRangeFutureRange = ConsultationScheduleRange & {
  __typename?: 'ConsultationScheduleRangeFutureRange'
  type: ConsultationScheduleRangeTypes
  from: Scalars['Date']
  to: Scalars['Date']
}

export type ConsultationAvailableScheduleSlot = {
  __typename?: 'ConsultationAvailableScheduleSlot'
  /** Represents dateTime with specified timezone. */
  startDate: Scalars['DateTime']
}

export type ConsultationAvailableSchedule = {
  __typename?: 'ConsultationAvailableSchedule'
  date: Scalars['Date']
  slots: Array<ConsultationAvailableScheduleSlot>
}

export type BookedAvailability = {
  __typename?: 'BookedAvailability'
  startDate: Scalars['DateTime']
  endDate: Scalars['DateTime']
}

export type ConsultationAvailability = {
  type: ConsultationAvailabilityTypes
  intervals: Array<ConsultationAvailabilityInterval>
}

export type ConsultationAvailabilityDay = ConsultationAvailability & {
  __typename?: 'ConsultationAvailabilityDay'
  type: ConsultationAvailabilityTypes
  intervals: Array<ConsultationAvailabilityInterval>
  day: ConsultationAvailabilityDays
}

export type ConsultationAvailabilityDate = ConsultationAvailability & {
  __typename?: 'ConsultationAvailabilityDate'
  type: ConsultationAvailabilityTypes
  intervals: Array<ConsultationAvailabilityInterval>
  date: Scalars['Date']
}

export type ConsultationAvailabilityInterval = {
  __typename?: 'ConsultationAvailabilityInterval'
  /** Represents consultation availability interval start time in minutes. */
  startTime: Scalars['Int']
  /** Represents consultation availability interval end time in minutes. */
  endTime: Scalars['Int']
}

export type CustomQuestion = {
  __typename?: 'CustomQuestion'
  question: Scalars['String']
  answerType: CustomQuestionAnswerTypes
  required: Scalars['Boolean']
  options: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum ConsultationAvailabilityDays {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export enum ConsultationStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export enum ConsultationPlatform {
  Meet = 'MEET',
  Zoom = 'ZOOM',
  Phone = 'PHONE',
  Irl = 'IRL',
}

export enum ConsultationAvailabilityTypes {
  Day = 'DAY',
  Date = 'DATE',
}

export enum CustomQuestionAnswerTypes {
  MultiLine = 'MULTI_LINE',
  SingleLine = 'SINGLE_LINE',
  MultipleChoice = 'MULTIPLE_CHOICE',
  Checkbox = 'CHECKBOX',
  Dropdown = 'DROPDOWN',
}

export type CustomQuestionInput = {
  question: Scalars['String']
  answerType: CustomQuestionAnswerTypes
  required: Scalars['Boolean']
  options: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ConsultationOrder = Node &
  Transaction &
  OrderRatingInterface & {
    __typename?: 'ConsultationOrder'
    id: Scalars['ID']
    user: User
    price: Money
    platform: TransactionPlatform
    status: TransactionStatus
    createdAt: Scalars['DateTime']
    consultation: Maybe<Consultation>
    creator: Creator
    isPayedOut: Scalars['Boolean']
    timezone: Scalars['String']
    startDate: Scalars['DateTime']
    endDate: Scalars['DateTime']
    canceledBy: Maybe<ConsultationOrderCanceledBy>
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    meetUrl: Maybe<Scalars['URL']>
    isCanceled: Scalars['Boolean']
    userMessage: Maybe<Scalars['String']>
    phone: Maybe<Scalars['String']>
    questionAnswers: Maybe<Array<Maybe<CustomQuestionAnswer>>>
    refund: Maybe<TransactionRefund>
    paidAt: Maybe<Scalars['DateTime']>
    rating: Maybe<Rating>
    ownerMessage: Maybe<Scalars['String']>
  }

export enum ConsultationOrderCanceledBy {
  Customer = 'CUSTOMER',
  Creator = 'CREATOR',
}

export type ConsultationOrderConnection = {
  __typename?: 'ConsultationOrderConnection'
  edges: Maybe<Array<Maybe<ConsultationOrderEdge>>>
  nodes: Maybe<Array<Maybe<ConsultationOrder>>>
  pageInfo: PageInfo
}

export type ConsultationOrderEdge = {
  __typename?: 'ConsultationOrderEdge'
  cursor: Scalars['String']
  node: ConsultationOrder
}

export type CustomQuestionAnswer = {
  __typename?: 'CustomQuestionAnswer'
  question: Scalars['String']
  answer: Scalars['String']
}

export type CustomQuestionAnswerInput = {
  question: Scalars['String']
  answer: Scalars['String']
}

export type SaleDiscount = {
  __typename?: 'SaleDiscount'
  price: Money
}

export type SaleDiscountResource =
  | Consultation
  | ExclusiveContent
  | LiveStream
  | Shoutout
  | ECourse

export type ApplySaleDiscountInput = {
  resourceId: Scalars['ID']
  discountedPriceAmount: Scalars['Int']
}

export type ApplySaleDiscountPayloadSuccess = {
  __typename?: 'ApplySaleDiscountPayloadSuccess'
  resource: SaleDiscountResource
}

export type ApplySaleDiscountPayload =
  | ApplySaleDiscountPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UnapplySaleDiscountInput = {
  resourceId: Scalars['ID']
}

export type UnapplySaleDiscountPayloadSuccess = {
  __typename?: 'UnapplySaleDiscountPayloadSuccess'
  resource: SaleDiscountResource
}

export type UnapplySaleDiscountPayload =
  | UnapplySaleDiscountPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddCreatorToFirstPromoterInput = {
  creatorId: Scalars['ID']
}

export type AddCreatorToFirstPromoterPayload =
  | AddCreatorToFirstPromoterPayloadSuccess
  | NotFoundError
  | FirstPromoterError

export type AddCreatorToFirstPromoterPayloadSuccess = {
  __typename?: 'AddCreatorToFirstPromoterPayloadSuccess'
  creator: Creator
}

export type ExportDataRequestInput = {
  type: ExportDataRequestTypes
}

export type ExportDataRequestPayload =
  | ExportDataRequestPayloadSuccess
  | ExternalServiceRequestError
  | NotFoundError

export type ExportDataRequestPayloadSuccess = {
  __typename?: 'ExportDataRequestPayloadSuccess'
  asset: Maybe<Asset>
}

export enum ExportDataRequestTypes {
  Customers = 'CUSTOMERS',
}

export type GenerateFirstPromoterIframeTokenInput = {
  creatorId: Scalars['ID']
}

export type GenerateFirstPromoterIframeTokenPayload =
  | GenerateFirstPromoterIframeTokenPayloadSuccess
  | NotFoundError
  | FirstPromoterError

export type GenerateFirstPromoterIframeTokenPayloadSuccess = {
  __typename?: 'GenerateFirstPromoterIframeTokenPayloadSuccess'
  accessToken: Scalars['String']
  expiresIn: Scalars['Int']
}

export type UpdateCreatorSelectedGoogleCalendarsInput = {
  creatorId: Scalars['ID']
  calendarIds: Array<Scalars['String']>
}

export type UpdateCreatorSelectedGoogleCalendarsPayload =
  | UpdateCreatorSelectedGoogleCalendarsPayloadSuccess
  | ExternalServiceRequestError
  | NotFoundError

export type UpdateCreatorSelectedGoogleCalendarsPayloadSuccess = {
  __typename?: 'UpdateCreatorSelectedGoogleCalendarsPayloadSuccess'
  creator: Creator
}

export type CompleteUserProfileInput = {
  name: Scalars['String']
  username: Scalars['String']
  phone: InputMaybe<Scalars['String']>
  referralCode: InputMaybe<Scalars['String']>
  fpTrackingId: InputMaybe<Scalars['String']>
  creatorId: InputMaybe<Scalars['ID']>
  onboardingVersion: OnboardingVersion
}

export type CompleteUserProfilePayload =
  | CompleteUserProfilePayloadSuccess
  | NotFoundError
  | InputValidationError
  | UsernameTakenError

export type CompleteUserProfilePayloadSuccess = {
  __typename?: 'CompleteUserProfilePayloadSuccess'
  user: User | Creator
  token: Scalars['String']
}

export type AccountAvailableForLinkingPayload = {
  __typename?: 'AccountAvailableForLinkingPayload'
  email: Scalars['String']
}

export type FindOrCreatePlanolyUserInput = {
  token: Scalars['String']
  socialSetId: Scalars['Int']
  strategy: InputMaybe<FindOrCreatePlanolyUserStrategy>
}

export type FindOrCreatePlanolyUserPayload =
  | FindOrCreatePlanolyUserPayloadSuccess
  | AccountAvailableForLinkingPayload
  | NotFoundError
  | ActorDisabledError
  | CaptchaVerificationFailedError

export type FindOrCreatePlanolyUserPayloadSuccess = {
  __typename?: 'FindOrCreatePlanolyUserPayloadSuccess'
  token: Scalars['String']
  workspaceName: Scalars['String']
  isNew: Scalars['Boolean']
}

export type RequestPasswordResetInput = {
  email: Scalars['String']
  accountType: AccountType
}

export type RequestPasswordResetPayload =
  | RequestPasswordResetPayloadSuccess
  | InputValidationError
  | NotFoundError
  | ActorDisabledError
  | CaptchaVerificationFailedError

export type RequestPasswordResetPayloadSuccess = {
  __typename?: 'RequestPasswordResetPayloadSuccess'
  email: Maybe<Scalars['String']>
}

export type ResetPasswordInput = {
  password: Scalars['String']
  token: Scalars['String']
}

export type ResetPasswordPayload =
  | ResetPasswordPayloadSuccess
  | InputValidationError
  | NotFoundError
  | ActorDisabledError
  | CaptchaVerificationFailedError

export type ResetPasswordPayloadSuccess = {
  __typename?: 'ResetPasswordPayloadSuccess'
  me: User | Creator
  token: Scalars['String']
  singleUseToken: Scalars['String']
}

export type ConnectShopifyInput = {
  code: Scalars['String']
  shop: Scalars['String']
}

export type ConnectShopifyPayload =
  | ConnectShopifyPayloadSuccess
  | ExternalServiceRequestError
  | NotFoundError

export type ConnectShopifyPayloadSuccess = {
  __typename?: 'ConnectShopifyPayloadSuccess'
  creator: Creator
}

export type DisconnectShopifyInput = {
  creatorId: Scalars['ID']
}

export type DisconnectShopifyPayload =
  | DisconnectShopifyPayloadSuccess
  | NotFoundError

export type DisconnectShopifyPayloadSuccess = {
  __typename?: 'DisconnectShopifyPayloadSuccess'
  creator: Creator
}

export type SigninAsCreatorInput = {
  creatorId: Scalars['ID']
}

export type SigninAsCreatorPayload =
  | SigninAsCreatorPayloadSuccess
  | NotFoundError
  | ActorDisabledError
  | CaptchaVerificationFailedError

export type SigninAsCreatorPayloadSuccess = {
  __typename?: 'SigninAsCreatorPayloadSuccess'
  creator: Creator
  singleUseToken: Scalars['String']
}

export type SigninBySingleUseTokenInput = {
  token: Scalars['String']
}

export type SigninBySingleUseTokenPayload =
  | SigninBySingleUseTokenPayloadSuccess
  | NotFoundError
  | ActorDisabledError
  | CaptchaVerificationFailedError

export type SigninBySingleUseTokenPayloadSuccess = {
  __typename?: 'SigninBySingleUseTokenPayloadSuccess'
  me: User | Creator
  token: Scalars['String']
}

export type SigninCreatorInput = {
  email: Scalars['String']
  password: Scalars['String']
}

export type SigninCreatorPayload =
  | SigninCreatorPayloadSuccess
  | InputValidationError
  | WrongCredentialsError
  | ActorDisabledError
  | CaptchaVerificationFailedError

export type SigninCreatorPayloadSuccess = {
  __typename?: 'SigninCreatorPayloadSuccess'
  me: Creator
  token: Scalars['String']
}

export type SignupCreatorInput = {
  email: Scalars['String']
  username: InputMaybe<Scalars['String']>
  name: InputMaybe<Scalars['String']>
  password: Scalars['String']
  bio: InputMaybe<Scalars['String']>
  referralCode: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  emailLanguage: InputMaybe<EmailLanguage>
  onboardingVersion: InputMaybe<OnboardingVersion>
}

export type SignupCreatorPayload =
  | SignupCreatorPayloadSuccess
  | InputValidationError
  | EmailTakenError
  | UsernameTakenError
  | CaptchaVerificationFailedError

export type SignupCreatorPayloadSuccess = {
  __typename?: 'SignupCreatorPayloadSuccess'
  me: Creator
  token: Scalars['String']
  singleUseToken: Scalars['String']
}

export type Customer = {
  __typename?: 'Customer'
  name: Maybe<Scalars['String']>
  email: Scalars['String']
  phone: Maybe<Scalars['String']>
  firstPurchaseDate: Scalars['Date']
  totalPurchases: Scalars['Int']
  totalSpent: Scalars['Int']
}

export type CustomerConnection = {
  __typename?: 'CustomerConnection'
  edges: Array<CustomersEdge>
  nodes: Array<Customer>
  pageInfo: PageInfo
}

export type CustomerFilterByField = {
  minimumSpent: InputMaybe<Scalars['Int']>
}

export enum CustomerSortField {
  TotalSpent = 'TOTAL_SPENT',
}

export type CustomersEdge = {
  __typename?: 'CustomersEdge'
  node: Customer
  cursor: Scalars['String']
}

export enum FindOrCreatePlanolyUserStrategy {
  Default = 'DEFAULT',
  CreateIgnoreExisting = 'CREATE_IGNORE_EXISTING',
  LinkExistingAccount = 'LINK_EXISTING_ACCOUNT',
}

export type InvoiceDefaults = {
  __typename?: 'InvoiceDefaults'
  issuer: Maybe<InvoiceIssuer>
}

export type InvoicesPreferences = {
  __typename?: 'InvoicesPreferences'
  defaultValues: Maybe<InvoiceDefaults>
}

export type PlanolyAccount = {
  __typename?: 'PlanolyAccount'
  userId: Scalars['Int']
  socialSetId: Scalars['Int']
}

export enum PolicyKindEnumType {
  TermsOfService = 'TermsOfService',
  PrivacyPolicy = 'PrivacyPolicy',
  RefundPolicy = 'RefundPolicy',
}

export type Policy = {
  __typename?: 'Policy'
  kind: PolicyKindEnumType
  description: Maybe<Scalars['String']>
}

export type SignupUserPayloadSuccess = {
  __typename?: 'SignupUserPayloadSuccess'
  me: User
  token: Scalars['String']
}

export type VerificationToken = {
  __typename?: 'VerificationToken'
  token: Scalars['String']
  expiryDate: Scalars['DateTime']
}

export type ShopifyCollectionType = {
  __typename?: 'ShopifyCollectionType'
  title: Scalars['String']
  description: Scalars['String']
  collectionImageUrl: Maybe<Scalars['String']>
  shopifyCollectionId: Scalars['String']
  products: Array<Maybe<ShopifyProductType>>
}

export type ShopifyProductType = {
  __typename?: 'ShopifyProductType'
  shopifyProductId: Scalars['String']
  title: Scalars['String']
  url: Maybe<Scalars['String']>
  description: Scalars['String']
  productImageUrls: Maybe<Array<Scalars['String']>>
  priceRange: ShopifyProductPriceRange
}

export type ShortUrlConnection = {
  __typename?: 'ShortUrlConnection'
  edges: Array<ShortUrlsEdge>
  nodes: Array<ShortUrl>
  pageInfo: PageInfo
}

export enum ShortUrlSortField {
  CreatedAt = 'CREATED_AT',
}

export type ShortUrlsEdge = {
  __typename?: 'ShortUrlsEdge'
  node: ShortUrl
  cursor: Scalars['String']
}

export type UnlinkCreatorAccountFromPlanolyInput = {
  planolyToken: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
}

export type UnlinkCreatorAccountFromPlanolyPayload =
  | UnlinkCreatorAccountFromPlanolyPayloadSuccess
  | NotFoundError
  | InputValidationError
  | EmailTakenError

export type UnlinkCreatorAccountFromPlanolyPayloadSuccess = {
  __typename?: 'UnlinkCreatorAccountFromPlanolyPayloadSuccess'
  creator: Creator
  token: Scalars['String']
}

export type UpdateCreatorReplyToEmailInput = {
  replyToEmail: InputMaybe<Scalars['String']>
}

export type UpdateCreatorReplyToEmailPayload =
  | UpdateCreatorReplyToEmailPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateCreatorReplyToEmailPayloadSuccess = {
  __typename?: 'UpdateCreatorReplyToEmailPayloadSuccess'
  creator: Maybe<Creator>
}

export type UpdatePasswordInput = {
  currentPassword: InputMaybe<Scalars['String']>
  newPassword: Scalars['String']
}

export type UpdatePasswordPayload =
  | UpdatePasswordPayloadSuccess
  | InputValidationError
  | WrongCredentialsError
  | CaptchaVerificationFailedError

export type UpdatePasswordPayloadSuccess = {
  __typename?: 'UpdatePasswordPayloadSuccess'
  me: User | Creator
}

export type ConsultationOutputField = {
  __typename?: 'ConsultationOutputField'
  id: Maybe<Scalars['String']>
  price: Maybe<Scalars['String']>
  formattedPrice: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
  timezone: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  durationInMinutes: Maybe<Scalars['String']>
  locationType: Maybe<Scalars['String']>
}

export type ECourseOutputField = {
  __typename?: 'ECourseOutputField'
  id: Maybe<Scalars['String']>
  price: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
}

export type ExclusiveContentOutputField = {
  __typename?: 'ExclusiveContentOutputField'
  id: Maybe<Scalars['String']>
  price: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
}

export type GetZapierTriggerSamplesInput = {
  key: ZapierWebhookEnum
  hookUrl: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
}

export type GetZapierTriggerSamplesPayload =
  | GetZapierTriggerSamplesPayloadSuccess
  | InputValidationError

export type GetZapierTriggerSamplesPayloadSuccess = {
  __typename?: 'GetZapierTriggerSamplesPayloadSuccess'
  samples: Array<Maybe<ZapierOutputField>>
}

export type InvoiceItemOutputFieldType = {
  __typename?: 'InvoiceItemOutputFieldType'
  description: Scalars['String']
  quantity: Scalars['Int']
  amount: Scalars['Int']
  amountFormatted: Scalars['String']
}

export type InvoiceOutputField = {
  __typename?: 'InvoiceOutputField'
  id: Maybe<Scalars['String']>
  number: Scalars['ID']
  dueDate: Scalars['Date']
  dueDateFormatted: Scalars['String']
  lastSentAt: Scalars['Date']
  lastSentAtFormatted: Scalars['String']
  totalPriceFormatted: Scalars['String']
  memo: Maybe<Scalars['String']>
  tax: Maybe<InvoiceTaxOutputFieldType>
  items: Maybe<Array<InvoiceItemOutputFieldType>>
}

export type InvoiceTaxOutputFieldType = {
  __typename?: 'InvoiceTaxOutputFieldType'
  label: Scalars['String']
  percentage: Scalars['Float']
  percentageFormatted: Scalars['String']
}

export type LivestreamOutputField = {
  __typename?: 'LivestreamOutputField'
  id: Maybe<Scalars['String']>
  price: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  timezone: Maybe<Scalars['String']>
  location: Maybe<Scalars['String']>
  durationInMinutes: Maybe<Scalars['String']>
}

export type OneTipOutputFieldType = {
  __typename?: 'OneTipOutputFieldType'
  id: Maybe<Scalars['String']>
  goalTitle: Maybe<Scalars['String']>
  goalDescription: Maybe<Scalars['String']>
}

export type QuestionAnswerOutputField = {
  __typename?: 'QuestionAnswerOutputField'
  question: Maybe<Scalars['String']>
  answer: Maybe<Scalars['String']>
}

export type RecurringTipOutputFieldType = {
  __typename?: 'RecurringTipOutputFieldType'
  id: Maybe<Scalars['String']>
  goalTitle: Maybe<Scalars['String']>
  goalDescription: Maybe<Scalars['String']>
}

export type ShoutoutOutputField = {
  __typename?: 'ShoutoutOutputField'
  id: Maybe<Scalars['String']>
  price: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
}

export type ZapierConsultationOrderOutputField = {
  __typename?: 'ZapierConsultationOrderOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  startDate: Maybe<Scalars['String']>
  startDateFormatted: Maybe<Scalars['String']>
  endDate: Maybe<Scalars['String']>
  formattedEndDate: Maybe<Scalars['String']>
  customerStartDate: Maybe<Scalars['String']>
  customerStartDateFormatted: Maybe<Scalars['String']>
  customerEndDate: Maybe<Scalars['String']>
  customerEndDateFormatted: Maybe<Scalars['String']>
  customerTimeZone: Maybe<Scalars['String']>
  location: Maybe<Scalars['String']>
  consultation: ConsultationOutputField
  questionAnswers: Maybe<Array<Maybe<QuestionAnswerOutputField>>>
}

export type ZapierCreatorOutputField = {
  __typename?: 'ZapierCreatorOutputField'
  email: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  username: Maybe<Scalars['String']>
}

export type ZapierECourseOrderOutputField = {
  __typename?: 'ZapierECourseOrderOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  eCourse: ECourseOutputField
}

export type ZapierExclusiveContentOrderOutputField = {
  __typename?: 'ZapierExclusiveContentOrderOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  customerMessage: Maybe<Scalars['String']>
  exclusiveContent: ExclusiveContentOutputField
}

export type ZapierInvoiceOrderOutputField = {
  __typename?: 'ZapierInvoiceOrderOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  invoice: InvoiceOutputField
}

export type ZapierLivestreamOrderOutputField = {
  __typename?: 'ZapierLivestreamOrderOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  livestream: LivestreamOutputField
}

export type ZapierMailingListOutputField = {
  __typename?: 'ZapierMailingListOutputField'
  id: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  title: Maybe<Scalars['String']>
  additionalInputFields: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['String']>
}

export type ZapierOneTimeTipReceivedOutputField = {
  __typename?: 'ZapierOneTimeTipReceivedOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  customerMessage: Maybe<Scalars['String']>
  tip: OneTipOutputFieldType
}

export type ZapierOutputField =
  | ZapierMailingListOutputField
  | ZapierConsultationOrderOutputField
  | ZapierTransactionConfirmedOutputField
  | ZapierLivestreamOrderOutputField
  | ZapierShoutoutOrderOutputField
  | ZapierExclusiveContentOrderOutputField
  | ZapierOneTimeTipReceivedOutputField
  | ZapierRecurringTipReceivedOutputField
  | ZapierTransactionOutputField
  | ZapierECourseOrderOutputField
  | ZapierInvoiceOrderOutputField

export type ZapierOwnerOutputField = {
  __typename?: 'ZapierOwnerOutputField'
  email: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
}

export type ZapierRecurringTipReceivedOutputField = {
  __typename?: 'ZapierRecurringTipReceivedOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  customerMessage: Maybe<Scalars['String']>
  tip: RecurringTipOutputFieldType
  planInterval: Maybe<Scalars['String']>
  planAmount: Maybe<Scalars['String']>
}

export type ZapierShoutoutOrderOutputField = {
  __typename?: 'ZapierShoutoutOrderOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  customerMessage: Maybe<Scalars['String']>
  occasion: Maybe<Scalars['String']>
  shoutout: ShoutoutOutputField
  questionAnswers: Maybe<Array<Maybe<QuestionAnswerOutputField>>>
}

export type ZapierTransactionConfirmedOutputField = {
  __typename?: 'ZapierTransactionConfirmedOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
  serviceType: Maybe<Scalars['String']>
  serviceId: Maybe<Scalars['String']>
  serviceTitle: Maybe<Scalars['String']>
}

export type ZapierTransactionOutputField = {
  __typename?: 'ZapierTransactionOutputField'
  id: Maybe<Scalars['String']>
  currencyCode: Maybe<Scalars['String']>
  amount: Maybe<Scalars['Float']>
  amountFormatted: Maybe<Scalars['String']>
  netAmount: Maybe<Scalars['Float']>
  netAmountFormatted: Maybe<Scalars['String']>
  applicationFeePercent: Maybe<Scalars['Float']>
  applicationFeeAmount: Maybe<Scalars['Float']>
  platform: Maybe<Scalars['String']>
  saleDiscountAmount: Maybe<Scalars['Float']>
  couponDiscountAmount: Maybe<Scalars['Float']>
  couponCode: Maybe<Scalars['String']>
  totalDiscountAmount: Maybe<Scalars['Float']>
  createdAt: Maybe<Scalars['String']>
  creator: Maybe<ZapierCreatorOutputField>
  owner: Maybe<ZapierOwnerOutputField>
}

export type CreatorZapier = {
  __typename?: 'CreatorZapier'
  apiKey: Scalars['String']
  webhooks: ZapierWebhooks
}

export enum ZapierWebhookEnum {
  NewECourseOrder = 'newECourseOrder',
  NewShoutoutOrder = 'newShoutoutOrder',
  NewConsultationOrder = 'newConsultationOrder',
  InvoicePaid = 'invoicePaid',
  NewExclusiveContentOrder = 'newExclusiveContentOrder',
  NewLiveStreamOrder = 'newLiveStreamOrder',
  NewOneTimeTip = 'newOneTimeTip',
  NewRecurringTip = 'newRecurringTip',
  TransactionConfirmed = 'transactionConfirmed',
  NewMailingListEntry = 'newMailingListEntry',
}

export type UpdateZapierWebhookSubscriptionInput = {
  key: ZapierWebhookEnum
  hookUrl: InputMaybe<Scalars['String']>
  enabled: Scalars['Boolean']
}

export type UpdateZapierWebhookSubscriptionPayload =
  | UpdateZapierWebhookSubscriptionPayloadSuccess
  | InputValidationError

export type UpdateZapierWebhookSubscriptionPayloadSuccess = {
  __typename?: 'UpdateZapierWebhookSubscriptionPayloadSuccess'
  success: Scalars['Boolean']
}

export type CreateAssetInput = {
  /** Media MIME type. */
  mimeType: Scalars['String']
  /** Size of the file to upload, in bytes. */
  fileSize: Scalars['BigInt']
  /** HTTP method to be used, POST for resumable uploads. */
  httpMethod: UploadHttpMethodType
  intent: AssetIntent
}

export type CreateAssetPayload =
  | CreateAssetPayloadSuccess
  | InputValidationError

export type CreateAssetPayloadSuccess = {
  __typename?: 'CreateAssetPayloadSuccess'
  /** Request headers of the media to be uploaded. */
  requestHeaders: Array<AssetSignedUrlRequestHeader>
  /** Signed URL to be used for the upload. */
  signedUrl: Scalars['URL']
  /** The asset object that will keep track of this file upload, processing, and moderation... */
  asset: Asset
}

export type DeleteAssetInput = {
  assetId: Scalars['ID']
}

export type DeleteAssetPayload =
  | DeleteAssetPayloadSuccess
  | NotDeletableError
  | NotFoundError

export type DeleteAssetPayloadSuccess = {
  __typename?: 'DeleteAssetPayloadSuccess'
  assetId: Scalars['ID']
}

export type AssetAudio = {
  __typename?: 'AssetAudio'
  url: Scalars['URL']
  playbackId: Scalars['String']
}

export type AssetDocument = {
  __typename?: 'AssetDocument'
  url: Scalars['URL']
}

export type AssetFacade = {
  __typename?: 'AssetFacade'
  video: Maybe<AssetVideo>
  audio: Maybe<AssetAudio>
  document: Maybe<AssetDocument>
  image: Maybe<ImageField>
}

export type AssetFile = {
  __typename?: 'AssetFile'
  name: Scalars['String']
  bucket: Scalars['String']
  size: Scalars['BigInt']
  contentType: Scalars['String']
}

export enum AssetIntent {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type AssetSignedUrlRequestHeader = {
  __typename?: 'AssetSignedUrlRequestHeader'
  name: Scalars['String']
  value: Scalars['String']
}

export enum AssetState {
  PendingUpload = 'PENDING_UPLOAD',
  Uploaded = 'UPLOADED',
  UploadedPendingProcessing = 'UPLOADED_PENDING_PROCESSING',
  UploadedProcessing = 'UPLOADED_PROCESSING',
  UploadedProcessingFailed = 'UPLOADED_PROCESSING_FAILED',
  UploadedReady = 'UPLOADED_READY',
  Deleted = 'DELETED',
}

export type AssetVideo = {
  __typename?: 'AssetVideo'
  thumbnailUrl: Scalars['URL']
  videoUrl: Scalars['URL']
  playbackId: Scalars['String']
  /** duration in seconds */
  duration: Maybe<Scalars['Float']>
}

export type DummyAsset = {
  __typename?: 'DummyAsset'
  id: Scalars['ID']
  pusherChannel: Maybe<Scalars['String']>
  file: AssetFile
  state: Array<AssetState>
  facades: Maybe<AssetFacade>
}

export type DummyAssets = {
  __typename?: 'DummyAssets'
  EXCLUSIVE_CONTENT_IMAGE: DummyAsset
  ECOURSE_IMAGE: DummyAsset
  CONSULTATION_IMAGE: DummyAsset
  SHOUTOUT_IMAGE: DummyAsset
  LIVESTREAM_IMAGE: DummyAsset
  MEMBERSHIP_IMAGE: DummyAsset
}

export type File = {
  path: Scalars['String']
  url: Scalars['URL']
}

export type ImageField = File & {
  __typename?: 'ImageField'
  path: Scalars['String']
  url: Scalars['URL']
  responsive: ResponsiveImageField
}

export type ImageFieldUrlArgs = {
  input: InputMaybe<ImageUrlInput>
}

export type ImageFieldResponsiveArgs = {
  input: InputMaybe<ImageResponsiveInput>
}

export type ImageRenderParams = {
  width: InputMaybe<Scalars['Int']>
  height: InputMaybe<Scalars['Int']>
  quality: InputMaybe<Scalars['Float']>
}

export type ImageResponsiveInput = {
  renderParams: InputMaybe<ImageRenderParams>
  srcSetParams: InputMaybe<ImageSrcSetParams>
}

export type ImageSrcSetParams = {
  widths: Array<Scalars['Int']>
}

export type ImageUrlInput = {
  renderParams: InputMaybe<ImageRenderParams>
}

export type ResponsiveImageField = {
  __typename?: 'ResponsiveImageField'
  src: Scalars['String']
  srcSet: Maybe<Scalars['String']>
}

export enum UploadHttpMethodType {
  Put = 'PUT',
  Post = 'POST',
}

/** Status of asset moderation review */
export enum AssetModerationStatus {
  Safe = 'SAFE',
  Ignored = 'IGNORED',
  Removed = 'REMOVED',
}

export type UpdateAssetModerationInput = {
  assetId: Scalars['ID']
  status: AssetModerationStatus
  reviewedBy: Scalars['String']
}

export type UpdateAssetModerationPayload =
  | UpdateAssetModerationPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateAssetModerationPayloadSuccess = {
  __typename?: 'UpdateAssetModerationPayloadSuccess'
  success: Scalars['Boolean']
}

export type AddItemToSocialFeedBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  title: Scalars['String']
  url: InputMaybe<Scalars['String']>
  coverAsset: Scalars['ID']
  originalCoverUrl: Scalars['String']
  postId: Scalars['String']
}

export type AddItemToSocialFeedBlockPayloadSuccess = {
  __typename?: 'AddItemToSocialFeedBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedItem: SocialFeedBlockItem
}

export type AddItemToSocialFeedBlockFromPlanolyInput = {
  planolyId: Scalars['Int']
  socialSetId: Scalars['Int']
  title: Scalars['String']
  url: InputMaybe<Scalars['String']>
  imageUrl: Scalars['String']
  postId: Scalars['String']
  platform: SocialFeedBlockPlatformEnum
}

export type AddItemToSocialFeedBlockFromPlanolyPayload =
  | AddItemToSocialFeedBlockFromPlanolyPayloadSuccess
  | NotFoundError
  | InputValidationError

export type AddItemToSocialFeedBlockFromPlanolyPayloadSuccess = {
  __typename?: 'AddItemToSocialFeedBlockFromPlanolyPayloadSuccess'
  planolyId: Scalars['Int']
}

export type AddShopifyProductBlockInput = {
  creatorLinkId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  collectionId: InputMaybe<Scalars['String']>
  productIds: InputMaybe<Array<Scalars['String']>>
  displayProductDescription: Scalars['Boolean']
  cardStyle: ShopifyBlockCardStyleEnum
}

export type AddShopifyBlockPayload =
  | AddShopifyProductBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddShopifyProductBlockPayloadSuccess = {
  __typename?: 'AddShopifyProductBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: ShopifyProductBlock
}

export type AddSocialFeedBlockInput = {
  creatorLinkId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  cardStyle: InputMaybe<SocialFeedBlockCardStyleEnum>
  platform: SocialFeedBlockPlatformEnum
}

export type AddSocialFeedBlockPayloadSuccess = {
  __typename?: 'AddSocialFeedBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: SocialFeedBlock
}

export type AddThumbnailsBlockInput = {
  creatorLinkId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  cardStyle: InputMaybe<ThumbnailsBlockCardStyleEnum>
}

export type AddThumbnailsBlockPayloadSuccess = {
  __typename?: 'AddThumbnailsBlockPayloadSuccess'
  creatorLink: CreatorLink
  addedBlock: ThumbnailsBlock
}

export type DeleteItemFromCarouselBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  cardId: Scalars['ID']
}

export type DeleteItemFromCarouselBlockPayload =
  | DeleteItemFromCarouselBlockPayloadSuccess
  | NotFoundError

export type DeleteItemFromCarouselBlockPayloadSuccess = {
  __typename?: 'DeleteItemFromCarouselBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type DeleteLinkBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
}

export type DeleteLinkBlockPayload =
  | DeleteLinkBlockPayloadSuccess
  | NotDeletableError
  | NotFoundError

export type DeleteLinkBlockPayloadSuccess = {
  __typename?: 'DeleteLinkBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type DeleteSocialFeedBlockItemInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  itemId: Scalars['ID']
}

export type DeleteSocialFeedBlockItemPayload =
  | DeleteSocialFeedBlockItemPayloadSuccess
  | NotFoundError

export type DeleteSocialFeedBlockItemPayloadSuccess = {
  __typename?: 'DeleteSocialFeedBlockItemPayloadSuccess'
  creatorLink: CreatorLink
}

export type GetSocialFeedBlocksByPlanolyCreatorInput = {
  planolyId: Scalars['Int']
  socialSetId: Scalars['Int']
}

export type GetSocialFeedBlocksByPlanolyCreatorPayload =
  | GetSocialFeedBlocksByPlanolyCreatorPayloadSuccess
  | NotFoundError

export type GetSocialFeedBlocksByPlanolyCreatorPayloadSuccess = {
  __typename?: 'GetSocialFeedBlocksByPlanolyCreatorPayloadSuccess'
  tiktokFeedBlock: Maybe<SocialFeedBlock>
  instagramFeedBlock: Maybe<SocialFeedBlock>
}

export type ReassignPurchasedLinkCustomDomainNameInput = {
  creatorLinkId: Scalars['ID']
}

export type ReassignPurchasedLinkCustomDomainNamePayload =
  | ReassignPurchasedLinkCustomDomainNamePayloadSuccess
  | NotFoundError
  | InputValidationError

export type ReassignPurchasedLinkCustomDomainNamePayloadSuccess = {
  __typename?: 'ReassignPurchasedLinkCustomDomainNamePayloadSuccess'
  success: Scalars['Boolean']
}

export type SetPremiumTemplateInput = {
  creatorLinkId: Scalars['ID']
  name: Scalars['String']
  theme: Scalars['String']
}

export type SetPremiumTemplatePayload =
  | SetPremiumTemplatePayloadSuccess
  | NotFoundError
  | InputValidationError

export type SetPremiumTemplatePayloadSuccess = {
  __typename?: 'SetPremiumTemplatePayloadSuccess'
  creatorLink: CreatorLink
}

export type SortSocialFeedBlockItemsInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  sortedItemIds: InputMaybe<Array<Scalars['ID']>>
}

export type SortSocialFeedBlockItemsPayload =
  | SortSocialFeedBlockItemsPayloadSuccess
  | InputValidationError
  | NotFoundError

export type SortSocialFeedBlockItemsPayloadSuccess = {
  __typename?: 'SortSocialFeedBlockItemsPayloadSuccess'
  creatorLink: CreatorLink
}

export enum FontFamilyEnum {
  Lato = 'LATO',
  Inter = 'INTER',
  Poppins = 'POPPINS',
  Montserrat = 'MONTSERRAT',
  Alegreya = 'ALEGREYA',
  AbrilFatface = 'ABRIL_FATFACE',
  Anton = 'ANTON',
  PtSerif = 'PT_SERIF',
  ConcertOne = 'CONCERT_ONE',
  PlayfairDisplay = 'PLAYFAIR_DISPLAY',
  Pacifico = 'PACIFICO',
  Kalam = 'KALAM',
  Rakkas = 'RAKKAS',
  DancingScript = 'DANCING_SCRIPT',
  Biorhyme = 'BIORHYME',
  IbmPlexSans = 'IBM_PLEX_SANS',
  Roboto = 'ROBOTO',
  Nunito = 'NUNITO',
  AmaticSc = 'AMATIC_SC',
  Avro = 'AVRO',
  Inconsolata = 'INCONSOLATA',
  PermanentMarker = 'PERMANENT_MARKER',
  Ranchers = 'RANCHERS',
  SpaceGrotesk = 'SPACE_GROTESK',
  DmSans = 'DM_SANS',
  Cormorant = 'CORMORANT',
  Archivo = 'ARCHIVO',
}

export type LinkBackgroundInput = {
  type: LinkBackgroundStyleEnum
  color: InputMaybe<Scalars['String']>
  imageAsset: InputMaybe<Scalars['ID']>
  transparency: InputMaybe<Scalars['Float']>
}

export enum LinkBackgroundStyleEnum {
  Solid = 'SOLID',
  Gradient = 'GRADIENT',
  CoverPhoto = 'COVER_PHOTO',
  BackgroundPhoto = 'BACKGROUND_PHOTO',
}

export enum LinkBlockAnimationTypesEnum {
  None = 'NONE',
  Bounce = 'BOUNCE',
  Blink = 'BLINK',
  Flash = 'FLASH',
  Pulse = 'PULSE',
  ShakeY = 'SHAKE_Y',
  ShakeX = 'SHAKE_X',
  Tada = 'TADA',
  HeartBeat = 'HEART_BEAT',
}

export type LinkBlockStylesInput = {
  transparency: InputMaybe<Scalars['Float']>
  buttonType: LinkButtonTypeEnum
  buttonShape: LinkButtonShapeEnum
}

export enum LinkButtonShapeEnum {
  Square = 'SQUARE',
  RoundedSlight = 'ROUNDED_SLIGHT',
  Rounded = 'ROUNDED',
}

export enum LinkButtonTypeEnum {
  Fill = 'FILL',
  Outline = 'OUTLINE',
  Shadow = 'SHADOW',
}

export type LinkColorsInput = {
  text: InputMaybe<Scalars['String']>
  buttonText: InputMaybe<Scalars['String']>
  innerButtonText: InputMaybe<Scalars['String']>
  tipButtonText: InputMaybe<Scalars['String']>
  buttonBackground: InputMaybe<Scalars['String']>
  innerButtonBackground: InputMaybe<Scalars['String']>
  tipButtonBackground: InputMaybe<Scalars['String']>
}

export enum SocialIconEnum {
  Shop = 'SHOP',
  Etsy = 'ETSY',
  Email = 'EMAIL',
  Tidal = 'TIDAL',
  Deezer = 'DEEZER',
  Tiktok = 'TIKTOK',
  Twitch = 'TWITCH',
  Napster = 'NAPSTER',
  Website = 'WEBSITE',
  Spotify = 'SPOTIFY',
  Youtube = 'YOUTUBE',
  Twitter = 'TWITTER',
  Groover = 'GROOVER',
  Patreon = 'PATREON',
  Bandcamp = 'BANDCAMP',
  Facebook = 'FACEBOOK',
  Linkedin = 'LINKEDIN',
  Snapchat = 'SNAPCHAT',
  Pinterest = 'PINTEREST',
  Instagram = 'INSTAGRAM',
  Soundcloud = 'SOUNDCLOUD',
  AppleMusic = 'APPLE_MUSIC',
  AmazonMusic = 'AMAZON_MUSIC',
  ApplePodcast = 'APPLE_PODCAST',
  Tumblr = 'TUMBLR',
  Threads = 'THREADS',
}

export type SocialIconInput = {
  url: Scalars['URL']
  platform: SocialIconEnum
}

export type ThumbnailsBlockItem = {
  __typename?: 'ThumbnailsBlockItem'
  title: Scalars['String']
  coverAsset: Asset
  url: Maybe<Scalars['String']>
}

export type UnsetPremiumTemplateInput = {
  creatorLinkId: Scalars['ID']
}

export type UnsetPremiumTemplatePayload =
  | UnsetPremiumTemplatePayloadSuccess
  | NotFoundError
  | InputValidationError

export type UnsetPremiumTemplatePayloadSuccess = {
  __typename?: 'UnsetPremiumTemplatePayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateBrandingVisibilityInput = {
  creatorLinkId: Scalars['ID']
  displayBranding: Scalars['Boolean']
}

export type UpdateBrandingVisibilityPayload =
  | UpdateBrandingVisibilityPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateBrandingVisibilityPayloadSuccess = {
  __typename?: 'UpdateBrandingVisibilityPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateLinkDesignInput = {
  creatorLinkId: Scalars['ID']
  templateId: InputMaybe<Scalars['String']>
  background: InputMaybe<LinkBackgroundInput>
  colors: InputMaybe<LinkColorsInput>
  blockStyles: InputMaybe<LinkBlockStylesInput>
  font: InputMaybe<FontFamilyEnum>
  fonts: InputMaybe<UpdateLinkDesignFontsInput>
}

export type UpdateLinkDesignPayload =
  | UpdateLinkDesignPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateLinkDesignPayloadSuccess = {
  __typename?: 'UpdateLinkDesignPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateShopifyProductBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  collectionId: InputMaybe<Scalars['String']>
  productIds: InputMaybe<Array<Scalars['String']>>
  title: InputMaybe<Scalars['String']>
  displayProductDescription: Scalars['Boolean']
  cardStyle: ShopifyBlockCardStyleEnum
}

export type UpdateShopifyBlockPayload =
  | UpdateShopifyProductBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateShopifyProductBlockPayloadSuccess = {
  __typename?: 'UpdateShopifyProductBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateSocialFeedBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  cardStyle: InputMaybe<SocialFeedBlockCardStyleEnum>
  sortedItemIds: InputMaybe<Array<Scalars['ID']>>
}

export type UpdateSocialFeedBlockPayloadSuccess = {
  __typename?: 'UpdateSocialFeedBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateSocialFeedBlockItemInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  itemId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  url: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
}

export type UpdateSocialFeedBlockItemPayloadSuccess = {
  __typename?: 'UpdateSocialFeedBlockItemPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpdateThumbnailsBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  cardStyle: InputMaybe<ThumbnailsBlockCardStyleEnum>
  items: InputMaybe<Array<UpdateThumbnailsBlockItemInput>>
}

export type UpdateThumbnailsBlockItemInput = {
  title: Scalars['String']
  coverAsset: Scalars['ID']
  url: InputMaybe<Scalars['String']>
}

export type UpdateThumbnailsBlockPayloadSuccess = {
  __typename?: 'UpdateThumbnailsBlockPayloadSuccess'
  creatorLink: CreatorLink
}

export type UpsertSocialIconsInput = {
  creatorLinkId: Scalars['ID']
  blockId: InputMaybe<Scalars['ID']>
  links: Array<SocialIconInput>
}

export type UpsertSocialIconsPayload =
  | UpsertSocialIconsPayloadSuccess
  | NotFoundError

export type UpsertSocialIconsPayloadSuccess = {
  __typename?: 'UpsertSocialIconsPayloadSuccess'
  creatorLink: CreatorLink
}

export type AddConsultationInput = {
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  timezone: Scalars['String']
  platform: InputMaybe<ConsultationPlatform>
  scheduleLimit: InputMaybe<Scalars['Int']>
  availabilityTimeBuffer: InputMaybe<ConsultationAvailabilityTimeBufferInput>
  price: MoneyInput
  intervalStep: InputMaybe<Scalars['Int']>
  scheduleRange: InputMaybe<ConsultationScheduleRangeInput>
  duration: InputMaybe<Scalars['Int']>
  coverAsset: InputMaybe<Scalars['ID']>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  slug: InputMaybe<Scalars['String']>
  status: InputMaybe<ConsultationStatus>
  availabilities: InputMaybe<Array<InputMaybe<ConsultationAvailabilityInput>>>
}

export type AddConsultationPayload =
  | AddConsultationPayloadSuccess
  | InputValidationError

export type AddConsultationPayloadSuccess = {
  __typename?: 'AddConsultationPayloadSuccess'
  consultation: Consultation
}

export type AddConsultationOrderInput = {
  consultationId: Scalars['ID']
  paymentIntentId: Scalars['String']
  userInformation: InputMaybe<UserCheckoutInput>
  startDate: Scalars['DateTime']
  timezone: Scalars['String']
  message: InputMaybe<Scalars['String']>
  couponCode: InputMaybe<Scalars['String']>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddConsultationOrderPayload =
  | AddConsultationOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorNoConnectedPaymentError
  | StripeClientRequestError
  | CreatorUnavailableError
  | ConsultationUnavailableError

export type AddConsultationOrderPayloadSuccess = {
  __typename?: 'AddConsultationOrderPayloadSuccess'
  paymentIntent: Maybe<PaymentIntent>
  authPayload: Maybe<SignupUserPayloadSuccess>
  consultationOrderId: Scalars['ID']
}

export type AddFreeConsultationOrderInput = {
  consultationId: Scalars['ID']
  userInformation: InputMaybe<UserCheckoutInput>
  startDate: Scalars['DateTime']
  timezone: Scalars['String']
  message: InputMaybe<Scalars['String']>
  couponCode: InputMaybe<Scalars['String']>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddFreeConsultationOrderPayload =
  | AddFreeConsultationOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorUnavailableError
  | ConsultationUnavailableError
  | ConsultationIsMonetizedError

export type AddFreeConsultationOrderPayloadSuccess = {
  __typename?: 'AddFreeConsultationOrderPayloadSuccess'
  authPayload: Maybe<SignupUserPayloadSuccess>
  consultationOrderId: Scalars['ID']
}

export type AddPaypalConsultationOrderInput = {
  consultationId: Scalars['ID']
  userInformation: InputMaybe<UserCheckoutInput>
  startDate: Scalars['DateTime']
  timezone: Scalars['String']
  message: InputMaybe<Scalars['String']>
  couponCode: InputMaybe<Scalars['String']>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
  paypalClientMetadataId: Scalars['String']
}

export type AddPaypalConsultationOrderPayload =
  | AddPaypalConsultationOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorNoConnectedPaymentError
  | CreatorUnavailableError
  | ConsultationUnavailableError
  | PaypalClientRequestError

export type AddPaypalConsultationOrderPayloadSuccess = {
  __typename?: 'AddPaypalConsultationOrderPayloadSuccess'
  paypalOrder: Maybe<PaypalOrder>
  authPayload: Maybe<SignupUserPayloadSuccess>
  consultationOrderId: Scalars['ID']
}

export type CancelConsultationOrderInput = {
  consultationOrderId: Scalars['ID']
}

export type CancelConsultationOrderPayload =
  | CancelConsultationOrderPayloadSuccess
  | NotFoundError
  | UpdateOrderStatusError
  | StripeClientRequestError
  | PaypalClientRequestError

export type CancelConsultationOrderPayloadSuccess = {
  __typename?: 'CancelConsultationOrderPayloadSuccess'
  consultationOrder: ConsultationOrder
}

export type DeleteConsultationInput = {
  consultationId: Scalars['ID']
}

export type DeleteConsultationPayload =
  | DeleteConsultationPayloadSuccess
  | DeleteConsultationPayloadUnpublished
  | NotFoundError

export type DeleteConsultationPayloadSuccess = {
  __typename?: 'DeleteConsultationPayloadSuccess'
  deletedConsultationId: Scalars['ID']
}

export type DeleteConsultationPayloadUnpublished = {
  __typename?: 'DeleteConsultationPayloadUnpublished'
  consultation: Maybe<Consultation>
}

export type PublishConsultationInput = {
  consultationId: Scalars['ID']
}

export type PublishConsultationPayload =
  | PublishConsultationPayloadSuccess
  | UnpublishableContentError
  | NotFoundError

export type PublishConsultationPayloadSuccess = {
  __typename?: 'PublishConsultationPayloadSuccess'
  consultation: Maybe<Consultation>
}

export type RescheduleConsultationOrderInput = {
  consultationOrderId: Scalars['ID']
  startDate: Scalars['DateTime']
  timezone: Scalars['String']
}

export type RescheduleConsultationOrderPayload =
  | RescheduleConsultationOrderPayloadSuccess
  | NotFoundError
  | ConsultationUnavailableError

export type RescheduleConsultationOrderPayloadSuccess = {
  __typename?: 'RescheduleConsultationOrderPayloadSuccess'
  consultationOrder: ConsultationOrder
}

export type ConsultationAvailabilityInput = {
  day: InputMaybe<ConsultationAvailabilityDays>
  type: ConsultationAvailabilityTypes
  date: InputMaybe<Scalars['Date']>
  intervals: Array<ConsultationAvailabilityIntervalInput>
}

export type ConsultationAvailabilityIntervalInput = {
  startTime: InputMaybe<Scalars['Int']>
  endTime: InputMaybe<Scalars['Int']>
}

export type ConsultationAvailabilityTimeBufferInput = {
  before: InputMaybe<Scalars['Int']>
  after: InputMaybe<Scalars['Int']>
}

export type ConsultationScheduleRangeInput = {
  type: ConsultationScheduleRangeTypes
  day: InputMaybe<Scalars['Int']>
  from: InputMaybe<Scalars['Date']>
  to: InputMaybe<Scalars['Date']>
}

export type UnpublishConsultationInput = {
  consultationId: Scalars['ID']
}

export type UnpublishConsultationPayload =
  | UnpublishConsultationPayloadSuccess
  | NotFoundError

export type UnpublishConsultationPayloadSuccess = {
  __typename?: 'UnpublishConsultationPayloadSuccess'
  consultation: Maybe<Consultation>
}

export type UpdateConsultationInput = {
  ratingConfig: InputMaybe<RatingConfigInput>
  consultationId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  timezone: InputMaybe<Scalars['String']>
  platform: InputMaybe<ConsultationPlatform>
  availabilityTimeBuffer: InputMaybe<ConsultationAvailabilityTimeBufferInput>
  intervalStep: InputMaybe<Scalars['Int']>
  scheduleRange: InputMaybe<ConsultationScheduleRangeInput>
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
  price: InputMaybe<MoneyInput>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  scheduleLimit: InputMaybe<Scalars['Int']>
  duration: InputMaybe<Scalars['Int']>
  slug: InputMaybe<Scalars['String']>
  availabilities: InputMaybe<Array<InputMaybe<ConsultationAvailabilityInput>>>
  status: InputMaybe<ConsultationStatusWithoutDraft>
}

export type UpdateConsultationPayload =
  | UpdateConsultationPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateConsultationPayloadSuccess = {
  __typename?: 'UpdateConsultationPayloadSuccess'
  consultation: Maybe<Consultation>
}

export type UpdateConsultationAvailabilitiesInput = {
  consultationId: Scalars['ID']
  duration: Scalars['Int']
  availabilities: Array<InputMaybe<ConsultationAvailabilityInput>>
  timezone: InputMaybe<Scalars['String']>
  scheduleLimit: InputMaybe<Scalars['Int']>
  availabilityTimeBuffer: InputMaybe<ConsultationAvailabilityTimeBufferInput>
  intervalStep: InputMaybe<Scalars['Int']>
  scheduleRange: InputMaybe<ConsultationScheduleRangeInput>
}

export type UpdateConsultationAvailabilitiesPayload =
  | UpdateConsultationAvailabilitiesPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateConsultationAvailabilitiesPayloadSuccess = {
  __typename?: 'UpdateConsultationAvailabilitiesPayloadSuccess'
  consultation: Maybe<Consultation>
}

export type UpdateCustomConsultationQuestionsInput = {
  consultationId: Scalars['ID']
  questions: Array<InputMaybe<CustomQuestionInput>>
}

export type UpdateCustomConsultationQuestionsPayload =
  | UpdateCustomConsultationQuestionsPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateCustomConsultationQuestionsPayloadSuccess = {
  __typename?: 'UpdateCustomConsultationQuestionsPayloadSuccess'
  consultation: Maybe<Consultation>
}

export type AddEmailBroadcastInput = {
  subject: Scalars['String']
  tipTapContent: Scalars['String']
  status: EmailBroadcastStatus
  recipientFilters: Array<EmailRecipientFilterInput>
}

export type AddEmailBroadcastPayload =
  | AddEmailBroadcastPayloadSuccess
  | InputValidationError

export type AddEmailBroadcastPayloadSuccess = {
  __typename?: 'AddEmailBroadcastPayloadSuccess'
  crmEmailBroadcast: CrmEmailBroadcast
}

export type AddEmailFlowInput = {
  name: Scalars['String']
  enrollExisting: Scalars['Boolean']
  steps: Array<CrmEmailFlowStepInput>
  enrollmentTriggers: Array<EmailEnrollmentTriggerInput>
  unenrollmentTriggers: Array<EmailUnenrollmentTriggerInput>
  status: EmailFlowStatus
}

export type AddEmailFlowPayload =
  | AddEmailFlowPayloadSuccess
  | InputValidationError

export type AddEmailFlowPayloadSuccess = {
  __typename?: 'AddEmailFlowPayloadSuccess'
  crmEmailFlow: CrmEmailFlow
}

export type CrmCustomerFilter = {
  earliestTransactionRange: InputMaybe<DateRange>
  latestTransactionRange: InputMaybe<DateRange>
  transactionCount: InputMaybe<IntRange>
  totalSpent: InputMaybe<IntRange>
  purchasedProducts: InputMaybe<CrmProductFilter>
  abandonedCartProducts: InputMaybe<CrmProductFilter>
  nameOrEmail: InputMaybe<Scalars['String']>
}

export enum CrmCustomerSortField {
  Email = 'EMAIL',
  Name = 'NAME',
  SpendTotal = 'SPEND_TOTAL',
  TransactionCount = 'TRANSACTION_COUNT',
  EarliestTransactionDate = 'EARLIEST_TRANSACTION_DATE',
  LatestTransactionDate = 'LATEST_TRANSACTION_DATE',
}

export type DeleteEmailBroadcastInput = {
  emailFlowId: Scalars['ID']
}

export type DeleteEmailBroadcastPayload =
  | DeleteEmailBroadcastPayloadSuccess
  | NotFoundError

export type DeleteEmailBroadcastPayloadSuccess = {
  __typename?: 'DeleteEmailBroadcastPayloadSuccess'
  emailFlowId: Scalars['ID']
}

export type DeleteEmailFlowInput = {
  emailFlowId: Scalars['ID']
}

export type DeleteEmailFlowPayload =
  | DeleteEmailFlowPayloadSuccess
  | NotFoundError

export type DeleteEmailFlowPayloadSuccess = {
  __typename?: 'DeleteEmailFlowPayloadSuccess'
  emailFlowId: Scalars['ID']
}

export type SendTestCrmEmailInput = {
  subject: Scalars['String']
  tipTapContent: Scalars['String']
}

export type SendTestCrmEmailPayload =
  | SendTestCrmEmailPayloadSuccess
  | InputValidationError

export type SendTestCrmEmailPayloadSuccess = {
  __typename?: 'SendTestCrmEmailPayloadSuccess'
  success: Scalars['Boolean']
}

export type CrmCustomer = Node & {
  __typename?: 'CrmCustomer'
  id: Scalars['ID']
  email: Scalars['String']
  name: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  totalSpent: Money
  transactionCount: Scalars['Int']
  earliestTransactionDate: Maybe<Scalars['DateTime']>
  latestTransactionDate: Maybe<Scalars['DateTime']>
  subscribedAt: Maybe<Scalars['DateTime']>
  unsubscribedAt: Maybe<Scalars['DateTime']>
  emailFlowEnrollments: Maybe<Array<EmailFlowEnrollment>>
  orders: OrderConnection
  subscriptionStatus: CrmCustomerSubscriptionStatusKind
}

export type CrmCustomerOrdersArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<OrderSortField>
  filterByField: InputMaybe<OrderFilterByField>
}

export type CrmCustomerEdge = {
  __typename?: 'CrmCustomerEdge'
  node: CrmCustomer
  cursor: Scalars['String']
}

export type CrmCustomersConnection = {
  __typename?: 'CrmCustomersConnection'
  edges: Array<CrmCustomerEdge>
  nodes: Array<CrmCustomer>
  pageInfo: PageInfo
}

export type CrmCustomersExport = {
  __typename?: 'CrmCustomersExport'
  customers: Array<CrmCustomer>
  limitExceeded: Scalars['Boolean']
}

export type CrmEmailBroadcast = Node & {
  __typename?: 'CrmEmailBroadcast'
  id: Scalars['ID']
  subject: Scalars['String']
  tipTapContent: Scalars['String']
  stats: CrmEmailStats
  status: EmailBroadcastStatus
  recipientFilters: Array<EmailEnrollmentTrigger>
}

export type CrmEmailBroadcastConnection = {
  __typename?: 'CrmEmailBroadcastConnection'
  edges: Array<CrmEmailBroadcastEdge>
  nodes: Array<CrmEmailBroadcast>
  pageInfo: PageInfo
}

export type CrmEmailFlow = Node & {
  __typename?: 'CrmEmailFlow'
  id: Scalars['ID']
  name: Scalars['String']
  enrollExisting: Scalars['Boolean']
  steps: Array<CrmEmailFlowStep>
  status: EmailFlowStatus
  enrollmentTriggers: Array<EmailEnrollmentTrigger>
  unenrollmentTriggers: Array<EmailUnenrollmentTrigger>
  recipientCount: Scalars['Int']
}

export type CrmEmailFlowConnection = {
  __typename?: 'CrmEmailFlowConnection'
  edges: Array<CrmEmailFlowEdge>
  nodes: Array<CrmEmailFlow>
  pageInfo: PageInfo
}

export type CrmEmailFlowStepUpdateInput = {
  id: InputMaybe<Scalars['ID']>
  subject: Scalars['String']
  tipTapContent: Scalars['String']
  delayMinutes: Scalars['Int']
}

export type CrmEmailSubscriptionStatus = {
  __typename?: 'CrmEmailSubscriptionStatus'
  subscribedAt: Maybe<Scalars['DateTime']>
  unsubscribedAt: Maybe<Scalars['DateTime']>
  creatorUsername: Maybe<Scalars['String']>
}

export type EmailEnrollmentTriggerInput = {
  kind: EmailEnrollmentTriggerKind
  productIds: Array<Scalars['ID']>
}

export type EmailFlowEnrollment = {
  __typename?: 'emailFlowEnrollment'
  emailFlowId: Scalars['String']
  enrolledAt: Scalars['DateTime']
  unenrolledAt: Maybe<Scalars['DateTime']>
  completedAt: Maybe<Scalars['DateTime']>
}

export enum EmailFlowStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type EmailUnenrollmentTriggerInput = {
  kind: EmailUnenrollmentTriggerKind
  productIds: Array<Scalars['ID']>
}

export type UpdateCrmCustomerSubscriptionStatusInput = {
  crmCustomerId: Scalars['ID']
  unsubscribe: Scalars['Boolean']
}

export type UpdateCrmCustomerSubscriptionStatusPayload =
  | UpdateCrmCustomerSubscriptionStatusPayloadSuccess
  | NotFoundError

export type UpdateCrmCustomerSubscriptionStatusPayloadSuccess = {
  __typename?: 'UpdateCrmCustomerSubscriptionStatusPayloadSuccess'
  crmCustomer: CrmCustomer
}

export type UpdateDraftEmailFlowInput = {
  id: Scalars['ID']
  name: Scalars['String']
  enrollExisting: Scalars['Boolean']
  steps: Array<CrmEmailFlowStepUpdateInput>
  enrollmentTriggers: Array<EmailEnrollmentTriggerInput>
  unenrollmentTriggers: Array<EmailUnenrollmentTriggerInput>
  status: EmailFlowStatus
}

export type UpdateDraftEmailFlowPayload =
  | UpdateDraftEmailFlowPayloadSuccess
  | NotFoundError
  | NotEditableError
  | InputValidationError

export type UpdateDraftEmailFlowPayloadSuccess = {
  __typename?: 'UpdateDraftEmailFlowPayloadSuccess'
  crmEmailFlow: CrmEmailFlow
}

export type UpdateEmailBroadcastInput = {
  id: Scalars['ID']
  subject: Scalars['String']
  tipTapContent: Scalars['String']
  status: EmailBroadcastStatus
  recipientFilters: Array<EmailRecipientFilterInput>
}

export type UpdateEmailBroadcastPayload =
  | UpdateEmailBroadcastPayloadSuccess
  | InputValidationError
  | NotFoundError
  | NotEditableError

export type UpdateEmailBroadcastPayloadSuccess = {
  __typename?: 'UpdateEmailBroadcastPayloadSuccess'
  crmEmailBroadcast: CrmEmailBroadcast
}

export type UpdatePublishedEmailFlowInput = {
  id: Scalars['ID']
  name: Scalars['String']
  steps: Array<CrmEmailFlowStepUpdateInput>
  status: EmailFlowStatus
}

export type UpdatePublishedEmailFlowPayload =
  | UpdatePublishedEmailFlowPayloadSuccess
  | NotFoundError
  | NotEditableError
  | InputValidationError

export type UpdatePublishedEmailFlowPayloadSuccess = {
  __typename?: 'UpdatePublishedEmailFlowPayloadSuccess'
  crmEmailFlow: CrmEmailFlow
}

export type AddECourseInput = {
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  slug: InputMaybe<Scalars['String']>
  price: MoneyInput
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  status: InputMaybe<ECourseStatusWithoutDraft>
  trailer: InputMaybe<Array<InputMaybe<ECourseLessonFileInput>>>
  lessons: InputMaybe<Array<InputMaybe<ECourseLessonInput>>>
}

export type AddECoursePayloadSuccess = {
  __typename?: 'AddECoursePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type AddECourseLessonInput = {
  eCourseId: Scalars['ID']
  title: Scalars['String']
  description: InputMaybe<Scalars['String']>
}

export type AddECourseLessonPayloadSuccess = {
  __typename?: 'AddECourseLessonPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type AddECourseLessonFileInput = {
  eCourseId: Scalars['ID']
  eCourseLessonId: Scalars['ID']
  fileAsset: Scalars['ID']
  title: Scalars['String']
  description: InputMaybe<Scalars['String']>
}

export type AddECourseLessonFilePayloadSuccess = {
  __typename?: 'AddECourseLessonFilePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type AddECourseOrderInput = {
  eCourseId: Scalars['ID']
  couponCode: InputMaybe<Scalars['String']>
  paymentIntentId: Scalars['String']
  userInformation: InputMaybe<UserCheckoutInput>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddECourseOrderPayloadSuccess = {
  __typename?: 'AddECourseOrderPayloadSuccess'
  eCourseOrderId: Maybe<Scalars['ID']>
  paymentIntent: Maybe<PaymentIntent>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddECourseTrailerInput = {
  eCourseId: Scalars['ID']
  fileAsset: Scalars['ID']
  title: Scalars['String']
  description: InputMaybe<Scalars['String']>
}

export type AddECourseTrailerPayloadSuccess = {
  __typename?: 'AddECourseTrailerPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type AddFreeECourseOrderInput = {
  eCourseId: Scalars['ID']
  couponCode: InputMaybe<Scalars['String']>
  userInformation: InputMaybe<UserCheckoutInput>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddFreeECourseOrderPayloadSuccess = {
  __typename?: 'AddFreeECourseOrderPayloadSuccess'
  eCourseOrderId: Maybe<Scalars['ID']>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddPaypalECourseOrderInput = {
  eCourseId: Scalars['ID']
  couponCode: InputMaybe<Scalars['String']>
  paypalClientMetadataId: Scalars['String']
  userInformation: InputMaybe<UserCheckoutInput>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddPaypalECourseOrderPayloadSuccess = {
  __typename?: 'AddPaypalECourseOrderPayloadSuccess'
  eCourseOrderId: Maybe<Scalars['ID']>
  paypalOrder: Maybe<PaypalOrder>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type DeleteECourseInput = {
  eCourseId: Scalars['ID']
}

export type DeleteECoursePayloadSuccess = {
  __typename?: 'DeleteECoursePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type DeleteECourseLessonInput = {
  eCourseId: Scalars['ID']
  eCourseLessonId: Scalars['ID']
}

export type DeleteECourseLessonPayloadSuccess = {
  __typename?: 'DeleteECourseLessonPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type DeleteECourseLessonFileInput = {
  eCourseId: Scalars['ID']
  eCourseLessonId: Scalars['ID']
  eCourseLessonFileId: Scalars['ID']
}

export type DeleteECourseLessonFilePayloadSuccess = {
  __typename?: 'DeleteECourseLessonFilePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type DeleteECourseTrailerInput = {
  eCourseId: Scalars['ID']
  eCourseTrailerId: Scalars['ID']
}

export type DeleteECourseTrailerPayloadSuccess = {
  __typename?: 'DeleteECourseTrailerPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type MarkECourseLessonAsVisibleOrInvisibleInput = {
  eCourseId: Scalars['ID']
  eCourseLessonId: Scalars['ID']
  visible: Scalars['Boolean']
}

export type MarkECourseLessonAsVisibleOrInvisiblePayloadSuccess = {
  __typename?: 'MarkECourseLessonAsVisibleOrInvisiblePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type MarkECourseLessonFileAsCompletedOrUncompletedInput = {
  eCourseId: Scalars['ID']
  eCourseLessonFileId: Scalars['ID']
  completed: Scalars['Boolean']
}

export type MarkECourseLessonFileAsCompletedOrUncompletedPayloadSuccess = {
  __typename?: 'MarkECourseLessonFileAsCompletedOrUncompletedPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type MarkECourseLessonFileAsVisibleOrInvisibleInput = {
  eCourseId: Scalars['ID']
  eCourseLessonFileId: Scalars['ID']
  visible: Scalars['Boolean']
}

export type MarkECourseLessonFileAsVisibleOrInvisiblePayloadSuccess = {
  __typename?: 'MarkECourseLessonFileAsVisibleOrInvisiblePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type PublishECourseInput = {
  eCourseId: Scalars['ID']
}

export type PublishECoursePayloadSuccess = {
  __typename?: 'PublishECoursePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type SortECourseLessonFilesInput = {
  eCourseId: Scalars['ID']
  eCourseLessonId: Scalars['ID']
  sortedECourseLessonFileIds: Array<InputMaybe<Scalars['ID']>>
}

export type SortECourseLessonFilesPayloadSuccess = {
  __typename?: 'SortECourseLessonFilesPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type SortECourseLessonsInput = {
  eCourseId: Scalars['ID']
  sortedECourseLessonIds: Array<InputMaybe<Scalars['ID']>>
}

export type SortECourseLessonsPayloadSuccess = {
  __typename?: 'SortECourseLessonsPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type SortECourseTrailersInput = {
  eCourseId: Scalars['ID']
  sortedECourseTrailerIds: Array<InputMaybe<Scalars['ID']>>
}

export type SortECourseTrailersPayloadSuccess = {
  __typename?: 'SortECourseTrailersPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type ECourseConnection = {
  __typename?: 'ECourseConnection'
  edges: Array<ECourseEdge>
  nodes: Array<ECourse>
  pageInfo: PageInfo
}

export type ECourseEdge = {
  __typename?: 'ECourseEdge'
  node: ECourse
  cursor: Scalars['String']
}

export type ECourseLessonFileInput = {
  id: InputMaybe<Scalars['ID']>
  title: Scalars['String']
  description: InputMaybe<Scalars['String']>
  fileAsset: Scalars['ID']
}

export type ECourseLessonInput = {
  id: InputMaybe<Scalars['ID']>
  title: Scalars['String']
  files: InputMaybe<Array<InputMaybe<ECourseLessonFileInput>>>
}

export type ECourseOrder = Node &
  Transaction &
  OrderRatingInterface & {
    __typename?: 'ECourseOrder'
    id: Scalars['ID']
    user: User
    price: Money
    paidAt: Maybe<Scalars['DateTime']>
    createdAt: Scalars['DateTime']
    status: TransactionStatus
    platform: TransactionPlatform
    creator: Creator
    isPayedOut: Scalars['Boolean']
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    refund: Maybe<TransactionRefund>
    rating: Maybe<Rating>
    eCourse: Maybe<ECourse>
    customerProgress: Maybe<ECourseOrderCustomerProgress>
    questionAnswers: Maybe<Array<Maybe<CustomQuestionAnswer>>>
    accessRevokedAt: Maybe<Scalars['DateTime']>
  }

export type ECourseOrderCustomerProgress = {
  __typename?: 'ECourseOrderCustomerProgress'
  completedLessonFilesCount: Scalars['Int']
  totalLessonFilesCount: Scalars['Int']
}

export type ECourseOrderEdge = {
  __typename?: 'ECourseOrderEdge'
  node: ECourseOrder
  cursor: Scalars['String']
}

export type ECourseOrdersConnection = {
  __typename?: 'ECourseOrdersConnection'
  edges: Array<ECourseOrderEdge>
  nodes: Array<ECourseOrder>
  pageInfo: PageInfo
}

export enum ECourseStatusWithoutDraft {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type ECourseViewer = {
  __typename?: 'ECourseViewer'
  orderId: Maybe<Scalars['ID']>
  hasPurchased: Scalars['Boolean']
}

export type UnpublishECourseInput = {
  eCourseId: Scalars['ID']
}

export type UnpublishECoursePayloadSuccess = {
  __typename?: 'UnpublishECoursePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type UpdateCustomerECourseAccessInput = {
  orderId: Scalars['ID']
  revoke: Scalars['Boolean']
}

export type UpdateCustomerECourseAccessPayloadSuccess = {
  __typename?: 'UpdateCustomerECourseAccessPayloadSuccess'
  order: ECourseOrder
}

export type UpdateECourseInput = {
  ratingConfig: InputMaybe<RatingConfigInput>
  eCourseId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  slug: InputMaybe<Scalars['String']>
  price: InputMaybe<MoneyInput>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
  trailer: InputMaybe<Array<InputMaybe<ECourseLessonFileInput>>>
  lessons: InputMaybe<Array<InputMaybe<ECourseLessonInput>>>
  status: InputMaybe<ECourseStatusWithoutDraft>
}

export type UpdateECoursePayloadSuccess = {
  __typename?: 'UpdateECoursePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type UpdateECourseLessonInput = {
  eCourseId: Scalars['ID']
  eCourseLessonId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
}

export type UpdateECourseLessonPayloadSuccess = {
  __typename?: 'UpdateECourseLessonPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type UpdateECourseLessonFileInput = {
  eCourseId: Scalars['ID']
  eCourseLessonId: Scalars['ID']
  eCourseLessonFileId: Scalars['ID']
  fileAsset: InputMaybe<Scalars['ID']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
}

export type UpdateECourseLessonFilePayloadSuccess = {
  __typename?: 'UpdateECourseLessonFilePayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type UpdateECourseTrailerInput = {
  eCourseId: Scalars['ID']
  eCourseTrailerId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  fileAsset: InputMaybe<Scalars['ID']>
}

export type UpdateECourseTrailerPayloadSuccess = {
  __typename?: 'UpdateECourseTrailerPayloadSuccess'
  eCourse: Maybe<ECourse>
}

export type AddExclusiveContentInput = {
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  price: MoneyInput
  coverAsset: InputMaybe<Scalars['ID']>
  slug: InputMaybe<Scalars['String']>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  status: InputMaybe<ExclusiveContentStatusWithoutDraft>
  blocks: InputMaybe<Array<InputMaybe<ExclusiveContentBlockItemInput>>>
}

export type AddExclusiveContentPayload =
  | AddExclusiveContentPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddExclusiveContentPayloadSuccess = {
  __typename?: 'AddExclusiveContentPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type AddExclusiveContentFileBlockInput = {
  exclusiveContentId: Scalars['ID']
  fileAsset: Scalars['ID']
  title: Scalars['String']
  description: Scalars['String']
}

export type AddExclusiveContentFileBlockPayload =
  | AddExclusiveContentFileBlockPayloadSuccess
  | NotFoundError
  | InputValidationError
  | AssetNotLinkableToResourceError

export type AddExclusiveContentFileBlockPayloadSuccess = {
  __typename?: 'AddExclusiveContentFileBlockPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type AddExclusiveContentOrderInput = {
  exclusiveContentId: Scalars['ID']
  paymentIntentId: Scalars['String']
  userInformation: InputMaybe<UserCheckoutInput>
  message: InputMaybe<Scalars['String']>
  couponCode: InputMaybe<Scalars['String']>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddExclusiveContentOrderPayloadSuccess = {
  __typename?: 'AddExclusiveContentOrderPayloadSuccess'
  exclusiveContentOrderId: Maybe<Scalars['ID']>
  paymentIntent: Maybe<PaymentIntent>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddExclusiveContentTextBlockInput = {
  exclusiveContentId: Scalars['ID']
  kind: InputMaybe<ExclusiveContentTextBlockKinds>
  value: Scalars['String']
}

export type AddExclusiveContentTextBlockPayload =
  | AddExclusiveContentTextBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type AddExclusiveContentTextBlockPayloadSuccess = {
  __typename?: 'AddExclusiveContentTextBlockPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type AddFreeExclusiveContentOrderInput = {
  exclusiveContentId: Scalars['ID']
  userInformation: UserCheckoutInput
  message: InputMaybe<Scalars['String']>
  couponCode: InputMaybe<Scalars['String']>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddFreeExclusiveContentOrderPayload =
  | AddFreeExclusiveContentOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | ExclusiveContentAlreadyPurchasedError
  | ExclusiveContentIsMonetizedError
  | PartialCouponDiscountError

export type AddFreeExclusiveContentOrderPayloadSuccess = {
  __typename?: 'AddFreeExclusiveContentOrderPayloadSuccess'
  exclusiveContentOrderId: Scalars['ID']
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddPaypalExclusiveContentOrderInput = {
  exclusiveContentId: Scalars['ID']
  message: InputMaybe<Scalars['String']>
  userInformation: InputMaybe<UserCheckoutInput>
  couponCode: InputMaybe<Scalars['String']>
  paypalClientMetadataId: Scalars['String']
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddPaypalExclusiveContentOrderPayloadSuccess = {
  __typename?: 'AddPaypalExclusiveContentOrderPayloadSuccess'
  exclusiveContentOrderId: Maybe<Scalars['ID']>
  paypalOrder: Maybe<PaypalOrder>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type DeleteExclusiveContentInput = {
  exclusiveContentId: Scalars['ID']
}

export type DeleteExclusiveContentPayload =
  | DeleteExclusiveContentPayloadSuccess
  | DeleteExclusiveContentPayloadUnpublished
  | NotFoundError

export type DeleteExclusiveContentPayloadSuccess = {
  __typename?: 'DeleteExclusiveContentPayloadSuccess'
  exclusiveContentId: Scalars['ID']
}

export type DeleteExclusiveContentPayloadUnpublished = {
  __typename?: 'DeleteExclusiveContentPayloadUnpublished'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type DeleteExclusiveContentBlockInput = {
  exclusiveContentId: Scalars['ID']
  exclusiveContentBlockId: Scalars['ID']
}

export type DeleteExclusiveContentBlockPayload =
  | DeleteExclusiveContentBlockPayloadSuccess
  | NotFoundError

export type DeleteExclusiveContentBlockPayloadSuccess = {
  __typename?: 'DeleteExclusiveContentBlockPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type PublishExclusiveContentInput = {
  exclusiveContentId: Scalars['ID']
}

export type PublishExclusiveContentPayload =
  | PublishExclusiveContentPayloadSuccess
  | UnpublishableContentError
  | NotFoundError

export type PublishExclusiveContentPayloadSuccess = {
  __typename?: 'PublishExclusiveContentPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type SortExclusiveContentBlocksInput = {
  exclusiveContentId: Scalars['ID']
  sortedExclusiveContentBlockIds: Array<InputMaybe<Scalars['ID']>>
}

export type SortExclusiveContentBlocksPayload =
  | SortExclusiveContentBlocksPayloadSuccess
  | NotFoundError

export type SortExclusiveContentBlocksPayloadSuccess = {
  __typename?: 'SortExclusiveContentBlocksPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type ExclusiveContentBlockItem =
  | ExclusiveContentFileBlock
  | ExclusiveContentTitleBlock
  | ExclusiveContentTextBlock

export type ExclusiveContentBlockItemInput = {
  id: InputMaybe<Scalars['ID']>
  thumbnailAsset: InputMaybe<Scalars['ID']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  fileAsset: InputMaybe<Scalars['ID']>
  value: InputMaybe<Scalars['String']>
  isFree: InputMaybe<Scalars['Boolean']>
  kind: ExclusiveContentBlockItemKind
}

export enum ExclusiveContentBlockItemKind {
  File = 'FILE',
  Text = 'TEXT',
  Title = 'TITLE',
}

export enum ExclusiveContentDisplayEnum {
  Default = 'DEFAULT',
  Course = 'COURSE',
}

export type ExclusiveContentFileBlock = {
  __typename?: 'ExclusiveContentFileBlock'
  id: Scalars['ID']
  isFree: Scalars['Boolean']
  isVisible: Scalars['Boolean']
  fileAsset: Maybe<Asset>
  type: Maybe<ExclusiveContentFileBlockKindEnum>
  thumbnailAsset: Maybe<Asset>
  title: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
}

export type ExclusiveContentFileBlockInput = {
  id: InputMaybe<Scalars['ID']>
  thumbnailAsset: InputMaybe<Scalars['ID']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  fileAsset: Scalars['ID']
}

export enum ExclusiveContentFileBlockKindEnum {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Audio = 'AUDIO',
}

export enum ExclusiveContentStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export enum ExclusiveContentStatusWithoutDraft {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type ExclusiveContentTextBlock = {
  __typename?: 'ExclusiveContentTextBlock'
  id: Scalars['ID']
  isFree: Scalars['Boolean']
  isVisible: Scalars['Boolean']
  value: Maybe<Scalars['String']>
}

export enum ExclusiveContentTextBlockKinds {
  Text = 'TEXT',
  Title = 'TITLE',
}

export type ExclusiveContentTitleBlock = {
  __typename?: 'ExclusiveContentTitleBlock'
  id: Scalars['ID']
  isVisible: Scalars['Boolean']
  value: Maybe<Scalars['String']>
}

export type UnpublishExclusiveContentInput = {
  exclusiveContentId: Scalars['ID']
}

export type UnpublishExclusiveContentPayload =
  | UnpublishExclusiveContentPayloadSuccess
  | NotFoundError

export type UnpublishExclusiveContentPayloadSuccess = {
  __typename?: 'UnpublishExclusiveContentPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type UpdateCustomerExclusiveContentAccessInput = {
  orderId: Scalars['ID']
  revoke: Scalars['Boolean']
}

export type UpdateCustomerExclusiveContentAccessPayload =
  | UpdateCustomerExclusiveContentAccessPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateCustomerExclusiveContentAccessPayloadSuccess = {
  __typename?: 'UpdateCustomerExclusiveContentAccessPayloadSuccess'
  order: ExclusiveContentOrder
}

export type UpdateExclusiveContentInput = {
  ratingConfig: InputMaybe<RatingConfigInput>
  exclusiveContentId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
  price: InputMaybe<MoneyInput>
  slug: InputMaybe<Scalars['String']>
  display: InputMaybe<Scalars['String']>
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  status: InputMaybe<ExclusiveContentStatusWithoutDraft>
  blocks: InputMaybe<Array<InputMaybe<ExclusiveContentBlockItemInput>>>
}

export type UpdateExclusiveContentPayload =
  | UpdateExclusiveContentPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateExclusiveContentPayloadSuccess = {
  __typename?: 'UpdateExclusiveContentPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type UpdateExclusiveContentBlockVisibilityInput = {
  exclusiveContentId: Scalars['ID']
  exclusiveContentBlockId: Scalars['ID']
  isVisible: Scalars['Boolean']
}

export type UpdateExclusiveContentBlockVisibilityPayload =
  | UpdateExclusiveContentBlockVisibilityPayloadSuccess
  | NotFoundError

export type UpdateExclusiveContentBlockVisibilityPayloadSuccess = {
  __typename?: 'UpdateExclusiveContentBlockVisibilityPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type UpdateExclusiveContentFileBlockInput = {
  exclusiveContentId: Scalars['ID']
  exclusiveContentBlockId: Scalars['ID']
  thumbnailAsset: InputMaybe<Scalars['ID']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  isFree: Scalars['Boolean']
}

export type UpdateExclusiveContentFileBlockPayload =
  | UpdateExclusiveContentFileBlockPayloadSuccess
  | NotFoundError
  | InputValidationError
  | AssetNotLinkableToResourceError

export type UpdateExclusiveContentFileBlockPayloadSuccess = {
  __typename?: 'UpdateExclusiveContentFileBlockPayloadSuccess'
  exclusiveContent: ExclusiveContent
}

export type UpdateExclusiveContentBlockMonetizationInput = {
  exclusiveContentId: Scalars['ID']
  exclusiveContentBlockId: Scalars['ID']
  isFree: Scalars['Boolean']
}

export type UpdateExclusiveContentBlockMonetizationPayload =
  | UpdateExclusiveContentBlockMonetizationPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateExclusiveContentBlockMonetizationPayloadSuccess = {
  __typename?: 'UpdateExclusiveContentBlockMonetizationPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type UpdateExclusiveContentTextBlockInput = {
  exclusiveContentId: Scalars['ID']
  exclusiveContentBlockId: Scalars['ID']
  value: InputMaybe<Scalars['String']>
  isFree: Scalars['Boolean']
}

export type UpdateExclusiveContentTextBlockPayload =
  | UpdateExclusiveContentTextBlockPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateExclusiveContentTextBlockPayloadSuccess = {
  __typename?: 'UpdateExclusiveContentTextBlockPayloadSuccess'
  exclusiveContent: Maybe<ExclusiveContent>
}

export type Add30DayTrialWithoutPaymentMethodPayloadSuccess = {
  __typename?: 'Add30DayTrialWithoutPaymentMethodPayloadSuccess'
  creator: Creator
}

export type AddCreatorSubscriptionInput = {
  priceId: Scalars['String']
  fpTrackingId: InputMaybe<Scalars['String']>
  coupon: InputMaybe<Scalars['String']>
  paymentMethodId: InputMaybe<Scalars['String']>
  currency: Scalars['String']
  planName: InputMaybe<Scalars['String']>
}

export type AddCreatorSubscriptionPayload =
  | AddCreatorSubscriptionPayloadSuccess
  | CreatorAlreadyUpgradedError
  | ExternalServiceRequestError
  | InputValidationError
  | NotFoundError

export type AddCreatorSubscriptionPayloadSuccess = {
  __typename?: 'AddCreatorSubscriptionPayloadSuccess'
  type: AddCreatorSubscriptionIntentType
  clientSecret: Scalars['String']
  creator: Creator
}

export type AddInternalSubscriptionSetupIntent = {
  creatorId: Scalars['ID']
}

export type AddInternalSubscriptionSetupIntentPayload =
  | AddInternalSubscriptionSetupIntentPayloadSuccess
  | CreatorAlreadyUpgradedError
  | InputValidationError
  | ExternalServiceRequestError
  | NotFoundError

export type AddInternalSubscriptionSetupIntentPayloadSuccess = {
  __typename?: 'AddInternalSubscriptionSetupIntentPayloadSuccess'
  clientSecret: Scalars['String']
  customerId: Scalars['String']
  creator: Creator
}

export type InternalSubscription = {
  __typename?: 'InternalSubscription'
  status: InternalSubscriptionStatus
  plan: InternalSubscriptionPlan
  planCapabilities: Array<PaidCapability>
  periodEnd: Maybe<Scalars['Date']>
  periodStart: Maybe<Scalars['Date']>
  priceId: Scalars['String']
  invoiceId: Maybe<Scalars['String']>
  subscriptionId: Scalars['String']
  promoId: Maybe<Scalars['String']>
  paidAmount: Scalars['Int']
  currencyCode: Scalars['String']
  canceledAt: Maybe<Scalars['Date']>
  cancelAt: Maybe<Scalars['Date']>
  endedAt: Maybe<Scalars['Date']>
  createdAt: Scalars['Date']
  updatedAt: Maybe<Scalars['Date']>
}

export enum InternalSubscriptionPlan {
  Starter = 'STARTER',
  Pro = 'PRO',
  ProPlus = 'PRO_PLUS',
}

export enum InternalSubscriptionStatus {
  Canceled = 'CANCELED',
  Active = 'ACTIVE',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Unpaid = 'UNPAID',
  Trialing = 'TRIALING',
}

export enum PaidCapability {
  CustomDomainNames = 'CUSTOM_DOMAIN_NAMES',
  ZapierIntegration = 'ZAPIER_INTEGRATION',
  PremiumTemplate = 'PREMIUM_TEMPLATE',
  GoogleCalendarsSelection = 'GOOGLE_CALENDARS_SELECTION',
  ReceiptEmailCustomMessageTemplate = 'RECEIPT_EMAIL_CUSTOM_MESSAGE_TEMPLATE',
  ShortenUrl = 'SHORTEN_URL',
  HideBranding = 'HIDE_BRANDING',
  OutreachCampaigns = 'OUTREACH_CAMPAIGNS',
}

export type AddFreeInvoiceOrderInput = {
  invoiceId: Scalars['ID']
  billingAddress: InputMaybe<Scalars['String']>
  userInformation: InputMaybe<UserCheckoutInput>
}

export type AddFreeInvoiceOrderPayloadSuccess = {
  __typename?: 'AddFreeInvoiceOrderPayloadSuccess'
  invoiceOrderId: Maybe<Scalars['ID']>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddInvoiceInput = {
  currencyCode: CurrencyCode
  customer: InvoiceCustomerInput
  issuer: InvoiceIssuerInput
  items: InputMaybe<Array<InvoiceItemInput>>
  tax: InputMaybe<InvoiceTaxInput>
  dueDate: Scalars['Date']
  memo: InputMaybe<Scalars['String']>
}

export type AddInvoicePayloadSuccess = {
  __typename?: 'AddInvoicePayloadSuccess'
  invoice: Maybe<Invoice>
}

export type AddInvoiceOrderInput = {
  invoiceId: Scalars['ID']
  paymentIntentId: Scalars['String']
  billingAddress: InputMaybe<Scalars['String']>
  userInformation: InputMaybe<UserCheckoutInput>
}

export type AddInvoiceOrderPayloadSuccess = {
  __typename?: 'AddInvoiceOrderPayloadSuccess'
  invoiceOrderId: Maybe<Scalars['ID']>
  paymentIntent: Maybe<PaymentIntent>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddPaypalInvoiceOrderInput = {
  invoiceId: Scalars['ID']
  paypalClientMetadataId: Scalars['String']
  billingAddress: InputMaybe<Scalars['String']>
  userInformation: InputMaybe<UserCheckoutInput>
}

export type AddPaypalInvoiceOrderPayloadSuccess = {
  __typename?: 'AddPaypalInvoiceOrderPayloadSuccess'
  invoiceOrderId: Maybe<Scalars['ID']>
  paypalOrder: Maybe<PaypalOrder>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type DeleteInvoiceInput = {
  invoiceId: Scalars['ID']
}

export type DeleteInvoicePayloadSuccess = {
  __typename?: 'DeleteInvoicePayloadSuccess'
  invoice: Maybe<Invoice>
}

export type InvoiceByNumberPayloadSuccess = {
  __typename?: 'InvoiceByNumberPayloadSuccess'
  invoice: Maybe<Invoice>
}

export type PublishInvoiceInput = {
  invoiceId: InputMaybe<Scalars['ID']>
}

export type PublishInvoicePayloadSuccess = {
  __typename?: 'PublishInvoicePayloadSuccess'
  invoice: Maybe<Invoice>
}

export type SendInvoiceViaEmailInput = {
  invoiceId: Scalars['ID']
}

export type SendInvoiceViaEmailPayloadSuccess = {
  __typename?: 'SendInvoiceViaEmailPayloadSuccess'
  invoice: Maybe<Invoice>
}

export type Invoice = Node & {
  __typename?: 'Invoice'
  id: Scalars['ID']
  shortUrl: Maybe<ShortUrlEmbedded>
  status: InvoiceStatus
  databaseId: Scalars['String']
  isDeletable: Scalars['Boolean']
  totalPrice: Money
  currencyCode: CurrencyCode
  createdAt: Scalars['DateTime']
  number: Scalars['ID']
  dueDate: Scalars['Date']
  lastSentAt: Maybe<Scalars['DateTime']>
  memo: Maybe<Scalars['String']>
  memoV2: Maybe<Scalars['String']>
  customer: Maybe<InvoiceCustomer>
  issuer: Maybe<InvoiceIssuer>
  owner: Creator
  tax: Maybe<InvoiceTax>
  items: Maybe<Array<InvoiceItem>>
  transaction: Maybe<InvoiceOrder>
}

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection'
  edges: Array<InvoiceEdge>
  nodes: Array<Invoice>
  pageInfo: PageInfo
}

export type InvoiceCustomer = {
  __typename?: 'InvoiceCustomer'
  name: Scalars['String']
  email: Scalars['String']
  billingAddress: Maybe<Scalars['String']>
  logoAsset: Maybe<Asset>
}

export type InvoiceCustomerInput = {
  name: Scalars['String']
  email: Scalars['String']
  billingAddress: InputMaybe<Scalars['String']>
  logoAsset: InputMaybe<Scalars['ID']>
}

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge'
  node: Invoice
  cursor: Scalars['String']
}

export type InvoiceIssuer = {
  __typename?: 'InvoiceIssuer'
  name: Scalars['String']
  email: Maybe<Scalars['String']>
  address: Maybe<Scalars['String']>
  taxIdentificationNumber: Maybe<Scalars['String']>
  logoAsset: Maybe<Asset>
}

export type InvoiceIssuerInput = {
  name: Scalars['String']
  email: InputMaybe<Scalars['String']>
  address: Scalars['String']
  taxIdentificationNumber: InputMaybe<Scalars['String']>
  logoAsset: InputMaybe<Scalars['ID']>
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem'
  description: Scalars['String']
  quantity: Scalars['Int']
  amount: Scalars['Int']
}

export type InvoiceItemInput = {
  description: Scalars['String']
  quantity: Scalars['Int']
  amount: Scalars['Int']
}

export type InvoiceOrder = Node &
  Transaction & {
    __typename?: 'InvoiceOrder'
    id: Scalars['ID']
    user: User
    price: Money
    paidAt: Maybe<Scalars['DateTime']>
    createdAt: Scalars['DateTime']
    status: TransactionStatus
    platform: TransactionPlatform
    creator: Creator
    isPayedOut: Scalars['Boolean']
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    refund: Maybe<TransactionRefund>
    invoice: Maybe<Invoice>
  }

export type InvoiceOrderEdge = {
  __typename?: 'InvoiceOrderEdge'
  node: InvoiceOrder
  cursor: Scalars['String']
}

export type InvoiceOrdersConnection = {
  __typename?: 'InvoiceOrdersConnection'
  edges: Array<InvoiceOrderEdge>
  nodes: Array<InvoiceOrder>
  pageInfo: PageInfo
}

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
}

export type InvoiceTax = {
  __typename?: 'InvoiceTax'
  label: Scalars['String']
  percentage: Scalars['Float']
}

export type InvoiceTaxInput = {
  label: Scalars['String']
  percentage: Scalars['Float']
}

export type UpdateInvoiceInput = {
  invoiceId: InputMaybe<Scalars['ID']>
  currencyCode: InputMaybe<CurrencyCode>
  dueDate: InputMaybe<Scalars['Date']>
  memo: InputMaybe<Scalars['String']>
  customer: InputMaybe<InvoiceCustomerInput>
  issuer: InputMaybe<InvoiceIssuerInput>
  tax: InputMaybe<InvoiceTaxInput>
  items: InputMaybe<Array<InputMaybe<InvoiceItemInput>>>
}

export type UpdateInvoicePayloadSuccess = {
  __typename?: 'UpdateInvoicePayloadSuccess'
  invoice: Maybe<Invoice>
}

export type AddFreeLiveStreamOrderInput = {
  liveStreamId: Scalars['ID']
  userInformation: InputMaybe<UserCheckoutInput>
  message: InputMaybe<Scalars['String']>
  couponCode: InputMaybe<Scalars['String']>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddFreeLiveStreamOrderPayloadSuccess = {
  __typename?: 'AddFreeLiveStreamOrderPayloadSuccess'
  liveStreamOrderId: Scalars['ID']
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddLiveStreamInput = {
  slug: InputMaybe<Scalars['String']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
  maxUser: InputMaybe<Scalars['Int']>
  startAt: InputMaybe<Scalars['DateTime']>
  duration: InputMaybe<Scalars['Int']>
  platform: InputMaybe<LiveStreamPlatform>
  status: InputMaybe<LiveStreamStatus>
  price: MoneyInput
  timezone: InputMaybe<Scalars['String']>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
}

export type AddLiveStreamPayload =
  | AddLiveStreamPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type AddLiveStreamPayloadSuccess = {
  __typename?: 'AddLiveStreamPayloadSuccess'
  liveStream: Maybe<LiveStream>
}

export type AddLiveStreamOrderInput = {
  liveStreamId: Scalars['ID']
  paymentIntentId: Scalars['String']
  userInformation: InputMaybe<UserCheckoutInput>
  message: InputMaybe<Scalars['String']>
  couponCode: InputMaybe<Scalars['String']>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddLiveStreamOrderPayloadSuccess = {
  __typename?: 'AddLiveStreamOrderPayloadSuccess'
  liveStreamOrderId: Scalars['ID']
  paymentIntent: Maybe<PaymentIntent>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddPaypalLiveStreamOrderInput = {
  liveStreamId: Scalars['ID']
  userInformation: InputMaybe<UserCheckoutInput>
  message: InputMaybe<Scalars['String']>
  couponCode: InputMaybe<Scalars['String']>
  paypalClientMetadataId: Scalars['String']
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddPaypalLiveStreamOrderPayloadSuccess = {
  __typename?: 'AddPaypalLiveStreamOrderPayloadSuccess'
  transactionId: Maybe<Scalars['ID']>
  paypalOrder: Maybe<PaypalOrder>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type DeleteLiveStreamInput = {
  liveStreamId: Scalars['ID']
}

export type DeleteLiveStreamPayload =
  | DeleteLiveStreamPayloadSuccess
  | DeleteLiveStreamPayloadUnpublished
  | NotFoundError

export type DeleteLiveStreamPayloadSuccess = {
  __typename?: 'DeleteLiveStreamPayloadSuccess'
  deletedLiveStreamId: Scalars['ID']
}

export type DeleteLiveStreamPayloadUnpublished = {
  __typename?: 'DeleteLiveStreamPayloadUnpublished'
  liveStreamId: Scalars['ID']
}

export type PublishLiveStreamInput = {
  liveStreamId: Scalars['ID']
}

export type PublishLiveStreamPayload =
  | PublishLiveStreamPayloadSuccess
  | InputValidationError
  | NotFoundError
  | ExternalServiceRequestError
  | UnpublishableContentError

export type PublishLiveStreamPayloadSuccess = {
  __typename?: 'PublishLiveStreamPayloadSuccess'
  liveStream: LiveStream
}

export type LiveStreamConnection = {
  __typename?: 'LiveStreamConnection'
  edges: Maybe<Array<Maybe<LiveStreamEdge>>>
  nodes: Maybe<Array<Maybe<LiveStream>>>
  pageInfo: PageInfo
}

export type LiveStreamEdge = {
  __typename?: 'LiveStreamEdge'
  cursor: Scalars['String']
  node: LiveStream
}

export type LiveStreamOrder = Node &
  Transaction &
  OrderRatingInterface & {
    __typename?: 'LiveStreamOrder'
    id: Scalars['ID']
    user: User
    price: Money
    paidAt: Maybe<Scalars['DateTime']>
    createdAt: Scalars['DateTime']
    status: TransactionStatus
    platform: TransactionPlatform
    creator: Creator
    isPayedOut: Scalars['Boolean']
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    refund: Maybe<TransactionRefund>
    rating: Maybe<Rating>
    ownerMessage: Maybe<Scalars['String']>
    liveStream: Maybe<LiveStream>
    questionAnswers: Maybe<Array<Maybe<CustomQuestionAnswer>>>
  }

export type LiveStreamOrderConnection = {
  __typename?: 'LiveStreamOrderConnection'
  edges: Maybe<Array<Maybe<LiveStreamOrderEdge>>>
  nodes: Maybe<Array<Maybe<LiveStreamOrder>>>
  pageInfo: PageInfo
}

export type LiveStreamOrderEdge = {
  __typename?: 'LiveStreamOrderEdge'
  cursor: Scalars['String']
  node: LiveStreamOrder
}

export enum LiveStreamStatusWithoutDraft {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type UnpublishLiveStreamInput = {
  liveStreamId: Scalars['ID']
}

export type UnpublishLiveStreamPayload =
  | UnpublishLiveStreamPayloadSuccess
  | InputValidationError
  | NotFoundError
  | ExternalServiceRequestError

export type UnpublishLiveStreamPayloadSuccess = {
  __typename?: 'UnpublishLiveStreamPayloadSuccess'
  liveStream: LiveStream
}

export type UpdateLiveStreamInput = {
  ratingConfig: InputMaybe<RatingConfigInput>
  slug: InputMaybe<Scalars['String']>
  liveStreamId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  price: InputMaybe<MoneyInput>
  maxUser: InputMaybe<Scalars['Int']>
  startAt: InputMaybe<Scalars['DateTime']>
  duration: InputMaybe<Scalars['Int']>
  platform: InputMaybe<LiveStreamPlatform>
  coverAsset: InputMaybe<Scalars['ID']>
  timezone: InputMaybe<Scalars['String']>
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  status: InputMaybe<LiveStreamStatusWithoutDraft>
}

export type UpdateLiveStreamPayload =
  | UpdateLiveStreamPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateLiveStreamPayloadSuccess = {
  __typename?: 'UpdateLiveStreamPayloadSuccess'
  liveStream: Maybe<LiveStream>
}

export type AddMembershipBlockInput = {
  kind: MembershipBlockKindEnum
  accessibleByTiers: Array<Scalars['ID']>
  isVisible: Scalars['Boolean']
  content: InputMaybe<Scalars['String']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  thumbnailAsset: InputMaybe<Scalars['ID']>
  fileAsset: InputMaybe<Scalars['ID']>
}

export type AddMembershipInput = {
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
  slug: InputMaybe<Scalars['String']>
  tiers: Array<AddMembershipTierInput>
  blocks: InputMaybe<Array<AddMembershipBlockInput>>
}

export type AddMembershipPayload =
  | AddMembershipPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddMembershipPayloadSuccess = {
  __typename?: 'AddMembershipPayloadSuccess'
  membership: Maybe<Membership>
}

export type AddMembershipTierInput = {
  name: Scalars['String']
  description: Scalars['String']
  pricingPlans: Array<AddMembershipTierPricingPlanInput>
}

export type AddMembershipTierPricingPlanInput = {
  intervalCount: Scalars['Int']
  interval: MembershipTierPricingPlanIntervalEnum
  amount: Scalars['Int']
  currencyCode: CurrencyCode
}

export type AddMembershipSubscriptionInput = {
  userId: InputMaybe<Scalars['ID']>
  membershipId: Scalars['ID']
  tierId: Scalars['ID']
  interval: MembershipTierPricingPlanIntervalEnum
  userInformation: InputMaybe<UserCheckoutInput>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
}

export type AddMembershipSubscriptionPayloadSuccess = {
  __typename?: 'AddMembershipSubscriptionPayloadSuccess'
  userId: Maybe<Scalars['ID']>
  membershipSubscriptionId: Maybe<Scalars['ID']>
  authPayload: Maybe<SignupUserPayloadSuccess>
  paymentIntent: PaymentIntent
}

export type DeleteMembershipInput = {
  membershipId: Scalars['ID']
}

export type DeleteMembershipPayload =
  | DeleteMembershipPayloadSuccess
  | InputValidationError
  | NotDeletableError
  | NotFoundError

export type DeleteMembershipPayloadSuccess = {
  __typename?: 'DeleteMembershipPayloadSuccess'
  membership: Maybe<Membership>
}

export type CheckoutMembershipSubscription = {
  __typename?: 'CheckoutMembershipSubscription'
  membershipSubscriptionId: Scalars['ID']
  tierId: Scalars['String']
  interval: MembershipTierPricingPlanIntervalEnum
  paymentIntent: PaymentIntent
  userId: Scalars['String']
}

export type Membership = Node &
  RatingInterface &
  TestimonialInterface & {
    __typename?: 'Membership'
    id: Scalars['ID']
    ratingConfig: Maybe<MonetizableServiceRatingConfig>
    rating: Maybe<MonetizableServiceRating>
    ratings: Maybe<RatingsConnection>
    testimonialConfig: Maybe<MonetizableServiceTestimonialConfig>
    testimonials: Maybe<TestimonialsConnection>
    shortUrl: Maybe<ShortUrlEmbedded>
    slug: Maybe<Scalars['String']>
    title: Maybe<Scalars['String']>
    description: Maybe<Scalars['String']>
    descriptionHTML: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplate: Maybe<Scalars['String']>
    receiptEmailCustomMessageTemplateV2: Maybe<Scalars['String']>
    databaseId: Scalars['String']
    owner: Creator
    coverAsset: Maybe<Asset>
    status: MembershipStatusEnum
    blocks: Array<Maybe<MembershipFileBlock | MembershipTextBlock>>
    isInsideLinkBlock: Scalars['Boolean']
    isDeletable: Scalars['Boolean']
    viewerHasPurchased: Scalars['Boolean']
    tiers: Array<MembershipTier>
    questions: Maybe<Array<Maybe<CustomQuestion>>>
  }

export type MembershipRatingsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type MembershipBlock = {
  id: Scalars['ID']
  isVisible: Scalars['Boolean']
  title: Scalars['String']
  accessibleByTiers: Array<Scalars['ID']>
}

export type MembershipBlockItem = {
  id: Scalars['ID']
  kind: MembershipBlockKindEnum
  isVisible: Scalars['Boolean']
}

export enum MembershipBlockKindEnum {
  Text = 'Text',
  File = 'File',
}

export type MembershipConnection = {
  __typename?: 'MembershipConnection'
  edges: Maybe<Array<Maybe<MembershipEdge>>>
  nodes: Maybe<Array<Maybe<Membership>>>
  pageInfo: PageInfo
}

export type MembershipEdge = {
  __typename?: 'MembershipEdge'
  cursor: Scalars['String']
  node: Membership
}

export type MembershipFileBlock = MembershipBlock & {
  __typename?: 'MembershipFileBlock'
  id: Scalars['ID']
  isVisible: Scalars['Boolean']
  title: Scalars['String']
  accessibleByTiers: Array<Scalars['ID']>
  fileAsset: Maybe<Asset>
  type: Maybe<MembershipFileBlockKindEnum>
  thumbnailAsset: Maybe<Asset>
  description: Maybe<Scalars['String']>
}

export enum MembershipFileBlockKindEnum {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Audio = 'AUDIO',
}

export type MembershipFilterByField = {
  status: InputMaybe<Array<MembershipStatusEnum>>
  canBeAddedToPageOnly: InputMaybe<Scalars['Boolean']>
}

export enum MembershipSortField {
  CreatedAt = 'CREATED_AT',
}

export enum MembershipStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type MembershipSubscription = Node &
  BaseSubscription &
  OrderRatingInterface & {
    __typename?: 'MembershipSubscription'
    id: Scalars['ID']
    status: BaseSubscriptionStatusEnum
    isValid: Scalars['Boolean']
    createdAt: Scalars['DateTime']
    pricingPlan: BaseSubscriptionPricingPlan
    user: User
    creator: Creator
    rating: Maybe<Rating>
    membership: Membership
    transactions: MembershipSubscriptionTransactionConnection
    latestTransaction: Maybe<MembershipSubscriptionTransaction>
    stripe: Maybe<MembershipSubscriptionStripe>
    tier: MembershipSubscriptionTier
    questionAnswers: Maybe<Array<Maybe<CustomQuestionAnswer>>>
  }

export type MembershipSubscriptionTransactionsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
  sortOrder: InputMaybe<OrderByDirection>
  sortField: InputMaybe<MembershipSubscriptionTransactionsSortField>
  filterByField: InputMaybe<MembershipSubscriptionTransactionsFilterByField>
}

export type MembershipSubscriptionConnection = {
  __typename?: 'MembershipSubscriptionConnection'
  edges: Maybe<Array<Maybe<MembershipSubscriptionEdge>>>
  nodes: Maybe<Array<Maybe<MembershipSubscription>>>
  pageInfo: PageInfo
}

export type MembershipSubscriptionEdge = {
  __typename?: 'MembershipSubscriptionEdge'
  cursor: Scalars['String']
  node: MembershipSubscription
}

export type MembershipSubscriptionTransaction = Node &
  Transaction & {
    __typename?: 'MembershipSubscriptionTransaction'
    id: Scalars['ID']
    user: User
    price: Money
    paidAt: Maybe<Scalars['DateTime']>
    createdAt: Scalars['DateTime']
    status: TransactionStatus
    platform: TransactionPlatform
    creator: Creator
    isPayedOut: Scalars['Boolean']
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    refund: Maybe<TransactionRefund>
    membership: Membership
    subscription: MembershipSubscription
  }

export type MembershipSubscriptionTransactionConnection = {
  __typename?: 'MembershipSubscriptionTransactionConnection'
  edges: Maybe<Array<Maybe<MembershipSubscriptionTransactionEdge>>>
  nodes: Maybe<Array<Maybe<MembershipSubscriptionTransaction>>>
  pageInfo: PageInfo
}

export type MembershipSubscriptionTransactionEdge = {
  __typename?: 'MembershipSubscriptionTransactionEdge'
  cursor: Scalars['String']
  node: MembershipSubscriptionTransaction
}

export type MembershipSubscriptionTransactionsFilterByField = {
  status: InputMaybe<Array<TransactionStatus>>
}

export enum MembershipSubscriptionTransactionsSortField {
  CreatedAt = 'CREATED_AT',
}

export type MembershipSubscriptionsFilterByField = {
  status: InputMaybe<Array<BaseSubscriptionStatusEnum>>
}

export enum MembershipSubscriptionsSortField {
  CreatedAt = 'CREATED_AT',
}

export type MembershipTextBlock = MembershipBlock & {
  __typename?: 'MembershipTextBlock'
  id: Scalars['ID']
  isVisible: Scalars['Boolean']
  title: Scalars['String']
  accessibleByTiers: Array<Scalars['ID']>
  content: Maybe<Scalars['String']>
}

export enum MembershipTextBlockKinds {
  Text = 'TEXT',
}

export type MembershipTier = {
  __typename?: 'MembershipTier'
  id: Scalars['ID']
  name: Scalars['String']
  description: Maybe<Scalars['String']>
  pricingPlans: Array<MembershipTierPricingPlan>
}

export type MembershipTierPricingPlan = {
  __typename?: 'MembershipTierPricingPlan'
  id: Scalars['ID']
  intervalCount: Scalars['Int']
  interval: MembershipTierPricingPlanIntervalEnum
  amount: Scalars['Int']
  currencyCode: CurrencyCode
}

export enum MembershipTierPricingPlanIntervalEnum {
  Month = 'MONTH',
  Year = 'YEAR',
}

export type UpdateMembershipBlockInput = {
  id: InputMaybe<Scalars['ID']>
  kind: MembershipBlockKindEnum
  accessibleByTiers: Array<Scalars['ID']>
  isVisible: Scalars['Boolean']
  content: InputMaybe<Scalars['String']>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  thumbnailAsset: InputMaybe<Scalars['ID']>
  fileAsset: InputMaybe<Scalars['ID']>
}

export type UpdateMembershipInput = {
  membershipId: Scalars['ID']
  ratingConfig: InputMaybe<RatingConfigInput>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
  slug: InputMaybe<Scalars['String']>
  status: InputMaybe<MembershipStatusEnum>
  tiers: InputMaybe<Array<UpdateMembershipTierInput>>
  blocks: InputMaybe<Array<UpdateMembershipBlockInput>>
}

export type UpdateMembershipPayload =
  | UpdateMembershipPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateMembershipPayloadSuccess = {
  __typename?: 'UpdateMembershipPayloadSuccess'
  membership: Maybe<Membership>
}

export type UpdateMembershipTierInput = {
  id: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  description: Scalars['String']
  pricingPlans: Array<UpdateMembershipTierPricingPlanInput>
}

export type UpdateMembershipTierPricingPlanInput = {
  id: InputMaybe<Scalars['ID']>
  intervalCount: Scalars['Int']
  interval: MembershipTierPricingPlanIntervalEnum
  amount: Scalars['Int']
  currencyCode: CurrencyCode
}

export type UpdateMembershipSubscriptionInput = {
  membershipSubscriptionId: Scalars['ID']
  tierId: Scalars['ID']
  interval: MembershipTierPricingPlanIntervalEnum
}

export type UpdateMembershipSubscriptionPayloadSuccess = {
  __typename?: 'UpdateMembershipSubscriptionPayloadSuccess'
  membershipSubscriptionId: Maybe<Scalars['ID']>
  paymentIntent: PaymentIntent
}

export type CalculateDiscountsInput = {
  resourceId: Scalars['ID']
  couponCode: InputMaybe<Scalars['String']>
  stripePaymentIntentId: InputMaybe<Scalars['String']>
}

export type CalculateDiscountsPayloadSuccess = {
  __typename?: 'CalculateDiscountsPayloadSuccess'
  discounts: Maybe<Array<DiscountCalculationItem>>
  originalPrice: Maybe<Money>
  deductedPrice: Maybe<Money>
}

export type DiscountCalculationItem = {
  __typename?: 'DiscountCalculationItem'
  amount: Maybe<Scalars['Int']>
  percentage: Maybe<Scalars['Float']>
  type: DiscountType
}

export enum DiscountType {
  Coupon = 'COUPON',
  Sale = 'SALE',
}

export type InvalidDeductiblePriceError = {
  __typename?: 'InvalidDeductiblePriceError'
  code: Scalars['String']
  message: Scalars['String']
}

export type AddDiscountCouponInput = {
  resourceId: Scalars['ID']
  name: Scalars['String']
  code: InputMaybe<Scalars['String']>
  discountAmount: InputMaybe<Scalars['Int']>
  discountPercentage: InputMaybe<Scalars['Float']>
  allowCombiningWithSaleDiscount: InputMaybe<Scalars['Boolean']>
  numberOfRedemptionsLimit: InputMaybe<Scalars['Int']>
  expiryDateLimit: InputMaybe<Scalars['DateTime']>
}

export type AddDiscountCouponPayloadSuccess = {
  __typename?: 'AddDiscountCouponPayloadSuccess'
  resource: Maybe<CouponDiscountable>
}

export type DeleteDiscountCouponInput = {
  resourceId: Scalars['ID']
  code: Scalars['String']
}

export type DeleteDiscountCouponPayloadSuccess = {
  __typename?: 'DeleteDiscountCouponPayloadSuccess'
  resource: Maybe<CouponDiscountable>
}

export type UpdateDiscountCouponInput = {
  currentCouponCode: Scalars['String']
  resourceId: Scalars['ID']
  name: Scalars['String']
  code: Scalars['String']
  discountAmount: InputMaybe<Scalars['Int']>
  discountPercentage: InputMaybe<Scalars['Float']>
  allowCombiningWithSaleDiscount: InputMaybe<Scalars['Boolean']>
  numberOfRedemptionsLimit: InputMaybe<Scalars['Int']>
  expiryDateLimit: InputMaybe<Scalars['DateTime']>
}

export type UpdateDiscountCouponPayloadSuccess = {
  __typename?: 'UpdateDiscountCouponPayloadSuccess'
  resource: Maybe<CouponDiscountable>
}

export type UpdateDiscountCouponStatusInput = {
  resourceId: Scalars['ID']
  code: Scalars['String']
  status: DiscountCouponStatus
}

export type UpdateDiscountCouponStatusSuccess = {
  __typename?: 'UpdateDiscountCouponStatusSuccess'
  resource: Maybe<CouponDiscountable>
}

export type GenerateMagicLinkToPurchaseInput = {
  purchaseId: Scalars['ID']
}

export type GenerateMagicLinkToPurchasePayload =
  | GenerateMagicLinkToPurchasePayloadSuccess
  | NotFoundError

export type GenerateMagicLinkToPurchasePayloadSuccess = {
  __typename?: 'GenerateMagicLinkToPurchasePayloadSuccess'
  accessLink: Scalars['String']
}

export type GeneratePaypalOnboardingLinkInput = {
  redirectUrl: InputMaybe<Scalars['String']>
}

export type GeneratePaypalOnboardingLinkPayloadSuccess = {
  __typename?: 'GeneratePaypalOnboardingLinkPayloadSuccess'
  link: Scalars['String']
}

export type InvoiceIssuerDefaultValuesInput = {
  name: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  address: InputMaybe<Scalars['String']>
  taxIdentificationNumber: InputMaybe<Scalars['String']>
  logoAsset: InputMaybe<Scalars['ID']>
}

export type SetInvoicesPreferencesInput = {
  defaultValues: SetInvoicesPreferencesDefaultValuesInput
}

export type SetInvoicesPreferencesSuccess = {
  __typename?: 'SetInvoicesPreferencesSuccess'
  creator: Creator
}

export type SetPaypalPreferencesInput = {
  enablePayLaterOption: Scalars['Boolean']
}

export type SetPaypalPreferencesSuccess = {
  __typename?: 'SetPaypalPreferencesSuccess'
  creator: Creator
}

export type RequestMagicLinkToPurchaseInput = {
  purchaseId: InputMaybe<Scalars['ID']>
  email: Scalars['String']
}

export type RequestMagicLinkToPurchasePayload =
  | RequestMagicLinkToPurchasePayloadSuccess
  | NotFoundError
  | ActorDisabledError

export type RequestMagicLinkToPurchasePayloadSuccess = {
  __typename?: 'RequestMagicLinkToPurchasePayloadSuccess'
  message: Scalars['String']
}

export type CancelBaseSubscriptionInput = {
  baseSubscriptionId: Scalars['ID']
}

export type CancelBaseSubscriptionPayloadSuccess = {
  __typename?: 'CancelBaseSubscriptionPayloadSuccess'
  subscription: MembershipSubscription
}

export type BaseSubscription = {
  id: Scalars['ID']
  status: BaseSubscriptionStatusEnum
  isValid: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  pricingPlan: BaseSubscriptionPricingPlan
  user: User
  creator: Creator
}

export type BaseSubscriptionPricingPlan = {
  __typename?: 'BaseSubscriptionPricingPlan'
  interval: BaseSubscriptionPricingPlanIntervalEnum
  intervalCount: Scalars['Int']
  amount: Scalars['Int']
  currencyCode: CurrencyCode
}

export enum BaseSubscriptionPricingPlanIntervalEnum {
  Month = 'MONTH',
  Year = 'YEAR',
}

export enum BaseSubscriptionStatusEnum {
  Canceled = 'CANCELED',
  Active = 'ACTIVE',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Unpaid = 'UNPAID',
  Trialing = 'TRIALING',
}

export enum CaptureMethodType {
  Manual = 'MANUAL',
  Automatic = 'AUTOMATIC',
}

export type CouponDiscountable =
  | ExclusiveContent
  | Consultation
  | LiveStream
  | Shoutout
  | ECourse

export type OrderConnection = {
  __typename?: 'OrderConnection'
  edges: Array<OrdersEdge>
  nodes: Array<TransactionNode>
  pageInfo: PageInfo
}

export type OrderFilterByField = {
  status: InputMaybe<Array<OrderStatusField>>
  type: InputMaybe<Array<OrderTypeField>>
  showUpcomingConsultations: InputMaybe<Scalars['Boolean']>
  showPastConsultations: InputMaybe<Scalars['Boolean']>
  isRefunded: InputMaybe<Scalars['Boolean']>
  shoutoutStatus: InputMaybe<Array<ShoutoutStatusField>>
}

export enum OrderSortField {
  CreatedAt = 'CREATED_AT',
  Price = 'PRICE',
  PaidAt = 'PAID_AT',
  StartDate = 'START_DATE',
}

export enum OrderStatusField {
  Draft = 'DRAFT',
  OnHold = 'ON_HOLD',
  Paid = 'PAID',
  Failed = 'FAILED',
  Cancelled = 'CANCELLED',
}

export enum OrderTypeField {
  Tip = 'TIP',
  Order = 'ORDER',
  RecurringTip = 'RECURRING_TIP',
  ExclusiveContent = 'EXCLUSIVE_CONTENT',
  LiveStream = 'LIVE_STREAM',
  Consultation = 'CONSULTATION',
  Ecourse = 'ECOURSE',
  Invoice = 'INVOICE',
  MembershipSubscription = 'MEMBERSHIP_SUBSCRIPTION',
}

export type OrdersEdge = {
  __typename?: 'OrdersEdge'
  node: TransactionNode
  cursor: Scalars['String']
}

export type OrdersExport = {
  __typename?: 'OrdersExport'
  orders: Array<TransactionNode>
  limitExceeded: Scalars['Boolean']
}

export enum ShoutoutStatusField {
  Pending = 'PENDING',
  Fulfilled = 'FULFILLED',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Expired = 'EXPIRED',
}

export type AddReceiptEmailCustomMessageInput = {
  resourceId: Scalars['ID']
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
}

export type AddReceiptEmailCustomMessagePayload =
  | AddReceiptEmailCustomMessagePayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddReceiptEmailCustomMessagePayloadSuccess = {
  __typename?: 'AddReceiptEmailCustomMessagePayloadSuccess'
  resource: Maybe<ResourceType>
}

export type ResourceType =
  | ExclusiveContent
  | Consultation
  | LiveStream
  | Shoutout
  | ECourse
  | Membership

export type AddResourceShortUrlInput = {
  resourceId: Scalars['ID']
}

export type AddResourceShortUrlPayload =
  | AddResourceShortUrlPayloadSuccess
  | InputValidationError

export type AddResourceShortUrlPayloadSuccess = {
  __typename?: 'AddResourceShortUrlPayloadSuccess'
  service: Maybe<ShortLinkableService>
}

export type ShortLinkableService =
  | ExclusiveContent
  | Consultation
  | LiveStream
  | Shoutout
  | ECourse
  | Invoice
  | Membership

export type AddShortUrlInput = {
  url: Scalars['String']
}

export type AddShortUrlPayload =
  | AddShortUrlPayloadSuccess
  | InputValidationError

export type AddShortUrlPayloadSuccess = {
  __typename?: 'AddShortUrlPayloadSuccess'
  shortUrl: ShortUrl
}

export type DeleteShortUrlInput = {
  shortUrlId: Scalars['ID']
}

export type DeleteShortUrlPayload = DeleteShortUrlPayloadSuccess | NotFoundError

export type DeleteShortUrlPayloadSuccess = {
  __typename?: 'DeleteShortUrlPayloadSuccess'
  shortUrl: ShortUrl
}

export type ShortUrl = {
  __typename?: 'ShortUrl'
  id: Scalars['ID']
  url: Scalars['String']
  key: Scalars['String']
  owner: Creator
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type ShortUrlEmbedded = {
  __typename?: 'ShortUrlEmbedded'
  id: Scalars['ID']
  key: Scalars['String']
}

export type UpdateShortUrlInput = {
  id: Scalars['ID']
  url: Scalars['String']
}

export type UpdateShortUrlPayload =
  | UpdateShortUrlPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateShortUrlPayloadSuccess = {
  __typename?: 'UpdateShortUrlPayloadSuccess'
  shortUrl: ShortUrl
}

export type AcceptShoutoutOrderInput = {
  shoutoutOrderId: Scalars['ID']
}

export type AcceptShoutoutOrderPayload =
  | AcceptShoutoutOrderPayloadSuccess
  | NotFoundError
  | UpdateOrderStatusError

export type AcceptShoutoutOrderPayloadSuccess = {
  __typename?: 'AcceptShoutoutOrderPayloadSuccess'
  shoutoutOrder: ShoutoutOrder
}

export type AddFreeShoutoutOrderInput = {
  shoutoutId: Scalars['ID']
  isForOwner: Scalars['Boolean']
  from: InputMaybe<Scalars['String']>
  to: Scalars['String']
  description: Scalars['String']
  occasion: InputMaybe<ShoutoutOrderOccasion>
  displayOnCreatorProfile: InputMaybe<Scalars['Boolean']>
  userInformation: InputMaybe<UserCheckoutInput>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
  couponCode: InputMaybe<Scalars['String']>
}

export type AddFreeShoutoutOrderPayload =
  | AddFreeShoutoutOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorUnavailableError
  | ShoutoutIsMonetizedError
  | PartialCouponDiscountError

export type AddFreeShoutoutOrderPayloadSuccess = {
  __typename?: 'AddFreeShoutoutOrderPayloadSuccess'
  shoutoutOrderId: Maybe<Scalars['ID']>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddPaypalShoutoutOrderInput = {
  shoutoutId: Scalars['ID']
  isForOwner: Scalars['Boolean']
  from: InputMaybe<Scalars['String']>
  to: Scalars['String']
  description: Scalars['String']
  occasion: InputMaybe<ShoutoutOrderOccasion>
  displayOnCreatorProfile: InputMaybe<Scalars['Boolean']>
  userInformation: InputMaybe<UserCheckoutInput>
  customPrice: InputMaybe<MoneyInput>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
  couponCode: InputMaybe<Scalars['String']>
  paypalClientMetadataId: Scalars['String']
  captureMethod: CaptureMethodType
}

export type AddPaypalShoutoutOrderPayload =
  | AddPaypalShoutoutOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorUnavailableError
  | PaypalClientRequestError
  | CreatorNoConnectedPaymentError

export type AddPaypalShoutoutOrderPayloadSuccess = {
  __typename?: 'AddPaypalShoutoutOrderPayloadSuccess'
  paypalOrder: Maybe<PaypalOrder>
  shoutoutOrderId: Scalars['ID']
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddShoutoutInput = {
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  price: MoneyInput
  responseType: InputMaybe<ShoutoutResponseType>
  slug: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  status: InputMaybe<ShoutoutStatusWithoutDraft>
  questions: InputMaybe<Array<CustomQuestionInput>>
  expiresInDays: InputMaybe<Scalars['Int']>
}

export type AddShoutoutPayload =
  | AddShoutoutPayloadSuccess
  | InputValidationError

export type AddShoutoutPayloadSuccess = {
  __typename?: 'AddShoutoutPayloadSuccess'
  shoutout: Shoutout
}

export type AddShoutoutOrderInput = {
  shoutoutId: Scalars['ID']
  paymentIntentId: Scalars['String']
  isForOwner: Scalars['Boolean']
  from: InputMaybe<Scalars['String']>
  to: Scalars['String']
  description: Scalars['String']
  occasion: InputMaybe<ShoutoutOrderOccasion>
  displayOnCreatorProfile: InputMaybe<Scalars['Boolean']>
  userInformation: InputMaybe<UserCheckoutInput>
  customPrice: InputMaybe<MoneyInput>
  questionAnswers: InputMaybe<Array<CustomQuestionAnswerInput>>
  couponCode: InputMaybe<Scalars['String']>
}

export type AddShoutoutOrderPayload =
  | AddShoutoutOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorUnavailableError
  | StripeClientRequestError
  | CreatorNoConnectedPaymentError

export type AddShoutoutOrderPayloadSuccess = {
  __typename?: 'AddShoutoutOrderPayloadSuccess'
  shoutoutOrderId: Maybe<Scalars['ID']>
  paymentIntent: Maybe<PaymentIntent>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type DeleteShoutoutInput = {
  shoutoutId: Scalars['ID']
}

export type DeleteShoutoutPayload =
  | DeleteShoutoutPayloadSuccess
  | DeleteShoutoutPayloadUnpublished
  | NotFoundError

export type DeleteShoutoutPayloadSuccess = {
  __typename?: 'DeleteShoutoutPayloadSuccess'
  shoutoutId: Scalars['ID']
}

export type DeleteShoutoutPayloadUnpublished = {
  __typename?: 'DeleteShoutoutPayloadUnpublished'
  shoutout: Shoutout
}

export type FulfillShoutoutOrderInput = {
  shoutoutOrderId: Scalars['ID']
  asset: InputMaybe<Scalars['ID']>
  message: InputMaybe<Scalars['String']>
}

export type FulfillShoutoutOrderPayload =
  | FulfillShoutoutOrderPayloadSuccess
  | NotFoundError
  | UpdateOrderStatusError
  | PaypalClientRequestError
  | StripeClientRequestError
  | AssetNotLinkableToResourceError

export type FulfillShoutoutOrderPayloadSuccess = {
  __typename?: 'FulfillShoutoutOrderPayloadSuccess'
  shoutoutOrder: ShoutoutOrder
}

export type PublishShoutoutInput = {
  shoutoutId: Scalars['ID']
}

export type PublishShoutoutPayload =
  | PublishShoutoutPayloadSuccess
  | UnpublishableContentError
  | NotFoundError

export type PublishShoutoutPayloadSuccess = {
  __typename?: 'PublishShoutoutPayloadSuccess'
  shoutout: Shoutout
}

export type RejectShoutoutOrderInput = {
  shoutoutOrderId: Scalars['ID']
  message: InputMaybe<Scalars['String']>
}

export type RejectShoutoutOrderPayload =
  | RejectShoutoutOrderPayloadSuccess
  | NotFoundError
  | UpdateOrderStatusError
  | PaypalClientRequestError
  | StripeClientRequestError

export type RejectShoutoutOrderPayloadSuccess = {
  __typename?: 'RejectShoutoutOrderPayloadSuccess'
  shoutoutOrder: ShoutoutOrder
}

export enum ShoutoutStatusWithoutDraft {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type UnpublishShoutoutInput = {
  shoutoutId: Scalars['ID']
}

export type UnpublishShoutoutPayload =
  | UnpublishShoutoutPayloadSuccess
  | NotFoundError

export type UnpublishShoutoutPayloadSuccess = {
  __typename?: 'UnpublishShoutoutPayloadSuccess'
  shoutout: Shoutout
}

export type UpdateCustomShoutoutQuestionsInput = {
  shoutoutId: Scalars['ID']
  questions: Array<InputMaybe<CustomQuestionInput>>
}

export type UpdateCustomShoutoutQuestionsPayload =
  | UpdateCustomShoutoutQuestionsPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateCustomShoutoutQuestionsPayloadSuccess = {
  __typename?: 'UpdateCustomShoutoutQuestionsPayloadSuccess'
  shoutout: Shoutout
}

export type UpdateShoutoutInput = {
  ratingConfig: InputMaybe<RatingConfigInput>
  shoutoutId: Scalars['ID']
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  price: InputMaybe<MoneyInput>
  coverAsset: InputMaybe<Scalars['ID']>
  responseType: InputMaybe<ShoutoutResponseType>
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
  saleDiscountAmount: InputMaybe<Scalars['Float']>
  slug: InputMaybe<Scalars['String']>
  expiresInDays: InputMaybe<Scalars['Int']>
  status: InputMaybe<ShoutoutStatusWithoutDraft>
  questions: InputMaybe<Array<CustomQuestionInput>>
}

export type UpdateShoutoutPayload =
  | UpdateShoutoutPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateShoutoutPayloadSuccess = {
  __typename?: 'UpdateShoutoutPayloadSuccess'
  shoutout: Shoutout
}

export type AddPaypalTipOrderInput = {
  creatorId: Scalars['ID']
  tipBlockId: Scalars['ID']
  message: InputMaybe<Scalars['String']>
  isPrivateMessage: InputMaybe<Scalars['Boolean']>
  price: MoneyInput
  paypalClientMetadataId: Scalars['String']
  userInformation: InputMaybe<UserCheckoutInput>
}

export type AddPaypalTipOrderPayload =
  | AddPaypalTipOrderPayloadSuccess
  | InputValidationError
  | PaypalClientRequestError
  | CreatorNoConnectedPaymentError
  | NotFoundError

export type AddPaypalTipOrderPayloadSuccess = {
  __typename?: 'AddPaypalTipOrderPayloadSuccess'
  transactionId: Scalars['ID']
  paypalOrder: Maybe<PaypalOrder>
  authPayload: Maybe<SignupUserPayloadSuccess>
}

export type AddTipInput = {
  creatorId: Scalars['ID']
  tipBlockId: Scalars['ID']
  message: InputMaybe<Scalars['String']>
  isPrivateMessage: InputMaybe<Scalars['Boolean']>
  price: MoneyInput
  paymentIntentId: Scalars['String']
  userInformation: InputMaybe<UserCheckoutInput>
}

export type AddTipPayload =
  | AddTipPayloadSuccess
  | InputValidationError
  | StripeClientRequestError
  | CreatorNoConnectedPaymentError
  | NotFoundError

export type AddTipPayloadSuccess = {
  __typename?: 'AddTipPayloadSuccess'
  paymentIntent: PaymentIntent
  authPayload: Maybe<SignupUserPayloadSuccess>
  transactionId: Scalars['String']
}

export type AddTipBlockGoalInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  target: MoneyInput
  title: Scalars['String']
  description: InputMaybe<Scalars['String']>
}

export type AddTipBlockGoalPayload =
  | AddTipBlockGoalPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddTipBlockGoalPayloadSuccess = {
  __typename?: 'AddTipBlockGoalPayloadSuccess'
  creatorLink: CreatorLink
  goal: Goal
}

export type AddTipStripeSubscriptionInput = {
  creatorId: Scalars['ID']
  planId: Scalars['String']
  message: InputMaybe<Scalars['String']>
  isPrivateMessage: InputMaybe<Scalars['Boolean']>
  userInformation: InputMaybe<UserCheckoutInput>
}

export type AddTipStripeSubscriptionPayload =
  | AddTipStripeSubscriptionPayloadSuccess
  | StripeClientRequestError
  | ExistingSubscriptionError
  | CreatorNoConnectedPaymentError
  | InputValidationError
  | NotFoundError

export type AddTipStripeSubscriptionPayloadSuccess = {
  __typename?: 'AddTipStripeSubscriptionPayloadSuccess'
  paymentIntent: PaymentIntent
  authPayload: Maybe<SignupUserPayloadSuccess>
  transactionId: Scalars['String']
}

export type CancelSubscriptionInput = {
  transactionId: Scalars['ID']
}

export type CancelSubscriptionPayload =
  | CancelSubscriptionPayloadSuccess
  | StripeClientRequestError
  | NotFoundError

export type CancelSubscriptionPayloadSuccess = {
  __typename?: 'CancelSubscriptionPayloadSuccess'
  transactionId: Scalars['String']
}

export type DeleteTipBlockGoalInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
}

export type DeleteTipBlockGoalPayload =
  | DeleteTipBlockGoalPayloadSuccess
  | InputValidationError
  | NotFoundError

export type DeleteTipBlockGoalPayloadSuccess = {
  __typename?: 'DeleteTipBlockGoalPayloadSuccess'
  creatorLink: CreatorLink
}

export type ResetTipBlockGoalInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
}

export type ResetTipBlockGoalPayload =
  | ResetTipBlockGoalPayloadSuccess
  | InputValidationError
  | NotFoundError

export type ResetTipBlockGoalPayloadSuccess = {
  __typename?: 'ResetTipBlockGoalPayloadSuccess'
  creatorLink: CreatorLink
  goal: Goal
}

export type RetryStripeInvoicePaymentInput = {
  transactionId: Scalars['ID']
  paymentMethodId: Scalars['String']
}

export type RetryStripeInvoicePaymentPayload =
  | RetryStripeInvoicePaymentPayloadSuccess
  | StripeClientRequestError
  | NotFoundError

export type RetryStripeInvoicePaymentPayloadSuccess = {
  __typename?: 'RetryStripeInvoicePaymentPayloadSuccess'
  transactionId: Scalars['String']
  paymentIntent: PaymentIntent
}

export type RecurringTip = Node &
  Transaction & {
    __typename?: 'RecurringTip'
    id: Scalars['ID']
    user: User
    price: Money
    paidAt: Maybe<Scalars['DateTime']>
    createdAt: Scalars['DateTime']
    status: TransactionStatus
    platform: TransactionPlatform
    creator: Creator
    isPayedOut: Scalars['Boolean']
    isRefunded: Scalars['Boolean']
    isRefundable: Scalars['Boolean']
    saleDiscount: Maybe<SaleDiscount>
    couponDiscount: Maybe<DiscountCoupon>
    refund: Maybe<TransactionRefund>
    description: Maybe<Scalars['String']>
    message: Maybe<Scalars['String']>
    isPrivateMessage: Scalars['Boolean']
  }

export enum TipBlockPriceOptionStyleEnum {
  TextOnly = 'TEXT_ONLY',
  TextWithEmoji = 'TEXT_WITH_EMOJI',
}

export type UpdateTipBlockPriceOptionInput = {
  amount: Scalars['Int']
  currencyCode: CurrencyCode
  emoji: InputMaybe<Scalars['String']>
}

export type UpdateTipBlockPriceOptionsInput = {
  style: TipBlockPriceOptionStyleEnum
  options: Array<UpdateTipBlockPriceOptionInput>
}

export type UpsertTipBlockGoalInput = {
  target: InputMaybe<MoneyInput>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
}

export type UpdateTipBlockInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  theme: InputMaybe<Scalars['String']>
  buttonText: InputMaybe<Scalars['String']>
  animationType: InputMaybe<LinkBlockAnimationTypesEnum>
  isAnimated: InputMaybe<Scalars['Boolean']>
  priceOptions: InputMaybe<UpdateTipBlockPriceOptionsInput>
  icon: InputMaybe<Scalars['String']>
  cardStyle: InputMaybe<TipBlockCardStyle>
  showTipingFeed: InputMaybe<Scalars['Boolean']>
  receiptEmailCustomMessageTemplate: InputMaybe<Scalars['String']>
  goal: InputMaybe<UpsertTipBlockGoalInput>
}

export type UpdateTipBlockPayload =
  | UpdateTipBlockPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateTipBlockPayloadSuccess = {
  __typename?: 'UpdateTipBlockPayloadSuccess'
  creatorLink: CreatorLink
  goal: Maybe<Goal>
}

export type UpdateTipBlockGoalInput = {
  creatorLinkId: Scalars['ID']
  blockId: Scalars['ID']
  target: InputMaybe<MoneyInput>
  title: InputMaybe<Scalars['String']>
  description: InputMaybe<Scalars['String']>
  coverAsset: InputMaybe<Scalars['ID']>
}

export type UpdateTipBlockGoalPayload =
  | UpdateTipBlockGoalPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateTipBlockGoalPayloadSuccess = {
  __typename?: 'UpdateTipBlockGoalPayloadSuccess'
  creatorLink: CreatorLink
  goal: Goal
}

export type BulkUpsertCreatorPoliciesInput = {
  creatorId: Scalars['ID']
  policies: Array<PolicyInput>
}

export type BulkUpsertCreatorPoliciesPayload =
  | BulkUpsertCreatorPoliciesPayloadSuccess
  | InputValidationError
  | NotFoundError
  | DuplicatePolicyKindError

export type BulkUpsertCreatorPoliciesPayloadSuccess = {
  __typename?: 'BulkUpsertCreatorPoliciesPayloadSuccess'
  creator: Maybe<Creator>
}

export type ConnectStripeInput = {
  code: Scalars['String']
}

export type ConnectStripePayload =
  | ConnectStripePayloadSuccess
  | StripeClientRequestError

export type ConnectStripePayloadSuccess = {
  __typename?: 'ConnectStripePayloadSuccess'
  creator: Maybe<Creator>
}

export type DeleteCreatorAccountPayloadSuccess = {
  __typename?: 'DeleteCreatorAccountPayloadSuccess'
  creatorId: Scalars['ID']
}

export type DeleteCreatorAccountInput = {
  creatorId: Scalars['ID']
}

export type DeleteCreatorAccountPayload =
  | DeleteCreatorAccountPayloadSuccess
  | NotFoundError

export type DeleteCreatorPlanolyAccountInput = {
  planolyId: Scalars['Int']
}

export type DeleteCreatorPlanolyAccountPayload =
  | DeleteCreatorPlanolyAccountPayloadSuccess
  | NotFoundError
  | InputValidationError

export type DeleteCreatorPlanolyAccountPayloadSuccess = {
  __typename?: 'DeleteCreatorPlanolyAccountPayloadSuccess'
  planolyId: Scalars['Int']
}

export type DeleteCreatorPlanolyWorkspaceInput = {
  planolyId: Scalars['Int']
  socialSetId: Scalars['Int']
}

export type DeleteCreatorPlanolyWorkspacePayload =
  | DeleteCreatorPlanolyWorkspacePayloadSuccess
  | NotFoundError
  | InputValidationError

export type DeleteCreatorPlanolyWorkspacePayloadSuccess = {
  __typename?: 'DeleteCreatorPlanolyWorkspacePayloadSuccess'
  planolyId: Scalars['Int']
}

export type DisconnectPaypalInput = {
  creatorId: Scalars['ID']
}

export type DisconnectPaypalPayload =
  | NotFoundError
  | PaypalAccountInUseError
  | DisconnectPaypalPayloadSuccess

export type DisconnectPaypalPayloadSuccess = {
  __typename?: 'DisconnectPaypalPayloadSuccess'
  creator: Creator
}

export type PaypalAccountInUseError = UserError & {
  __typename?: 'PaypalAccountInUseError'
  message: Scalars['String']
  code: Scalars['String']
}

export type DisconnectStripeInput = {
  creatorId: Scalars['ID']
}

export type DisconnectStripePayload =
  | NotFoundError
  | StripeAccountInUseError
  | StripeClientRequestError
  | DisconnectStripePayloadSuccess

export type DisconnectStripePayloadSuccess = {
  __typename?: 'DisconnectStripePayloadSuccess'
  creator: Creator
}

export type StripeAccountInUseError = UserError & {
  __typename?: 'StripeAccountInUseError'
  message: Scalars['String']
  code: Scalars['String']
}

export type DeleteUserAccountPayloadSuccess = {
  __typename?: 'DeleteUserAccountPayloadSuccess'
  userId: Scalars['ID']
}

export type DeleteUserAccountInput = {
  userId: Scalars['ID']
}

export type DeleteUserAccountPayload =
  | DeleteUserAccountPayloadSuccess
  | NotFoundError

export type CustomQuestionService =
  | ExclusiveContent
  | Consultation
  | LiveStream
  | Shoutout
  | ECourse
  | Membership

export type UpdateServiceCustomQuestionsInput = {
  serviceId: Scalars['ID']
  questions: Array<CustomQuestionInput>
}

export type UpdateServiceCustomQuestionsPayload =
  | UpdateServiceCustomQuestionsPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateServiceCustomQuestionsPayloadSuccess = {
  __typename?: 'UpdateServiceCustomQuestionsPayloadSuccess'
  service: Maybe<CustomQuestionService>
}

export type OrderRatingInterface = {
  rating: Maybe<Rating>
}

export type Rating = {
  __typename?: 'Rating'
  message: Maybe<Scalars['String']>
  value: Scalars['Int']
  createdAt: Scalars['DateTime']
  isAnonymous: Scalars['Boolean']
  owner: Maybe<RatingOwner>
}

export type RatingConfigInput = {
  display: InputMaybe<Scalars['Boolean']>
  minimumValueToDisplayMessage: InputMaybe<Scalars['Int']>
}

export type RatingEdge = {
  __typename?: 'RatingEdge'
  node: Rating
  cursor: Scalars['String']
}

export type RatingInterface = {
  ratingConfig: Maybe<MonetizableServiceRatingConfig>
  rating: Maybe<MonetizableServiceRating>
  ratings: Maybe<RatingsConnection>
}

export type RatingInterfaceRatingsArgs = {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  before: InputMaybe<Scalars['String']>
  last: InputMaybe<Scalars['Int']>
}

export type RatingsConnection = {
  __typename?: 'RatingsConnection'
  edges: Array<RatingEdge>
  nodes: Array<Rating>
  pageInfo: PageInfo
}

export type RateableService =
  | ExclusiveContent
  | Consultation
  | LiveStream
  | Shoutout
  | ECourse
  | Membership

export type UpdateServiceRatingConfigurationInput = {
  serviceId: Scalars['ID']
  display: Scalars['Boolean']
  minimumValueToDisplayMessage: InputMaybe<Scalars['Int']>
}

export type UpdateServiceRatingConfigurationPayload =
  | UpdateServiceRatingConfigurationPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateServiceRatingConfigurationPayloadSuccess = {
  __typename?: 'UpdateServiceRatingConfigurationPayloadSuccess'
  service: Maybe<RateableService>
}

export type UpsertRatingInput = {
  message: InputMaybe<Scalars['String']>
  value: Scalars['Int']
  documentId: Scalars['ID']
  isAnonymous: Scalars['Boolean']
}

export type UpsertRatingPayloadSuccess = {
  __typename?: 'UpsertRatingPayloadSuccess'
  rating: Maybe<Rating>
}

export type UpsertTransactionRatingInput = {
  message: InputMaybe<Scalars['String']>
  value: Scalars['Int']
  transactionId: Scalars['ID']
  isAnonymous: Scalars['Boolean']
}

export type UpsertTransactionRatingPayloadSuccess = {
  __typename?: 'UpsertTransactionRatingPayloadSuccess'
  rating: Maybe<Rating>
}

export enum SluggableNode {
  ECourse = 'ECourse',
  ExclusiveContent = 'ExclusiveContent',
  LiveStream = 'LiveStream',
  Shoutout = 'Shoutout',
  Consultation = 'Consultation',
  Membership = 'Membership',
}

export type Testimonial = {
  __typename?: 'Testimonial'
  content: Scalars['String']
  user: TestimonialUser
}

export type TestimonialConfigInput = {
  display: InputMaybe<Scalars['Boolean']>
}

export type TestimonialEdge = {
  __typename?: 'TestimonialEdge'
  node: Testimonial
  cursor: Scalars['String']
}

export type TestimonialInterface = {
  testimonialConfig: Maybe<MonetizableServiceTestimonialConfig>
  testimonials: Maybe<TestimonialsConnection>
}

export type TestimonialsConnection = {
  __typename?: 'TestimonialsConnection'
  edges: Array<TestimonialEdge>
  nodes: Array<Testimonial>
  pageInfo: PageInfo
}

export type TestimonialInput = {
  content: Scalars['String']
  user: TestimonialUserInput
}

export type TestimonialUserInput = {
  name: Scalars['String']
  profilePictureAsset: InputMaybe<Scalars['ID']>
}

export type UpdateTestimonialsInput = {
  resourceId: Scalars['ID']
  display: Scalars['Boolean']
  testimonials: Array<InputMaybe<TestimonialInput>>
}

export type UpdateTestimonialsPayloadSuccess = {
  __typename?: 'UpdateTestimonialsPayloadSuccess'
  testimonials: Array<Maybe<Testimonial>>
}

export type CreatorAlreadyUpgradedError = UserError & {
  __typename?: 'CreatorAlreadyUpgradedError'
  message: Scalars['String']
  code: Scalars['String']
}

export type CreatorNotEligibleForTrialError = UserError & {
  __typename?: 'CreatorNotEligibleForTrialError'
  message: Scalars['String']
  code: Scalars['String']
}

export type DuplicatePolicyKindError = UserError & {
  __typename?: 'DuplicatePolicyKindError'
  message: Scalars['String']
  code: Scalars['String']
}

export type ECourseAlreadyPurchasedError = UserError & {
  __typename?: 'ECourseAlreadyPurchasedError'
  message: Scalars['String']
  code: Scalars['String']
}

export type ECourseIsMonetizedError = UserError & {
  __typename?: 'ECourseIsMonetizedError'
  message: Scalars['String']
  code: Scalars['String']
}

export type FirstPromoterError = UserError & {
  __typename?: 'FirstPromoterError'
  message: Scalars['String']
  code: Scalars['String']
}

export type NotEditableError = UserError & {
  __typename?: 'NotEditableError'
  message: Scalars['String']
  code: Scalars['String']
}

export type CreatorGlobalInformations = {
  __typename?: 'CreatorGlobalInformations'
  recentTransactions: Array<TransactionNode>
  upcomingConsultationTransactions: Array<ConsultationOrder>
  newCustomers: Array<User>
  totalOnHoldTransactions: Scalars['Int']
  totalPendingShoutoutOrders: Scalars['Int']
}

export type ZapierWebhooks = {
  __typename?: 'ZapierWebhooks'
  newECourseOrder: Maybe<Array<Maybe<ZapierWebhook>>>
  newShoutoutOrder: Maybe<Array<Maybe<ZapierWebhook>>>
  newConsultationOrder: Maybe<Array<Maybe<ZapierWebhook>>>
  invoicePaid: Maybe<Array<Maybe<ZapierWebhook>>>
  newExclusiveContentOrder: Maybe<Array<Maybe<ZapierWebhook>>>
  newLiveStreamOrder: Maybe<Array<Maybe<ZapierWebhook>>>
  newOneTimeTip: Maybe<Array<Maybe<ZapierWebhook>>>
  newRecurringTip: Maybe<Array<Maybe<ZapierWebhook>>>
  transactionConfirmed: Maybe<Array<Maybe<ZapierWebhook>>>
  newMailingListEntry: Maybe<Array<Maybe<ZapierWebhook>>>
}

export type AddItemToSocialFeedBlockPayload =
  | AddItemToSocialFeedBlockPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type AddSocialFeedBlockPayload =
  | AddSocialFeedBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddThumbnailsBlockPayload =
  | AddThumbnailsBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateLinkDesignFontsInput = {
  heading: FontFamilyEnum
  body: FontFamilyEnum
}

export type UpdateSocialFeedBlockPayload =
  | UpdateSocialFeedBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateSocialFeedBlockItemPayload =
  | UpdateSocialFeedBlockItemPayloadSuccess
  | InputValidationError
  | NotFoundError
  | AssetNotLinkableToResourceError

export type UpdateThumbnailsBlockPayload =
  | UpdateThumbnailsBlockPayloadSuccess
  | InputValidationError
  | NotFoundError

export enum ConsultationStatusWithoutDraft {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type EmailRecipientFilterInput = {
  kind: EmailEnrollmentTriggerKind
  productIds: Array<Scalars['ID']>
}

export type CrmEmailFlowStepInput = {
  subject: Scalars['String']
  tipTapContent: Scalars['String']
  delayMinutes: Scalars['Int']
}

export type DateRange = {
  from: InputMaybe<Scalars['DateTime']>
  to: InputMaybe<Scalars['DateTime']>
}

export type IntRange = {
  from: InputMaybe<Scalars['Int']>
  to: InputMaybe<Scalars['Int']>
}

export type CrmProductFilter = {
  productIds: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  kinds: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export enum CrmCustomerSubscriptionStatusKind {
  Subscribed = 'SUBSCRIBED',
  Unsubscribed = 'UNSUBSCRIBED',
  OptInEmailSent = 'OPT_IN_EMAIL_SENT',
}

export type CrmEmailStats = {
  __typename?: 'CrmEmailStats'
  totalSent: Scalars['Int']
  bounces: Scalars['Int']
  complaints: Scalars['Int']
  clicks: Scalars['Int']
  opens: Scalars['Int']
}

export enum EmailBroadcastStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type EmailEnrollmentTrigger = {
  __typename?: 'EmailEnrollmentTrigger'
  kind: EmailEnrollmentTriggerKind
  productIds: Array<Scalars['String']>
}

export type CrmEmailBroadcastEdge = {
  __typename?: 'CrmEmailBroadcastEdge'
  node: CrmEmailBroadcast
  cursor: Scalars['String']
}

export type CrmEmailFlowStep = Node & {
  __typename?: 'CrmEmailFlowStep'
  id: Scalars['ID']
  subject: Scalars['String']
  tipTapContent: Scalars['String']
  delayMinutes: Scalars['Int']
  stats: CrmEmailStats
}

export type EmailUnenrollmentTrigger = {
  __typename?: 'EmailUnenrollmentTrigger'
  kind: EmailUnenrollmentTriggerKind
  productIds: Array<Scalars['String']>
}

export type CrmEmailFlowEdge = {
  __typename?: 'CrmEmailFlowEdge'
  node: CrmEmailFlow
  cursor: Scalars['String']
}

export enum EmailEnrollmentTriggerKind {
  AbandonedCart = 'AbandonedCart',
  ProductPurchase = 'ProductPurchase',
  MailingList = 'MailingList',
}

export enum EmailUnenrollmentTriggerKind {
  ProductPurchase = 'ProductPurchase',
}

export type AddECoursePayload =
  | AddECoursePayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddECourseLessonPayload =
  | AddECourseLessonPayloadSuccess
  | InputValidationError

export type AddECourseLessonFilePayload =
  | AddECourseLessonFilePayloadSuccess
  | InputValidationError

export type AddECourseOrderPayload =
  | AddECourseOrderPayloadSuccess
  | InputValidationError
  | NotFoundError
  | ECourseAlreadyPurchasedError
  | CreatorNoConnectedPaymentError

export type AddECourseTrailerPayload =
  | AddECourseTrailerPayloadSuccess
  | InputValidationError

export type AddFreeECourseOrderPayload =
  | AddFreeECourseOrderPayloadSuccess
  | InputValidationError
  | NotFoundError
  | ECourseIsMonetizedError
  | ECourseAlreadyPurchasedError

export type AddPaypalECourseOrderPayload =
  | AddPaypalECourseOrderPayloadSuccess
  | InputValidationError
  | NotFoundError
  | ECourseAlreadyPurchasedError
  | CreatorNoConnectedPaymentError
  | PaypalClientRequestError

export type DeleteECoursePayload =
  | DeleteECoursePayloadSuccess
  | NotDeletableError
  | NotFoundError

export type DeleteECourseLessonPayload =
  | DeleteECourseLessonPayloadSuccess
  | NotFoundError
  | InputValidationError

export type DeleteECourseLessonFilePayload =
  | DeleteECourseLessonFilePayloadSuccess
  | NotFoundError
  | InputValidationError

export type DeleteECourseTrailerPayload =
  | DeleteECourseTrailerPayloadSuccess
  | NotFoundError
  | InputValidationError

export type MarkECourseLessonAsVisibleOrInvisiblePayload =
  | MarkECourseLessonAsVisibleOrInvisiblePayloadSuccess
  | NotFoundError
  | InputValidationError

export type MarkECourseLessonFileAsCompletedOrUncompletedPayload =
  | MarkECourseLessonFileAsCompletedOrUncompletedPayloadSuccess
  | NotFoundError
  | InputValidationError

export type MarkECourseLessonFileAsVisibleOrInvisiblePayload =
  | MarkECourseLessonFileAsVisibleOrInvisiblePayloadSuccess
  | NotFoundError
  | InputValidationError

export type PublishECoursePayload =
  | PublishECoursePayloadSuccess
  | NotFoundError
  | InputValidationError

export type SortECourseLessonFilesPayload =
  | SortECourseLessonFilesPayloadSuccess
  | NotFoundError
  | InputValidationError

export type SortECourseLessonsPayload =
  | SortECourseLessonsPayloadSuccess
  | NotFoundError
  | InputValidationError

export type SortECourseTrailersPayload =
  | SortECourseTrailersPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UnpublishECoursePayload =
  | UnpublishECoursePayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateCustomerECourseAccessPayload =
  | UpdateCustomerECourseAccessPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateECoursePayload =
  | UpdateECoursePayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateECourseLessonPayload =
  | UpdateECourseLessonPayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateECourseLessonFilePayload =
  | UpdateECourseLessonFilePayloadSuccess
  | NotFoundError
  | InputValidationError

export type UpdateECourseTrailerPayload =
  | UpdateECourseTrailerPayloadSuccess
  | NotFoundError
  | InputValidationError

export type AddExclusiveContentOrderPayload =
  | AddExclusiveContentOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorNoConnectedPaymentError
  | ExclusiveContentAlreadyPurchasedError
  | StripeClientRequestError
  | CreatorUnavailableError

export type AddPaypalExclusiveContentOrderPayload =
  | AddPaypalExclusiveContentOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorUnavailableError
  | PaypalClientRequestError
  | CreatorNoConnectedPaymentError
  | ExclusiveContentAlreadyPurchasedError

export type Add30DayTrialWithoutPaymentMethodPayload =
  | Add30DayTrialWithoutPaymentMethodPayloadSuccess
  | ExternalServiceRequestError
  | NotFoundError
  | CreatorNotEligibleForTrialError

export type Add30DayTrialWithoutPaymentMethodInput = {
  creatorId: Scalars['ID']
}

export enum AddCreatorSubscriptionIntentType {
  Setup = 'setup',
  Payment = 'payment',
}

export type AddFreeInvoiceOrderPayload =
  | AddFreeInvoiceOrderPayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddInvoicePayload =
  | AddInvoicePayloadSuccess
  | InputValidationError
  | NotFoundError

export type AddInvoiceOrderPayload =
  | AddInvoiceOrderPayloadSuccess
  | InputValidationError
  | NotFoundError
  | CreatorNoConnectedPaymentError

export type AddPaypalInvoiceOrderPayload =
  | AddPaypalInvoiceOrderPayloadSuccess
  | InputValidationError
  | NotFoundError
  | CreatorNoConnectedPaymentError
  | PaypalClientRequestError

export type DeleteInvoicePayload =
  | DeleteInvoicePayloadSuccess
  | NotDeletableError
  | NotFoundError

export type InvoiceByNumberPayload =
  | InvoiceByNumberPayloadSuccess
  | NotFoundError

export type PublishInvoicePayload =
  | PublishInvoicePayloadSuccess
  | UnpublishableContentError
  | NotFoundError
  | InputValidationError

export type SendInvoiceViaEmailPayload =
  | SendInvoiceViaEmailPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateInvoicePayload =
  | UpdateInvoicePayloadSuccess
  | NotFoundError
  | InputValidationError

export type AddFreeLiveStreamOrderPayload =
  | AddFreeLiveStreamOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | LiveStreamAlreadyPurchasedError
  | LiveStreamAlreadyHappenedError
  | LiveStreamAlreadyFullError
  | LiveStreamIsMonetizedError

export type AddLiveStreamOrderPayload =
  | AddLiveStreamOrderPayloadSuccess
  | CreatorUnavailableError
  | NotFoundError
  | InputValidationError
  | CreatorNoConnectedPaymentError
  | StripeClientRequestError
  | LiveStreamAlreadyHappenedError
  | LiveStreamAlreadyFullError
  | LiveStreamAlreadyPurchasedError

export type AddPaypalLiveStreamOrderPayload =
  | AddPaypalLiveStreamOrderPayloadSuccess
  | NotFoundError
  | InputValidationError
  | CreatorNoConnectedPaymentError
  | LiveStreamAlreadyPurchasedError
  | LiveStreamAlreadyHappenedError
  | LiveStreamAlreadyFullError

export type AddMembershipSubscriptionPayload =
  | AddMembershipSubscriptionPayloadSuccess
  | InputValidationError
  | NotFoundError
  | CreatorNoConnectedPaymentError

export type MembershipSubscriptionStripe = {
  __typename?: 'MembershipSubscriptionStripe'
  creatorStripeAccountId: Maybe<Scalars['String']>
  customerStripeAccountId: Scalars['String']
  stripeSubscriptionId: Scalars['String']
}

export type MembershipSubscriptionTier = {
  __typename?: 'MembershipSubscriptionTier'
  id: Scalars['ID']
  name: Scalars['String']
  description: Maybe<Scalars['String']>
}

export type UpdateMembershipSubscriptionPayload =
  | UpdateMembershipSubscriptionPayloadSuccess
  | InputValidationError
  | NotFoundError
  | CreatorNoConnectedPaymentError

export type CalculateDiscountsPayload =
  | CalculateDiscountsPayloadSuccess
  | InputValidationError
  | NotFoundError
  | InvalidDeductiblePriceError

export type AddDiscountCouponPayload =
  | AddDiscountCouponPayloadSuccess
  | InputValidationError

export type DeleteDiscountCouponPayload =
  | DeleteDiscountCouponPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpdateDiscountCouponPayload =
  | UpdateDiscountCouponPayloadSuccess
  | InputValidationError

export type UpdateDiscountCouponStatusPayload =
  | UpdateDiscountCouponStatusSuccess
  | InputValidationError
  | NotFoundError

export type GeneratePaypalOnboardingLinkPayload =
  | GeneratePaypalOnboardingLinkPayloadSuccess
  | PaypalError
  | ExternalServiceRequestError
  | NotFoundError

export type SetInvoicesPreferencesDefaultValuesInput = {
  issuer: InvoiceIssuerDefaultValuesInput
}

export type SetInvoicesPreferences =
  | SetInvoicesPreferencesSuccess
  | NotFoundError

export type SetPaypalPreferences = SetPaypalPreferencesSuccess | NotFoundError

export type CancelBaseSubscriptionPayload =
  | CancelBaseSubscriptionPayloadSuccess
  | InputValidationError
  | NotFoundError

export type TransactionNode =
  | InvoiceOrder
  | ExclusiveContentOrder
  | LiveStreamOrder
  | ShoutoutOrder
  | ConsultationOrder
  | ECourseOrder
  | OneTimeTip
  | RecurringTip
  | MembershipSubscriptionTransaction

export type PolicyInput = {
  kind: PolicyKindEnumType
  description: InputMaybe<Scalars['String']>
}

export type RatingOwner = {
  __typename?: 'RatingOwner'
  id: Scalars['ID']
  name: Scalars['String']
}

export type MonetizableServiceRatingConfig = {
  __typename?: 'MonetizableServiceRatingConfig'
  minimumValueToDisplayMessage: Maybe<Scalars['Int']>
  display: Scalars['Boolean']
}

export type MonetizableServiceRating = {
  __typename?: 'MonetizableServiceRating'
  average: Scalars['Float']
  count: Scalars['Int']
}

export type UpsertRatingPayload =
  | UpsertRatingPayloadSuccess
  | InputValidationError
  | NotFoundError

export type UpsertTransactionRatingPayload =
  | UpsertTransactionRatingPayloadSuccess
  | InputValidationError
  | NotFoundError

export type TestimonialUser = {
  __typename?: 'TestimonialUser'
  name: Scalars['String']
  profilePictureAsset: Maybe<Asset>
}

export type MonetizableServiceTestimonialConfig = {
  __typename?: 'MonetizableServiceTestimonialConfig'
  display: Scalars['Boolean']
}

export type UpdateTestimonialsPayload =
  | UpdateTestimonialsPayloadSuccess
  | InputValidationError
  | NotFoundError

export type ZapierWebhook = {
  __typename?: 'ZapierWebhook'
  userId: Scalars['String']
  hookId: Scalars['String']
}
